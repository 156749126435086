import { render, staticRenderFns } from "./ComplaintsModal.vue?vue&type=template&id=6b6ddb48&scoped=true&"
import script from "./ComplaintsModal.vue?vue&type=script&lang=ts&"
export * from "./ComplaintsModal.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6b6ddb48",
  null
  
)

export default component.exports