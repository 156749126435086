import { Module, VuexModule, Mutation, Action, getModule } from 'vuex-module-decorators';
import store from "./index";
import {getMenuItems, getHomepageSubcategories, getAllSubcategories} from "../api/categories";

@Module({ dynamic: true, store, name: 'categories', namespaced: true })
export default class categories extends VuexModule {
    navigationMenu: object[] = [];
    allSubcategories: object[] = [];
    selectedMenuKey: number | null = null ;
    selectedSubcategory: object | null = null;
    homepageSubcategories: object[] | null = null;

    @Mutation
    setNavigationMenu(data: object[]):void {
        this.navigationMenu = data;
    }
    @Mutation
    setSelectedMenuKey(data: number | null){
        this.selectedMenuKey = data;
    }
    @Mutation
    setSelectedSubcategory(data: object | null){
        this.selectedSubcategory = data;
    }
    @Mutation
    setHomepageSubcategories(data: object[] ){
        this.homepageSubcategories = data;
    }
    @Mutation
    setAllSubcategories(data: object[] ){
        this.allSubcategories = data;
    }


    @Action
    async fetchCategoriesSubcategories(){
        const response = await getMenuItems();
        console.log('nav,', response)
        if(response !== null){
            this.setNavigationMenu(response);
        }
    }
    @Action
    async fetchAllSubcategories(){
        const response = await getAllSubcategories();
        if(response !== null){
            this.setAllSubcategories(response);
        }
    }
    @Action
    async fetchHomepageSubcategories(){
        const response = await getHomepageSubcategories();
        if(response !== null){
            this.setHomepageSubcategories(response);
        }

    }
}
export const CategoriesStore = getModule(categories);