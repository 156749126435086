import { Module, VuexModule, Mutation, Action, getModule } from 'vuex-module-decorators'
import store from "./index";

@Module({
  name: 'Loader',
  namespaced: true,
  dynamic: true,
  store
})
export default class Loader extends VuexModule {
  loading: boolean = false;

  @Mutation
  set(isShow: boolean):void {
    this.loading = isShow;
  }

  // @Action
  // setLoading(isShow: boolean) {
  //   this.set(isShow);
  // }
}
export const LoaderStore = getModule(Loader);