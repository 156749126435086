
import { Component, Vue, Prop } from "vue-property-decorator";
import { modal } from "@/store/modal";
import { CustomerStore } from "@/store/customer";
@Component({})
export default class AppThankYouModal extends Vue {
  CustomerStore: any = CustomerStore;
  Modal: any = modal;

  public async deactivateAccount() {
    await CustomerStore.deactivateAccount();
  }
}
