import axiosInstance from "../../config/axios";

export const getHomepageBlogPosts = async() => {
    const response = await axiosInstance.get('/blogs');
    return response && response.data ? response.data : null ;
}
export const getBlogById = async(params:any) => {
    const response = await axiosInstance.post('/blog/single_post', {...params});
    return response && response.data ? response.data : null ;
}
export const getAllBlogPosts = async() => {
    const response = await axiosInstance.get('/blogs/all');
    return response && response.data ? response.data : null ;
}
export const getFilteredBlogPosts = async(params : any) => {
    const response = await axiosInstance.post('/blogs', {...params});
    return response && response.data ? response.data : null ;
}
export const getBlogHashtags = async() => {
    const response = await axiosInstance.get('/blogs/hashtags');
    return response && response.data ? response.data : null ;
}
export const getSimilarBlogPosts = async(params:any) => {
    const response = await axiosInstance.post('/blogs/similar_posts', {...params});
    return response && response.data ? response.data : null ;
}


