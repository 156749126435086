
import { Vue, Component, Watch } from "vue-property-decorator";
import AppProductViewer from "@/components/AppProductViewer.vue";
import AppProductSlider from "@/components/AppProductSlider.vue";
import AppRegisterBanner from "@/components/AppRegisterBanner.vue";
import ShippingIcon from "@/icons/ShippingIcon.vue";
import Hearth from "@/icons/Hearth.vue";
import AppShopNavigation from "@/components/AppShopNavigation.vue";
import ArrowPhotoOverlayIcon from "@/icons/ArrowPhotoOverlayIcon.vue";
import AppRecommendedProductsSlider from "@/components/AppRecommendedProductsSlider.vue";
import AppAddToCartButton from "@/components/AppAddToCartButton.vue";
// import AppImageViewer from "@/components/AppImageViewer.vue";
import { CartStore } from "@/store/cart";
import { ProductStore } from "../store/product";
import { NavigationStore } from "../store/navigation";
import { CustomerStore } from "../store/customer";
@Component({
  components: {
    ArrowPhotoOverlayIcon,
    AppProductViewer,
    Hearth,
    ShippingIcon,
    AppShopNavigation,
    AppRegisterBanner,
    AppRecommendedProductsSlider,
    AppAddToCartButton,
    AppProductSlider,
  },
})
export default class ProductDetails extends Vue {
  host: string | undefined = process.env.VUE_APP_BACKEND_IMAGE_PREFIX;
  CartStore: any = CartStore;
  ProductStore: any = ProductStore;
  NavigationStore: any = NavigationStore;
  CustomerStore: any = CustomerStore;
  isSizeInvalid: boolean = false;
  isLikedBtnLoading: boolean = false;
  get ImagesUrlsPerColor() {
    if (this.productColors && this.selectedColor && this.selectedColor.id) {
      return this.foundProduct.images
        .filter((image: any) => image.color.id == this.selectedColor.id)
        .map((image: any) => image.url);
    }
    return [];
  }
  get selectedProductSize() {
    return ProductStore.selectedProductSize;
  }
  get selectedColor() {
    return ProductStore.selectedColor;
  }
  get foundProduct() {
    console.log('FOUND PRODUCT')
    return ProductStore.selectedProduct;
  }
  get similarProducts() {
    return ProductStore.similarProducts;
  }
  get availableColorIds() {
    return this.foundProduct && this.foundProduct.available_color_ids
      ? this.foundProduct.available_color_ids
      : [];
  }
  get availableSizesByColor() {
    return this.foundProduct && this.foundProduct.available_sizes_by_color
      ? this.foundProduct.available_sizes_by_color
      : {};
  }
  get isInStock() {
    return this.foundProduct && this.foundProduct.is_in_stock
      ? this.foundProduct.is_in_stock
      : false;
  }
  get isColorInStock() {
    if (this.selectedColor) {
      return this.availableColorIds.includes(this.selectedColor.id);
    }
  }
  get productColors() {
    console.log(ProductStore.productColors, 'component')
    return ProductStore.productColors;
    // if (this.foundProduct) {
    //   if (
    //     this.foundProduct.images &&
    //     Array.isArray(this.foundProduct.images) &&
    //     this.foundProduct.images.length > 0
    //   ) {
    //     let colorArray: any[] = [];
    //     this.foundProduct.images.forEach((image: any) => {
    //       if (image.color) {
    //         if (!colorArray.find((color) => color.id == image.color.id)) {
    //           colorArray.push(image.color);
    //         }
    //       }
    //     });
    //     ProductStore.setProductColors(colorArray);
    //     console.log(colorArray, 'colorArray')
    //     return colorArray;
    //   }
    // }
    // return [];
  }
  get allProductColors() {
    return this.foundProduct.images.map((image: any) => image.url);
  }
  get sliderImages() {
    if (this.selectedColor) {
      return this.ImagesUrlsPerColor;
    }
    return this.allProductColors;
  }

  get routeObject() {
    if (this.$route && this.$route.params && this.$route.params.id)
      return this.$route.params.id;
    return null;
  }
  public async addLike() {
    this.isLikedBtnLoading = true;
    await CustomerStore.addLike(Number(this.$route.params.id));
    ProductStore.fetchProductById(Number(this.$route.params.id));
    this.isLikedBtnLoading = false;
  }
  public setSelectedSize(val: any): void {
    ProductStore.setProductSize(val);
  }
  public setColor(val: any): void {
    ProductStore.setSelectedColor(val);
  }
  async addProductToCart() {
    if (this.selectedProductSize) {
      await CartStore.addProductToCart({
        productId: this.foundProduct ? this.foundProduct.id : null,
        size:
          this.selectedProductSize && this.selectedProductSize.size
            ? this.selectedProductSize.size
            : null,
        colorId: this.selectedColor ? this.selectedColor.id : null,
      });
      return true;
    } else {
      this.isSizeInvalid = true;
      return false;
    }
    return false;
  }
  public async slideNextProduct(operator: any) {
    await ProductStore.slideProduct({
      operator: operator,
    });
    this.$router
      .push(`/product-details/${ProductStore.selectedProduct.id}`)
      .catch(() => {});
  }
  @Watch("selectedProductSize", { deep: true, immediate: true })
  onSelectedColorChange(newVal: any) {
    if (newVal) {
      this.isSizeInvalid = false;
    }
  }
  @Watch("selectedColor", { deep: true, immediate: true })
  onSelectedProductSizeChanged(newVal: any) {
    if (newVal) {
      ProductStore.setProductSize(null);
    }
  }
  @Watch("routeObject", {
    deep: true,
    immediate: true,
  })
  async routeIdHandler(newVal: any) {
    if (newVal) {
      await ProductStore.fetchProductById(Number(newVal));
      ProductStore.fetchSimilarProducts({ product_id: Number(newVal) });
      let availableColors = [];
      if (this.availableColorIds) {
        availableColors = this.productColors.filter((value) =>
          this.availableColorIds.includes(value.id)
        );
      }
      // let initialColor = null;
      // if (availableColors.length > 0) {
      //   initialColor =
      //     availableColors && availableColors[0] ? availableColors[0] : null;
      // } else {
      //   initialColor =
      //     this.productColors && this.productColors[0]
      //       ? this.productColors[0]
      //       : null;
      // }
      // ProductStore.setSelectedColor(initialColor);
    }
  }
  updated() {
    console.log('updated')
  }
  destroyed() {
    ProductStore.setSimilarProducts([]);
    ProductStore.resetProduct();
  }
}
