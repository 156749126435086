
import { Component, Vue, Watch } from "vue-property-decorator";
import AppNavigation from "@/components/shared/AppHeader.vue";
import AppFooter from "@/components/shared/AppFooter.vue";
import AppAcceptCookiesBanner from "@/components/AppAcceptCookiesBanner.vue";
import { modal } from "@/store/modal";
import { ProductStore } from "@/store/product";
import { CategoriesStore } from "./store/categories";
import { BlogStore } from "./store/blog";
import { CustomerStore } from "./store/customer";
import { OverlayStore } from "./store/overlayLoader";
import WarehouseErrorModal from "@/components/modals/WarehouseErrorModal.vue";

@Component({
  components: {
    AppNavigation,
    AppFooter,
    AppAcceptCookiesBanner,
    WarehouseErrorModal,
  },
})
export default class App extends Vue {
  ProductStore: any = ProductStore;
  CategoriesStore: any = CategoriesStore;
  BlogStore: any = BlogStore;
  modal: any = modal;
  cookiesBanner: boolean = true;
  options: any = {
    enableHighAccuracy: true,
    timeout: 10000,
  };
  get loader() {
    return OverlayStore.isLoading;
  }
  // GETTERS - START
  get modalTitle() {
    return this.modal.modalTitle;
  }

  get modalStatus() {
    return this.modal.showModal;
  }
  // GETTERS - END
  async mounted() {
    CustomerStore.fetchCookie("fwi-token");
    this.cookiesBannerHandler();
  }

  // METHODS - START
  acceptCookies() {
    this.cookiesBanner = false;
    localStorage.setItem("cookies_accepted", "true");
  }

  cookiesBannerHandler() {
    const cookiesAccepted = localStorage.getItem("cookies_accepted");
    if (cookiesAccepted === "true") {
      this.cookiesBanner = false;
    }
  }
  // METHODS - END

  // WATCHERS - START
  @Watch("modalTitle")
  onTitleChange(val: string): void {
    if (val !== "") {
      if (this.modalStatus) this.$modal.show(val);
      else if (!this.modalStatus) this.$modal.hide(val);

      this.$nextTick(() => {
        this.modal.set({ name: "", show: false });
      });
    }
  }
  // WATCHERS - END
}
