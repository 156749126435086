
import { NavigationStore } from "@/store/navigation";
import { Vue, Component, Prop } from "vue-property-decorator";

@Component({})
export default class AppAddToCartButton extends Vue {
  NavigationStore: any = NavigationStore;
  @Prop({})
  callbackFunction!: () => {};
  public async buttonClicked() {
    if (await this.callbackFunction()) {
      const buttons = document.querySelectorAll(".addProductButton");
      buttons.forEach((button) => {
        if (!button.classList.contains("loading")) {
          button.classList.add("loading");
          setTimeout(() => button.classList.remove("loading"), 3100);
        }
      });
    }
  }
  mounted() {}
}
