
import { Component, Vue } from "vue-property-decorator";
import { modal } from "@/store/modal";
import { OrderStore } from "@/store/order";
@Component({})
export default class WarehouseErrorModal extends Vue {
  imageURLprefix: string | undefined = process.env.VUE_APP_BACKEND_IMAGE_PREFIX;
  Modal: any = modal;
  OrderStore: any = OrderStore;
  get products() {
    return OrderStore.warehouseErrorProducts
      ? OrderStore.warehouseErrorProducts
      : [];
  }
  public clearModal() {
    OrderStore.setWarehouseErrorProducts(null);
  }
}
