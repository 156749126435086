import { render, staticRenderFns } from "./LogoFooter.vue?vue&type=template&id=11133e30&scoped=true&"
import script from "./LogoFooter.vue?vue&type=script&lang=ts&"
export * from "./LogoFooter.vue?vue&type=script&lang=ts&"
import style0 from "./LogoFooter.vue?vue&type=style&index=0&id=11133e30&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "11133e30",
  null
  
)

export default component.exports