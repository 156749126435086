
import { Component, Prop, Vue } from "vue-property-decorator";
import ArrowPhotoOverlayIcon from "@/icons/ArrowPhotoOverlayIcon.vue";
import { imageViewer } from "@/store/imageViewer";
import { modal } from "@/store/modal";

@Component({ components: { ArrowPhotoOverlayIcon } })
export default class AppProductViewer extends Vue {
  imgId: number = 0;
  parentId: number = 0;
  host: string | undefined = process.env.VUE_APP_BACKEND_IMAGE_PREFIX;
  imageViewer: any = imageViewer;
  modal: any = modal;

  @Prop({ required: true })
  images!: string[];

  get itemsToShow() {
    if (window.innerWidth < 600) return 1;
    if (window.innerWidth < 900) return 2;
    if (window.innerWidth < 1025) return 3;
    if (window.innerWidth >= 1025) return 4;
  }

  zoomIn(event: any): void {
    if (window.innerWidth > 1023) {
      var imgId = "img" + this.imgId;
      var parentId = "parent" + this.parentId;
      var img = document.getElementById(imgId) as HTMLElement;
      var parent = document.getElementById(parentId) as HTMLElement;
      img.style.backgroundRepeat = "no-repeat";
      img.style.backgroundSize = "150% 150%";
      var x = event.pageX - img.offsetLeft;
      var y = event.pageY - img.offsetTop;
      var xperc = (x / parent.offsetWidth) * 100;
      var yperc = (y / parent.offsetHeight) * 100;
      img.style.backgroundPositionX = xperc + "%";
      img.style.backgroundPositionY = yperc - 30 + "%";
      img.style.transition = "0.1s linear all";
    }
  }

  zoomOut(): void {
    if (window.innerWidth > 1023) {
      var imgId = "img" + this.imgId;
      var img = document.getElementById(imgId) as HTMLElement;
      img.style.backgroundSize = "100% 100%";
    }
  }

  slide(side: string, elementId: any, itemId: any): void {
    let content = document.getElementById(elementId) as HTMLElement;
    let itemWidth = document.getElementById(itemId) as HTMLElement;
    let contentWidth: number = content.offsetWidth;
    let containerWidth: number = itemWidth.offsetWidth;
    if (side === "left") {
      content.scrollLeft -= containerWidth + 20;
    } else {
      content.scrollLeft += containerWidth + 20;
    }
  }
  openImageViewer(images: string[], index: number) {
    this.imageViewer.setImgIndex(index);
    this.imageViewer.setImages(images);
    this.imageViewer.setSelectedImage(images[index]);
    this.modal.set({ name: "imageViewerModal", show: true });
  }
}
