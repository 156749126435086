export const sr = {
  "footer": {
    "help": "Pomoć",
    "contact-us": "Kontaktirajte nas",
    "shipping-info": "Informacije o dostavi",
    "replacement-of-goods": "Zamena robe",
    "gift-card": "Poklon kartice",
    "size-guide": "Priručnik za veličine",
    "my-account": "Moj nalog",
    "login-register": "Prijava / Registracija",
    "order-history": "Istorija porudžbina",
    "info": "Informacije",
    "privacy-policy": "Politika privatnosti",
    "protection-of-confidential-information": "Zaštita poverljivih informacija",
    "refund": "Povraćaj sredstava",
    "cookie-policy": "Politika kolačića(cookies)",
    "terms-of-use": "Uslovi koriščenja",
    "follow-us": "Zapratite nas",
    "complaints": "Reklamacije",

    "about-us": {
      "title":"Osnovni podaci o firmi",
      "about-us": "O nama",
      "full-name-of-legal-entity": "Pun naziv pravnog subjekta",
      "fwi-address": "Savska 15 A/3, 11 000 Beograd",
      "activity-activity-code": "Delatnost i šifra delatnosti",
      "wholesale-of-clothing-and-footwear": "4642-Trgovina na veliko odećom i obućom",
      "identification-number": "Matični broj",
      "tax-number": "Poreski broj",
      "web-address": "Web adresa",
      "support-title" : "Kontakt podaci - korisnički servis",
      "contact-phone": "Kontakt telefon",
      "contact-email": "Kontakt email",
    },

  },

  "cart": {
    "conversion-title" : "Izjava o konverziji",
    "statement-of-conversion": "Sva plaćanja biće izvršena u lokalnoj valuti Republike Srbije – dinar (RSD).Za informativni prikaz cena u drugim valutama koristi se srednji kurs Narodne Banke Srbije. Iznos za koji će biti zadužena Vaša platna kartica biće izražen u Vašoj lokalnoj valuti kroz konverziju u istu po kursu koji koriste kartičarske organizacije, a koji nama u trenutku transakcije ne može biti poznat. Kao rezultat ove konverzije postoji mogućnost neznatne razlike od originalne cene navedene na našem sajtu.",
    // "purchase-agreement": "Saglasan sam sa opštim uslovima kupovine",
    "tax-included-title" : "Izjava o PDV-u",
    "tax-included": "PDV uračunat u cenu i nema skrivenih troškova",
    "purchase-agreement-1": "Saglasan sam sa opštim",
    "purchase-agreement-2": "uslovima",
    "purchase-agreement-3": "kupovine",
    "checkout": "Završi kupovinu",
    "additional-info": "Dodatne informacije",
    "need-more-help": "Dodatna pomoć",
    "add-to-cart": "Dodaj u korpu",
  },

  "globals": {
    "address": "Adresa",
    "returns": "Vraćanje proizvoda",
    "contact-us-by-email": "Kontaktirajte nas na mejl",
    "fwi-email": "testbrka@fwi.com",
    "register": "Napravi nalog",
    "amount": "Iznos",
    "cookies-policy": "Na našem sajtu se koriste kolačići. Ukoliko kliknete na Prihvatam  ili nastavite da koristite sajt, prihvatate upotrebu kolačića. Ukoliko ne prihvatate upotrebu kolačića, možete izmeniti podešavanja u Vašem pretraživaču.",
    "agree": "Prihvatam",
    "cancel": "Ne prihvatam",
    "close": "Zatvori",
  },

  "terms-of-use-modal": {
    "general-terms-of-purchase": "Opšti uslovi online kupovine",
    "paragraph-1": "Posetom ili kupovinom na Internet prodavnici www.fwi.rs prihvatate Uslove kupovine koji su navedeni u daljem tekstu. Molimo Vas da ih pažljivo pročitate i upoznate se sa pravilima koje primenjujemo. www.fwi.rs zadržava pravo da bez prethodnog obaveštavanja vrši izmene i dopune ovih Uslova korišćenja.",
    "paragraph-2": "Kada posetite www.fwi.rs ili nam pošaljete e-mail poruku, komunicirate sa nama elektronskim putem. Naša komunikacija prema Vama će biti putem elektronske pošte (email) ili obaveštenjima koja ćemo objavljivati na ovom sajtu.",
    "paragraph-3": "Za dodatne informacije u vezi sa porudžbinom, kao i u slučaju reklamacije ili otkaza porudžbine, možete se obratiti Korisničkom servisu slanjem e-mail-a na support@fwi.rs",
    "paragraph-4": "FWI by Ivana Korab” se obavezuje da će čuvati privatnost svih kupaca. ‟FWI by Ivana Korab” prikuplja samo osnovne podatke o kupcima/korisnicima neophodne za poslovanje i informisanje korisnika u skladu sa dobrim poslovnim običajima i u cilju pružanja kvalitetne usluge. Svi podaci o kupcima/korisnicima se strogo čuvaju i dostupni su samo zaposlenima kojima su ti podaci potrebni za obavljanje posla. Svi zaposleni Internet prodavnice www.fwi.rs odgovorni su za poštovanje načela zaštite privatnosti.",
    "paragraph-5": "Sadržaj prikazan na ovom sajtu je vlasništvo ‟FWI by Ivana Korab“ i ne može se koristiti bez prethodne saglasnosti ‟FWI by Ivana Korab”-a.",
    "paragraph-6": "Online prodavnica www.fwi.rs radi od 00-24h, 7 dana u nedelji. U slučaju tehničkih problema, sajt može biti kraći vremenski period nedostupan o čemu ćete biti obavešteni.",
    "paragraph-7": "Ukoliko koristite www.fwi.rs odgovorni ste za održavanje i poverljivost svog naloga i lozinki koje ste odredili prilikom registracije na sajtu. Maloletnici mogu koristiti www.fwi.rs uz nadzor roditelja ili staratelja. ‟FWI by Ivana Korab” zadržava pravo da odbije pružanje usluga, ukine nalog ili otkaže porudžbinu ukoliko ustanovi da su prekršeni Uslovi kupovine.",
    "paragraph-8": "‟FWI by Ivana Korab” zadržava pravo da može u svako vreme, bez prethodne najave, promeniti podatke prikazane na bilo kojoj stranici sajta. Posetioci www.fwi.rs nemaju pravo da jednostrano menjaju Uslove kupovine.",
    "paragraph-9": "www.fwi.rs je internet prodavnica koja posluje u skladu sa Zakonom o elektronskoj trgovini i svim ostalim važećim zakonima i propisima u Republici Srbiji.",
    "paragraph-10": "Nepoštovanje ovde definisani pravila može prouzrokovati neizvršenje platne transakcije ili neuspelu porudžbinu.",
    "paragraph-11": "Ovde propisani Opšti uslovi kupovine sastavni su deo Ugovora o kupoprodaji koji će biti zaključen između Prodavca i Kupca.",

    "ordering": "Naručivanje",
    "paragraph-12": "Za naručivanje proizvoda iz ponude Internet prodavnice potrebno je da ispratite sledeće korake: ",
    "paragraph-13": "- Upoznavanje sa Opštim uslovima kupovine, obaveštenjima o osnovnim podacima Prodavca, upoznavanje sa pravom na jednostrani raskid Ugovora i tekstom obrasca koji se popunjava prilikom jednostranog raskida ugovora, upoznavanje sa svim pravima i obavezama Kupca, što je sve na nedvosmislen i jasan način istaknuto na sajtu Prodavca",
    "paragraph-14": "- Registracija",
    "paragraph-15": "- Pronalaženje proizvoda koji želite da kupite",
    "paragraph-16": "- Unos podataka o načinu dostave, plaćanja i mestu isporuke",
    "paragraph-17": "- Pregled, potvrda i praćenje narudžbenice",

    "register": "Registracija",
    "paragraph-18": "Registracija se obavlja samo jednom, a svaki sledeći put, unosite samo Vaš e-mail i lozinku, koju sistem automatski prepoznaje. Registracija je besplatna i obavlja se izborom opcije “Moj nalog”, sa desne strane ispod teksta “Novi ste korisnik?” birate opciju “Registrujte se putem mail-a”. Potrebno je da navedete Vašu e-mail adresu, ime i prezime, adresu stanovanja, na koju bismo Vam dostavljali porudžbine, broj telena, putem kog bismo Vas kontaktirali za potvrdu porudžbine, kao i lozinku koju biste koristili za pristup Vašem nalogu nakon registracije. Kako biste se registrovali potrebno je i da pročitate polisu privatnosti i izjavu o poverljivosti podataka i označite polje za saglasnost sa istim. Nakon popunjavanje podataka, na e-mail adresu dobijate automatsku poruku sa linkom za potvrdu registracije. Klikom na link u e-mail-u otvara se prikaz na stranici gde unosite Vašu e-mail adresu i lozinku i pristupate nalogu klikom na “Login” dugme.",
    "paragraph-19": "Ukoliko želite, sa sajta se u svakom trenutku možete odjaviti izborom opcije “Odjavite se”. Ponovnu prijavu na sajt možete obaviti izborom opcije “Moj nalog” i navođenjem Vaše e-mail adrese i lozinke.",

    "finding-a-product": "Pronalaženje proizvoda koji želite da kupite",
    "paragraph-20": "Proizvodi su organizovani po kategorijama. Kada pronađete proizvod koji želite da kupite, kliknite na dugme “Izaberite veličinu”, a zatim na dugme “Dodajte u korpu”.",

    "enter-dilivery-and-payment-info": "Unos podataka o načinu dostave, plaćanju i mestu isporuke",
    "paragraph-21": "Kada ste završili sa stavljanjem proizvoda u korpu i želite da ih naručite, izaberite opciju “Korpa” gde su prikazani svi artikli koje ste izabrali. Ako ste se predomislili u vezi nekog od proizvoda, proizvod možete izbrisati iz korpe ili promeniti količinu.",
    "paragraph-22": "Klikom na dugme “Nastavite porudžbinu” otvaraju Vam se opcije za izbor načina plaćanja, načina slanja i odabir adrese za dostavu, koja može biti različita od one koju ste naveli prilikom registracije.",
    "paragraph-23": "Podaci o mestu isporuke su podaci gde želite da pošaljemo pošiljku i zbog toga Vas molimo da sve podatke navedete što preciznije kako bi Vas kuriri koji uručuju pošiljke što lakše pronašli. U slučaju neispravno unetih podataka, može doći do vraćanja Vaše pošiljke i sporije isporuke.",
    "paragraph-24": "Vaša pošiljka će biti poslata kurirskom službom. Plaćanje vršite pouzećem.",

    "review-confirm-track-order": "Pregled, potvrda i praćenje narudžbenice",
    "paragraph-25": "Pre nego što potvrdite porudžbinu, možete još jednom pogledati sve njene detalje. Ukoliko se slažete sa njom izaberite opciju “Poručite sada” čime je ona kreirana. Prije potvrde porudžbine, potrebno je da pročitate Opšte uslove kupovine i složite se sa istim. Automatski sa potvrdom porudžbine na registracioni e-mail Vam se šalje pregled narudžbenice.",
    "paragraph-26": "Prodavac će u roku od 3 do 5 radnih dana obavestiti kupca o dostupnosti porudžbine i mogućnosti da isporuči robu.",
    "paragraph-27": "Prodavac će se postarati da u što kraćem roku proveri raspoloživost poručenog artikla. Ukoliko su svi uslovi za kupovinu obezbeđeni, Prodavac će poslati potvrdu porudžbenice na e-mail adresu koju je Kupac naznačio prilikom registracije, time prihvatajući ponudu za zaključenje Ugovora. Ugovor se smatra zaključenim kada Prodavac obavesti Kupca o statusu porudžbine i vremenu isporuke poručenih artikala.",
    "paragraph-28": "Ukoliko Prodavac nije u mogućnosti da obezbedi poručeni artikal, mejlom će obavestiti kupca da isti više nije raspoloživ, te da je porudžbina otkazana. U tom slučaju Ugovor neće biti zaključen.",
    "paragraph-29": "Cene proizvoda su jasno istaknute na sajtu Prodavca. Prodavac zadržava pravo promene cena bez prethodnog obaveštavanja, kao i pravo dodavanja ili povlačenja iz ponude bilo kog artikla u svakom trenutku.",

    "payment": "Plaćanje",
    "paragraph-30": "Sve cene na sajtu su prikazane sa uračunatim PDV-om. www.fwi.rs podržava sledeće načine plaćanja:",
    "paragraph-31": "- pouzećem - plaćanje pouzećem podrazumeva plaćanje narudžbenice kuriru prilikom preuzimanja pošiljke. Plaćanje se vrši isključivo gotovinom.",
    "paragraph-32": "- platnim karticama  - kupac može izvršiti plaćanje korišćenjem Visa, Mastercard, Maestro ili American Express.karticama, izdatim od strane domaće banke.",
    "paragraph-33": "Sva plaćanja biće izvršena u lokalnoj valuti Republike Srbije - dinar (RSD).",
    "paragraph-34": "Za informativni prikaz cena u drugim valutama koristi se srednji kurs Narodne Banke Srbije. Iznos za koji će biti zadužena vaša platna kartica biće izražen u Vašoj lokalnoj valuti kroz konverziju u istu po kursu koji koriste kartičarske organizacije, a koji nama u trenutku transakcije ne može biti poznat. Kao rezultat ove konverzije postoji mogućnost neznatne razlike od originalne cene navedene na našem sajtu.",

    "dilivery": "Dostava",
    "paragraph-35": "Dostavu pošiljki vrši nezavisna kurirska služba i moguća je bilo gde u svetu. Troškovi dostave se posebno naplaćuju i oni su iskazani prilikom poručivanja robe. Iznos troškova dostave zavisi od težine pošiljke, kao i mesta gde se šalje. Troškove dostave plaćate zajedno sa proizvodima",
    "paragraph-36": "Vreme za koje će vam pošiljka biti uručena je do 5 radnih dana od dana potvrđene porudžbine. Kurirska služba dostavlja pošiljke u periodu od 08:00h do 19:00h.",
    "paragraph-37": "Prilikom dostavljanja robe Kupcu, Kupcu će biti predat jedan primerak maloprodajnog računa, koji istovremeno predstavlja ugovor o kupoprodaji.",
    "paragraph-38": "Kupac je odgovoran za štetu koja nastane propuštanjem da preuzme robu koju mu je poslao Prodavac u skladu sa ugovorom.",
    "paragraph-39": "Pod štetom se podrazumeva oštećenje na robi, kao i troškovi koje Prodavac ima zbog propuštanja Kupca da preuzme robu, kao što su: troškovi čuvanja, prepakivanja, vraćanja robe i slično.",

    "replacement": "Zamena",
    "paragraph-40": "Kupac može da zatraži zamenu u roku od 14 dana od dana prijema robe. Zamena robe se odnosi na zamenu veličine ili zamenu modela. U slučaju zamene roba se, zajedno sa popunjenim Obrascem za zamenu, dostavlja Prodavcu, i u tom slučaju troškove zamene snosi Kupac.",
    "paragraph-41": "Obrazac za zamenu dostupan je na sajtu Prodavca www.fwi.rs i može se slobodno preuzeti kao trajni nosač zapisa.",
    "paragraph-42": "U slučaju zamene Kupac je u obavezi da robu vrati bez oštećenja i tragova korišćenja. U suprotnom, Prodavac nije u obavezi da obavi zamenu.",
    "paragraph-43": "Artikal za koji se vrši zamena, mora imati istu ili veću vrednost od artikla koji se menja, uz doplatu.",
    "paragraph-44": "U periodima sezonskih sniženja isključivo radimo povrat novca. Robu nam šaljete zajedno sa popunjenom Izjavom o odustajanju od Ugovora.",

    "return-goods-and-termination-contract": "Povrat robe - Raskid Ugovora",
    "paragraph-45": "Kupac može izvršiti povrat robe, odnosno raskinuti Ugovor o kupoprodaji u roku od 14 dana od dana prijema robe. U slučaju raskida Ugovora, Kupac je u obavezi da to učini na Obrascu za raskid Ugovora.",
    "paragraph-46": "Obrazac za raskid Ugovora dostupan je na sajtu Prodavca www.fwi.rs i može se slobodno preuzeti kao trajni nosač zapisa.",
    "paragraph-47": "Izjava o raskidu Ugovora Proizvodi pravno dejstvo od dana kada je poslata Prodavcu i smatra se blagovremenom ako je upućena u roku do 14 dana od dana prijema robe.",
    "paragraph-48": "U slučaju raskida Ugovora Kupac je u obavezi da istu robu vrati bez oštećenja i znakova da je roba korišćena. U suprotnom Prodavac nije u obavezi da Kupcu vrati primljeni iznos kupoprodajne cene. Troškovi vraćanja robe padaju na teret kupca.",
    "paragraph-49": "U slučaju raskida Ugovora od strane Kupca u navedenom roku, Prodavac je dužan da Kupcu vrati primljeni iznos kupoprodajne cene u roku od 14 dana od dana prijema jednostrane izjave Kupca o raskidu Ugovora, pod uslovom da je Kupac u navedenom roku vratio robu Prodavcu bez oštećenja i znakova da je roba korišćena.",
    "paragraph-50": "U slučaju vraćanja artikla i povraćaja sredstava plaćenih nekom od platnih kartica, FWI by Ivana Korab povraćaj vrši preko VISA, EC/MC i Maestro metoda plaćanja, što znači da će banka na naš zahtev obaviti povraćaj sredstava na račun korisnika kartice.",

    "complaint": "Reklamacija",
    "paragraph-51": "Pored reklamacije radi ostvarenja prava po osnovu saobraznosti, možete izjaviti reklamaciju i zbog pogrešno obračunate cene i drugih nedostataka.",
    "paragraph-52": "Pretpostavlja se da je roba saobrazna Ugovoru:",
    "paragraph-53": "ako odgovara opisu koji je dao Prodavac, i ako ima svojstva robe koju je Prodavac pokazao Kupcu kao uzorak ili model",
    "paragraph-54": "ako ima svojstva potrebna za redovnu upotrebu robe iste vrste",
    "paragraph-55": "ako ima svojstva potrebna za naročitu upotrebu za koju je Kupac nabavlja",
    "paragraph-56": "ako po kvalitetu odgovara onome što je uobičajeno kod robe iste vrste i što Kupac može osnovano da očekuje i javna obećanja o posebnim svojstvima robe data od strane Prodavca, naročito ako je obećanje učinjeno putem oglasa ili na ambalaži robe.",
    "paragraph-57": "Reklamaciju na nesaobraznost kupac može izjaviti u roku od 2 godine od dana kupovine proizvoda ili prijema robe, uz originalan račun ili bilo koji drugi dokaz o kupovini robe.",
    "paragraph-58": "Ako nesaobraznost nastane u roku od 6 meseci od dana prelaska rizika na potrošača, može se pretpostaviti da je nesaobraznost postojala u trenutku prelaska rizika na potrošača.",
    "paragraph-59": "Na reklamaciju će Vam biti odgovoreno u roku od 8 (osam) dana, od dana podnošenja reklamacije.",
    "paragraph-60": "Sve troškove koji su neophodni da bi se roba saobrazila ugovoru, a naročito troškove rada, materijala, preuzimanja i isporuke, snosimo mi kao trgovac.",
    "paragraph-61": "Kao prodavac dužni smo da kupcu izdamo pisanu potvrdu ili elektrosnkim putem potvrdimo prijem reklamacije, odnosno saopštimo broj pod kojim je zavedena reklamacija u evidenciji primljenih reklamacija.",
    "paragraph-62": "Reklamaciju možete izjaviti:",
    "paragraph-63": "pisanim putem, preko Obrasca za reklamaciju koji se može preuzeti kao trajni nosač zapisa dostupnom na sajtu www.fwi.rs, uz istovremeno dostavljanje robe Prodavcu koja je predmet kupoprodaje i uz originalan primerak računa ili bilo koji drugi dokaz o kupovini.",
    "paragraph-64": "Rok za rešavanje po reklamaciji ne može da bude duži od 15 dana od dana podnošenja reklamacije.",
    "paragraph-65": "Produžavanje roka za rešavanje reklamacija moguće je samo jednom.",
    "paragraph-66": "Ako isporučena roba nije saobrazna Ugovoru, Kupac ima pravo da bira između otklanjanja nedostataka, zamene za isti ili drugi artikal i povrata novca.",
    "paragraph-67": "Drugi artikal za koji se vrši zamena mora biti iste ili veće vrednosti, uz doplatu.",
    "paragraph-68": "Ako otklanjanje nesaobraznosti nije moguće ili ako predstavlja nesrazmerno opterećenje za Prodavca, Kupac može da zahteva umenjenje cene ili da izjavi da raskida Ugovor. U zavisnosti od zahteva Kupca, Prodavac je dužan da celokupan iznos kupoprodajne cene ili deo cene za koju se prihvati umenjenje od strane Prodavca, vrati Kupcu u roku od 14 dana.",
    "paragraph-69": "Kupac takođe ima pravo da raskine Ugovor ukoliko Prodavac ne otkloni nesaobraznost robe u utvrđenom roku.",
    "paragraph-70": "Kupac u svakom slučaju nema pravo na raskid Ugovora ukoliko je nesaobraznost robe neznatna.",
    "paragraph-71": "Troškove povrata robe u slučaju reklamacije snosi Prodavac",
    "paragraph-72": "*Nijansa boje artikala može da varira u zavisnosti od kvaliteta monitora preko koga Kupac vrši izbor artikala za kupovinu, tako da Prodavac ne snosi odgovornost za razlike između prikazane i realne boje*",
    "paragraph-73": "U slučaju reklamacije artikla i povraćaja sredstava plaćenih nekom od platnih kartica, FWI by Ivana Korab povraćaj vrši preko VISA, EC/MC i Maestro metoda plaćanja, što znači da će banka na naš zahtev obaviti povraćaj sredstava na račun korisnika kartice.",
  },

  "shipping-info-modal": {
    "paragraph-1": "Dostavu pošiljki vrši nezavisna kurirska služba i moguća je bilo gde u svetu. Troškovi dostave se posebno naplaćuju i oni su iskazani prilikom poručivanja robe. Iznos troškova dostave zavisi od težine pošiljke, kao i mesta gde se šalje. Troškove dostave plaćate zajedno sa proizvodima.",
    "paragraph-2": "Vreme za koje će vam pošiljka biti uručena je do 5  radnih dana od dana potvrđene porudžbine. Kurirska služba dostavlja pošiljke u periodu od 08:00h do 19:00h.",
    "paragraph-3": "Prilikom dostavljanja robe Kupcu, Kupcu će biti predat jedan primerak maloprodajnog računa, koji istovremeno predstavlja ugovor o kupoprodaji.",
    "paragraph-4": "Kupac je odgovoran za štetu koja nastane propuštanjem da preuzme robu koju mu je poslao Prodavac u skladu sa ugovorom.",
    "paragraph-5": "Pod štetom se podrazumeva oštećenje na robi, kao i troškovi koje Prodavac ima zbog propuštanja Kupca da preuzme robu, kao što su: troškovi čuvanja, prepakivanja, vraćanja robe i slično.",
  },

  "complaints-modal": {
    "paragraph-1": "Pored reklamacije radi ostvarenja prava po osnovu saobraznosti, možete izjaviti reklamaciju i zbog pogrešno obračunate cene i drugih nedostataka.",
    "paragraph-2": "Pretpostavlja se da je roba saobrazna Ugovoru: ",
    "paragraph-3": "ako odgovara opisu koji je dao Prodavac, i ako ima svojstva robe koju je Prodavac pokazao Kupcu kao uzorak ili model,",
    "paragraph-4": "ako ima svojstva potrebna za redovnu upotrebu robe iste vrste,",
    "paragraph-5": "ako ima svojstva potrebna za naročitu upotrebu za koju je Kupac nabavlja,",
    "paragraph-6": "ako po kvalitetu odgovara onome što je uobičajeno kod robe iste vrste i što Kupac može osnovano da očekuje i javna obećanja o posebnim svojstvima robe data od strane Prodavca, naročito ako je obećanje učinjeno putem oglasa ili na ambalaži robe.",
    "paragraph-7": "Reklamaciju na nesaobraznost kupac može izjaviti u roku od 2 godine od dana kupovine proizvoda ili prijema robe, uz originalan račun ili bilo koji drugi dokaz o kupovini robe.",
    "paragraph-8": "Ako nesaobraznost nastane u roku od 6 meseci od dana prelaska rizika na potrošača, može se pretpostaviti da je nesaobraznost postojala u trenutku prelaska rizika na potrošača.",
    "paragraph-9": "Na reklamaciju će Vam biti odgovoreno u roku od 8 (osam) dana, od dana podnošenja reklamacije.",
    "paragraph-10": "Sve troškove koji su neophodni da bi se roba saobrazila ugovoru, a naročito troškove rada, materijala, preuzimanja i isporuke, snosimo mi kao trgovac.",
    "paragraph-11": "Kao prodavac dužni smo da kupcu izdamo pisanu potvrdu ili elektrosnkim putem potvrdimo prijem reklamacije, odnosno saopštimo broj pod kojim je zavedena reklamacija u evidenciji primljenih reklamacija.",
    "paragraph-12": "Reklamaciju možete izjaviti:",
    "paragraph-13": "pisanim putem, preko Obrasca za reklamaciju koji se može preuzeti kao trajni nosač zapisa dostupnom na sajtu www.fwi.rs, uz istovremeno dostavljanje robe Prodavcu koja je predmet kupoprodaje i uz originalan primerak računa ili bilo koji drugi dokaz o kupovini.",
    "paragraph-14": "Rok za rešavanje po reklamaciji ne može da bude duži od 15 dana od dana podnošenja reklamacije.",
    "paragraph-15": "Produžavanje roka za rešavanje reklamacija moguće je samo jednom.",
    "paragraph-16": "Ako isporučena roba nije saobrazna Ugovoru, Kupac ima pravo da bira između otklanjanja nedostataka, zamene za isti ili drugi artikal i povrata novca.",
    "paragraph-17": "Drugi artikal za koji se vrši zamena mora biti iste ili veće vrednosti, uz doplatu.",
    "paragraph-18": "Ako otklanjanje nesaobraznosti nije moguće ili ako predstavlja nesrazmerno opterećenje za Prodavca, Kupac može da zahteva umenjenje cene ili da izjavi da raskida Ugovor. U zavisnosti od zahteva Kupca, Prodavac je dužan da celokupan iznos kupoprodajne cene ili deo cene za koju se prihvati umenjenje od strane Prodavca, vrati Kupcu u roku od 14 dana.",
    "paragraph-19": "Kupac takođe ima pravo da raskine Ugovor ukoliko Prodavac ne otkloni nesaobraznost robe u utvrđenom roku.",
    "paragraph-20": "Kupac u svakom slučaju nema pravo na raskid Ugovora ukoliko je nesaobraznost robe neznatna.",
    "paragraph-21": "Troškove povrata robe u slučaju reklamacije snosi Prodavac",
    "paragraph-22": "*Nijansa boje artikala može da varira u zavisnosti od kvaliteta monitora preko koga Kupac vrši izbor artikala za kupovinu, tako da Prodavac ne snosi odgovornost za razlike između prikazane i realne boje*",
    "paragraph-23": "U slučaju reklamacije artikla i povraćaja sredstava plaćenih nekom od platnih kartica, FWI by Ivana Korab povraćaj vrši preko VISA, EC/MC i Maestro metoda plaćanja, što znači da će banka na naš zahtev obaviti povraćaj sredstava na račun korisnika kartice.",
  },

  "goods-replacement-modal": {
    "paragraph-1": "Kupac može da zatraži zamenu u roku od 14 dana od dana prijema robe. Zamena robe se odnosi na zamenu veličine ili zamenu modela. U slučaju zamene roba se, zajedno sa popunjenim ",
    "paragraph-1-link": "Obrascem za zamenu",
    "paragraph-1-1": ", dostavlja Prodavcu, i u tom slučaju troškove zamene snosi Kupac.",
    "paragraph-2": "Obrazac za zamenu dostupan je na sajtu Prodavca www.fwi.rs i može se slobodno preuzeti kao trajni nosač zapisa.",
    "paragraph-3": "U slučaju zamene Kupac je u obavezi da robu vrati bez oštećenja i tragova korišćenja. U suprotnom, Prodavac nije u obavezi da obavi zamenu.",
    "paragraph-4": "Artikal za koji se vrši zamena, mora imati istu ili veću vrednost od artikla koji se menja, uz doplatu.",
    "paragraph-5": "U periodima sezonskih sniženja isključivo radimo povrat novca. Robu nam šaljete zajedno sa popunjenom",
    "paragraph-5-link": "Izjavom o odustajanju od Ugovora",
  },

  "prvacy-policy-modal": {
    "title" : 'Zaštita privatnosti korisnika ',
    "paragraph-1": "U ime FWI d.o.o obavezujemo se da ćemo čuvati privatnost svih naših kupaca. Prikupljamo samo neophodne, osnovne podatke o kupcima/ korisnicima i podatke neophodne za poslovanje i informisanje korisnika u skladu sa dobrim poslovnim običajima i u cilju pružanja kvalitetne usluge. Dajemo kupcima mogućnost izbora uključujući mogućnost odluke da li žele ili ne da se izbrišu sa mailing lista koje se koriste za marketinške kampanje. Svi podaci o korisnicima/kupcima se strogo čuvaju i dostupni su samo zaposlenima kojima su ti podaci nužni za obavljanje posla. Svi zaposleni [ime prodajnog mesta] (i poslovni partneri) odgovorni su za poštovanje načela zaštite privatnosti.",
  },

  "protection-of-confidential-info-modal": {
    "title" : 'Zaštita poverljivih podataka o transakciji',
    "paragraph-1": "Prilikom unošenja podataka o platnoj kartici, poverljive informacija se prenose putem javne mreže u zaštićenoj (kriptovanoj) formi upotrebom SSL protokola i PKI sistema, kao trenutno najsavremenije kriptografske tehnologije.",
    "paragraph-2": "Sigurnost podataka prilikom kupovine, garantuje procesor platnih kartica, Banca Intesa ad Beograd, pa se tako kompletni proces naplate obavlja na stranicama banke. Niti jednog trenutka podaci o platnoj kartici nisu dostupni našem sistemu.",
  },

  "refund-info-modal": {
    "title" : "Povraćaj sredstava",
    "paragraph-1": "U slučaju vraćanja robe i povraćaja sredstava kupcu koji je prethodno platio nekom od platnih kartica, delimično ili u celosti, a bez obzira na razlog vraćanja, FWI d.o.o je u obavezi da povraćaj vrši isključivo preko VISA, EC/MC i Maestro metoda plaćanja, što znači da će banka na zahtev prodavca obaviti povraćaj sredstava na račun korisnika kartice."
  },

  "profile": {
    "info": "Info",
    "likes": "Sviđanja",
    "purchases": "Kupovine",
    "wallet": "Novčanik",
    "logout": "Odjavi se",
    "info-tab": {
      'title': 'Informacije o profilu',
      'reset-password': 'Resetuj šifru',
      "email-reset-info": "Kontaktirajte nas kako bismo resetovali Vaš email",
      'deactivate-account' : 'Deaktiviraj nalog',
      'confirm-changes' : 'Prihvati izmene',
       
    },
    "likes-tab":{
      "no-products": "Trenutno nemate lajkovanih proizvoda"
    },
    "change-password": {
      "old-password": "Postojeća šifra",
      "new-password" : "Nova šifra",
      "repeat-password" : "Ponovite novu šifru"
    }
  },

  "wallet": {
    "available-funds": "Raspoloživa sredstva",
    "pay": "Uplati",
  },
  "countries_placeholder": 'Država',
  "country-not-found": 'Nema rezultata pretrage',
  "countries" : [
    {id:'Albanija', name: " Albanija",},
    {id:'Alžir', name: " Alžir",},
    {id:'Američka Samoa', name: " Američka Samoa",},
    {id:'Andora', name: " Andora",},
    {id:'Angola', name: " Angola",},
    {id:'Angvila', name: " Angvila",},
    {id:'Antigva', name: " Antigva",},
    {id:'Argentina', name: " Argentina",},
    {id:'Aruba', name: " Aruba",},
    {id:'Australija', name: " Australija",},
    {id:'Austrija', name: " Austrija",},
    {id:'Avganistan', name: " Avganistan",},
    {id:'Azerbejdžan', name: " Azerbejdžan",},
    {id:'Bahami', name: " Bahami",},
    {id:'Bahrein', name: " Bahrein",},
    {id:'Bangladeš', name: " Bangladeš",},
    {id:'Barbados', name: " Barbados",},
    {id:'Belgija', name: " Belgija",},
    {id:'Belize', name: " Belize",},
    {id:'Belorusija', name: " Belorusija",},
    {id:'Benin', name: " Benin",},
    {id:'Bermudska ostrva', name: " Bermudska ostrva",},
    {id:'Bocvana', name: " Bocvana",},
    {id:'Bolivija', name: " Bolivija",},
    {id:'Boner', name: " Boner",},
    {id:'Bosna i Hercegovina', name: " Bosna i Hercegovina",},
    {id:'Brazil', name: " Brazil",},
    {id:'Brunej', name: " Brunej",},
    {id:'Bugarska', name: " Bugarska",},
    {id:'Burkina Faso', name: " Burkina Faso",},
    {id:'Burundi', name: " Burundi",},
    {id:'Butan', name: " Butan",},
    {id:'Centralnoafrička Republika', name: " Centralnoafrička Republika",},
    {id:'Crna Gora', name: " Crna Gora",},
    {id:'Čad', name: " Čad",},
    {id:'Češka Republika', name: " Češka Republika",},
    {id:'Čile', name: " Čile",},
    {id:'Danska', name: " Danska",},
    {id:'Devičanska ostrva (Američka)', name: " Devičanska ostrva (Američka)",},
    {id:'Devičanska ostrva (Britanska)', name: " Devičanska ostrva (Britanska)",},
    {id:'Dominika', name: " Dominika",},
    {id:'Dominikanska Republika', name: " Dominikanska Republika",},
    {id:'Džerzi', name: " Džerzi",},
    {id:'Džibuti', name: " Džibuti",},
    {id:'Egipat', name: " Egipat",},
    {id:'Ekvador', name: " Ekvador",},
    {id:'El Salvador', name: " El Salvador",},
    {id:'Eritreja', name: " Eritreja",},
    {id:'Estonija', name: " Estonija",},
    {id:'Etiopija', name: " Etiopija",},
    {id:'Farska ostrva', name: " Farska ostrva",},
    {id:'Fidži', name: " Fidži",},
    {id:'Filipini', name: " Filipini",},
    {id:'Finska', name: " Finska",},
    {id:'Folklandska ostrva', name: " Folklandska ostrva",},
    {id:'Francuska', name: " Francuska",},
    {id:'Francuska Gvajana', name: " Francuska Gvajana",},
    {id:'Gabon', name: " Gabon",},
    {id:'Gambija', name: " Gambija",},
    {id:'Gana', name: " Gana",},
    {id:'Gernzi', name: " Gernzi",},
    {id:'Gibraltar', name: " Gibraltar",},
    {id:'Grčka', name: " Grčka",},
    {id:'Grenada', name: " Grenada",},
    {id:'Grenland', name: " Grenland",},
    {id:'Gruzija', name: " Gruzija",},
    {id:'Guam', name: " Guam",},
    {id:'Gvadalupe', name: " Gvadalupe",},
    {id:'Gvajana (Britanska)', name: " Gvajana (Britanska)",},
    {id:'Gvatemala', name: " Gvatemala",},
    {id:'Gvineja - Bisao', name: " Gvineja - Bisao",},
    {id:'Gvineja - Ekvatorijalna', name: " Gvineja - Ekvatorijalna",},
    {id:'Gvineja Republika', name: " Gvineja Republika",},
    {id:'Haiti', name: " Haiti",},
    {id:'Holandija', name: " Holandija",},
    {id:'Holandski Antili', name: " Holandski Antili",},
    {id:'Honduras', name: " Honduras",},
    {id:'Hong Kong', name: " Hong Kong",},
    {id:'Hrvatska', name: " Hrvatska",},
    {id:'Indija', name: " Indija",},
    {id:'Indonezija', name: " Indonezija",},
    {id:'Irak', name: " Irak",},
    {id:'Iran', name: " Iran",},
    {id:'Irska', name: " Irska",},
    {id:'Island', name: " Island",},
    {id:'Istočni Timor', name: " Istočni Timor",},
    {id:'Italija', name: " Italija",},
    {id:'Izrael', name: " Izrael",},
    {id:'Jamajka', name: " Jamajka",},
    {id:'Japan', name: " Japan",},
    {id:'Jemen', name: " Jemen",},
    {id:'Jermenija', name: " Jermenija",},
    {id:'Jordan', name: " Jordan",},
    {id:'Južna Afrika', name: " Južna Afrika",},
    {id:'Južna Koreja', name: " Južna Koreja",},
    {id:'Južni Sudan', name: " Južni Sudan",},
    {id:'Kajmanska ostrva', name: " Kajmanska ostrva",},
    {id:'Kambodža', name: " Kambodža",},
    {id:'Kamerun', name: " Kamerun",},
    {id:'Kanada', name: " Kanada",},
    {id:'Kanarska ostrva', name: " Kanarska ostrva",},
    {id:'Katar', name: " Katar",},
    {id:'Kazahstan', name: " Kazahstan",},
    {id:'Kenija', name: " Kenija",},
    {id:'Kina', name: " Kina",},
    {id:'Kipar', name: " Kipar",},
    {id:'Kirgistan', name: " Kirgistan",},
    {id:'Kiribati', name: " Kiribati",},
    {id:'Kolumbija', name: " Kolumbija",},
    {id:'Komori', name: " Komori",},
    {id:'Kongo', name: " Kongo",},
    {id:'Kongo, Demokratska Republika', name: " Kongo, Demokratska Republika",},
    {id:'Kostarika', name: " Kostarika",},
    {id:'Kuba', name: " Kuba",},
    {id:'Kukova ostrva', name: " Kukova ostrva",},
    {id:'Kurasao', name: " Kurasao",},
    {id:'Kuvajt', name: " Kuvajt",},
    {id:'Laos', name: " Laos",},
    {id:'Letonija', name: " Letonija",},
    {id:'Lesoto', name: " Lesoto",},
    {id:'Liberija', name: " Liberija",},
    {id:'Liban', name: " Liban",},
    {id:'Libija', name: " Libija",},
    {id:'Lihtenštajn', name: " Lihtenštajn",},
    {id:'Litvanija', name: " Litvanija",},
    {id:'Luksemburg', name: " Luksemburg",},
    {id:'Madagaskar', name: " Madagaskar",},
    {id:'Mađarska', name: " Mađarska",},
    {id:'Majote', name: " Majote",},
    {id:'Makao', name: " Makao",},
    {id:'Malavi', name: " Malavi",},
    {id:'Maldivi', name: " Maldivi",},
    {id:'Malezija', name: " Malezija",},
    {id:'Mali', name: " Mali",},
    {id:'Malta', name: " Malta",},
    {id:'Maroko', name: " Maroko",},
    {id:'Maršalska ostrva', name: " Maršalska ostrva",},
    {id:'Martinik', name: " Martinik",},
    {id:'Mauricijus', name: " Mauricijus",},
    {id:'Mauritanija', name: " Mauritanija",},
    {id:'Meksiko', name: " Meksiko",},
    {id:'Mijanmar', name: " Mijanmar",},
    {id:'Mikronezija', name: " Mikronezija",},
    {id:'Moldavija', name: " Moldavija",},
    {id:'Monako', name: " Monako",},
    {id:'Mongolija', name: " Mongolija",},
    {id:'Monserat', name: " Monserat",},
    {id:'Mozambik', name: " Mozambik",},
    {id:'Namibija', name: " Namibija",},
    {id:'Nauru', name: " Nauru",},
    {id:'Nemačka', name: " Nemačka",},
    {id:'Nepal', name: " Nepal",},
    {id:'Nevis', name: " Nevis",},
    {id:'Niger', name: " Niger",},
    {id:'Nigerija', name: " Nigerija",},
    {id:'Nikaragva', name: " Nikaragva",},
    {id:'Niue', name: " Niue",},
    {id:'Norveška', name: " Norveška",},
    {id:'Nova Kaledonija', name: " Nova Kaledonija",},
    {id:'Novi Zeland', name: " Novi Zeland",},
    {id:'Obala Slonovače', name: " Obala Slonovače",},
    {id:'Oman', name: " Oman",},
    {id:'Pakistan', name: " Pakistan",},
    {id:'Palau', name: " Palau",},
    {id:'Panama', name: " Panama",},
    {id:'Papua Nova Gvineja', name: " Papua Nova Gvineja",},
    {id:'Paragvaj', name: " Paragvaj",},
    {id:'Peru', name: " Peru",},
    {id:'Poljska', name: " Poljska",},
    {id:'Portugalija', name: " Portugalija",},
    {id:'Portoriko', name: " Portoriko",},
    {id:'Reunion ostrva', name: " Reunion ostrva",},
    {id:'Ruanda', name: " Ruanda",},
    {id:'Rumunija', name: " Rumunija",},
    {id:'Ruska Federacija', name: " Ruska Federacija",},
    {id:'Samoa', name: " Samoa",},
    {id:'San Marino', name: " San Marino",},
    {id:'Saudijska Arabija', name: " Saudijska Arabija",},
    {id:'Sejšeli', name: " Sejšeli",},
    {id:'Senegal', name: " Senegal",},
    {id:'Severna Koreja', name: " Severna Koreja",},
    {id:'Severna Makedonija', name: " Severna Makedonija",},
    {id:'Severna Marijanska ostrva (Saipan)', name: " Severna Marijanska ostrva (Saipan)",},
    {id:'Sijera Leone', name: " Sijera Leone",},
    {id:'Singapur', name: " Singapur",},
    {id:'Sirija', name: " Sirija",},
    {id:'Sjedinjene Američke Države', name: " Sjedinjene Američke Države",},
    {id:'Slovačka', name: " Slovačka",},
    {id:'Slovenija', name: " Slovenija",},
    {id:'Solomonska ostrva', name: " Solomonska ostrva",},
    {id:'Somalija', name: " Somalija",},
    {id:'Somalilend (Severna Somalija)', name: " Somalilend (Severna Somalija)",},
    {id:'Srbija', name: " Srbija",},
    {id:'Svazilend', name: " Svazilend",},
    {id:'Sveti Bartolomej', name: " Sveti Bartolomej",},
    {id:'Sveti Eustahije', name: " Sveti Eustahije",},
    {id:'Sveti Kits', name: " Sveti Kits",},
    {id:'Sveta Lucija', name: " Sveta Lucija",},
    {id:'Sveti Martin', name: " Sveti Martin",},
    {id:'Sveti Toma i Princip', name: " Sveti Toma i Princip",},
    {id:'Sveta Jelena', name: " Sveta Jelena",},
    {id:'Sveti Vinsent', name: " Sveti Vinsent",},
    {id:'Sudan', name: " Sudan",},
    {id:'Surinam', name: " Surinam",},
    {id:'Španija', name: " Španija",},
    {id:'Šri Lanka', name: " Šri Lanka",},
    {id:'Švedska', name: " Švedska",},
    {id:'Švajcarska', name: " Švajcarska",},
    {id:'Tahiti', name: " Tahiti",},
    {id:'Tajvan', name: " Tajvan",},
    {id:'Tadžikistan', name: " Tadžikistan",},
    {id:'Tanzanija', name: " Tanzanija",},
    {id:'Tajland', name: " Tajland",},
    {id:'Togo', name: " Togo",},
    {id:'Tonga', name: " Tonga",},
    {id:'Trinidad i Tobago', name: " Trinidad i Tobago",},
    {id:'Tunis', name: " Tunis",},
    {id:'Turkmenistan', name: " Turkmenistan",},
    {id:'Turska', name: " Turska",},
    {id:'Turks i Kaikos ostrva', name: " Turks i Kaikos ostrva",},
    {id:'Tuvalu', name: " Tuvalu",},
    {id:'Uganda', name: " Uganda",},
    {id:'Ukrajina', name: " Ukrajina",},
    {id:'Ujedinjeni Arapski Emirati', name: " Ujedinjeni Arapski Emirati",},
    {id:'Urugvaj', name: " Urugvaj",},
    {id:'Uzbekistan', name: " Uzbekistan",},
    {id:'Vanuatu', name: " Vanuatu",},
    {id:'Vatikan', name: " Vatikan",},
    {id:'Velika Britanija', name: " Velika Britanija",},
    {id:'Venecuela', name: " Venecuela",},
    {id:'Vijetnam', name: " Vijetnam",},
    {id:'Zambija', name: " Zambija",},
    {id:'Zelenortska ostrva', name: " Zelenortska ostrva",},
    {id:'Zimbabve', name: " Zimbabve",},
  ],
  'warehouse-error-modal':{
    'title' : 'Nažalost nisu svi proizvodi iz Vaše korpu dostupni',
  },
  'review-and-buy':{
    'title' : "Pregled informacija",
    'unexpected-error' : 'Žao nam je, došlo je do greške',
    'payment-method' : 'Način plaćanja',
    'wallet-payment' : 'Novčanik',
    'card-payment' : 'Kartica',
    'cash-payment' : 'Pouzećem',
    'buy': 'Poruči', 
    'total-cart' : 'Iznos korpa',
    'delivery-price' : 'Iznos dostava',
    'discount-coupon' : 'Šifra za popust',
    'apply-discount' : 'Zavedi',
    'discount' : 'Iznos popust',
    'total' : 'Ukupno',
    'back': 'Nazad'
  },

  "shop": {
    "sort-by": "Sortiraj prema",
    "popularity": "Popularnosti",
    "price-1": "Ceni",
    "price-2": "Cena",
    "filters": "Filteri",
    "choose-filter": "Izaberi filter",
    "clear-all": "Izbriši sve",
    "products": "Proizvodi",
    "size": "Veličina",
    "color": "Boja",
    "of": "Od",
    "items": "Artikala",
  },

  "contact-information": {
    "contact-information": "Kontakt informacije",
    "already-have-an-account": "Da li već posedujete nalog?",
    "log-in": "Prijava",
    "email": "Email",
    "keep-me-up-to-date": "Zanimaju me novosti i posebne ponude",
    "first-name": "Ime",
    "last-name": "Prezime",
    "address": "Adresa",
    "apt-suite": "Sprat, stan",
    "postal-code": "Poštanski broj",
    "city": "Grad",
    "country": "Država",
    "state-providence": "Region",
    "phone-number": "Broj telefona",
    "shipping-address-is-the-same-as-invoice-address": "Koristiti istu adresu za isporuku",
    "i-want-shippment-to-another-address": "Želim da mi se porudžbina isporuči na zasebnu adresu",
    "return-to-shopping-bag": "Povratak na korpu",
    "please-fill-in-all-the-fields": "Molim Vas popunite sva polja",
    "proceed-to-shipping": "Isporuka",
    "password": "Lozinka",
    "repeat-password": "Ponovo unesite lozinku",
  },

  "shipping": {
    "shipping-company": "Dostavljač",
    "shipping-type": "Tip dostave",
    "shipping-amount": "Cena dostave",
    "payment-type": "Način plaćanja",
    "shipping-address" : "Adresa za dostavu",
    "shipping-info" : "Dostavljač će Vas kontaktirati u vezi datuma i vremena isporuke. Isporuka traje od 2 do 5 dana.",
    "back" : "Nazad",
    "continue" : "Pregled porudžbine"
  },

  "purchases": {
    "purchase-code": "Kod porudžbine",
    "status": "Status",
    "no-purchases": "Trenutno nemate ni jednu porudžbinu",
    "discount": "Popust"
  },

  "product": {
    "name": "Naziv",
    "color": "Boja",
    "size" : 'Veličina',
    "quantity": "Količina",
  },

  "errors": {
    "passwords-must-match": "Lozinke se moraju poklapati",
    "required-field": "Obavezno polje",
    "you-must-fill-required-fields": "Morate popuniti obavezna polja",
  },

  "session-expired-message": "Sesija je istekla. Molim Vas ponovo se ulogujte.",
  'deactivate-account-modal': {
    'heading': 'Žao nam je što odlazite!',
    'text': 'Da li ste sigurni da želite da deaktivirate profil?',
    'yes': "Deaktiviraj nalog",
    'no': 'Odustani'
  },
  'reset-password-modal':{
    'email': 'Email',
    'invalid': 'Molim Vas unesite email adresu',
    'confirm' : 'Potvrdi'
  },
  'search-modal':{
    'placeholder': 'Pretražite proizvode i kategorije po nazivu',
    'categories' : 'Kategorije',
    'products' : 'Proizvodi',
    'no-results' : 'Nažalost nema rezultata za vašu pretragu. Pokušajte da unesete drugu ključnu reč.'
  },
  'thank-you-modal':{
    'heading-1': 'Hvala Vam na ukazanom poverenju!',
    'heading-2' : 'Uspešno ste završili kupovinu',
    'confirmation-code' : 'Kod porudžbine',
    'email-sent-to': 'Potvrda o plaćanju je poslata na email adresu: ',
    'check-order': 'Porudžbinu možete proveriti na Vašem profilu',
    'continue-shopping' : 'Nastavite sa kupovinom'
  },
  'product-details' : {
    'description' : 'Opis',
    'materials' : 'Materijali',
    'out-of-stock' : 'Nema na stanju',
    'select-a-color' : 'Odaberite boju i veličinu',
  },
  'similar-products' : {
    'title' : 'Slični proizvodi'
  },
  'popular-products' : {
    'title' : 'Popularni proizvodi'
  },
  'shop-navigation' : {
    'to-shop' : 'Povratak u prodavnicu',
    'previous' : 'Prethodni proizvod',
    'next' : 'Sledeći proizvod',
  },
  'home': {
    'welcome' : 'Dobrodošli',
    'new-arrivals' : 'Novi proizvodi',
    'browse-all-products' : 'Pregledaj sve proizvode',
    'favourites' : 'FWI omiljeno',
    'shop-category' : 'Pregled po kategorijama',
    'new-product' : 'Novi proizvod',
    'fashion-health' : 'Moda i zdravlje'
  },
  'side-cart': {
    'summary' : 'Pregled porudžbine',
    'subtotal' : 'Iznos korpe',
    'discount' : 'Popust',
    'shipping' : 'Iznos dostave',
    'total' : 'Ukupno'
  },
  'about':{
    'title' : 'O nama',
    'paragraph-1':'Sportskog ste duha i pritom volite modu i pratite modne trendove? Bilo da je Vaš stil već prepoznatljiv ili ga rado prilagođavate nadolazećim trendovima iz sveta mode, linija ženske garderobe novog brenda FWI će Vas potpuno oboriti s nogu.',
    'paragraph-2':'Dok žurno obavljate poslove u gradu, družite se unutar tržnog centra ili provodite večernje časove uživajući u izlascima, komadi FWI kolekcije su tu da Vas u stopu prate.',
    'paragraph-3':'Inspirisana modom i životnim stilom svojstvenim Los Anđelesu, linija FWI sportske opreme naše uspešne manekenke Ivane Korab učiniće da svaki Vaš aktivan dan bude ispraćen, pre svega udobnošću, ali u isto vreme elegancijom i svedenošću.',
    'paragraph-4':'Glavna odlika svakog odevnog predmeta iz kolekcije FWI je isticanje ličnog pečata svake dame ponaosob na moderan način. Višenamenski komadi izrađeni od kvalitetnih i pažljivo odabranih materijala uklopiće se u kombinacije koje nosite od jutra do mraka.',
    'paragraph-5':'Ekskluzivan enterijer same radnje kao i uvek nasmejana lica naših devojaka, stvaraju atmosferu koja će Vas trajno privući. Bilo da želite obradovati sebe ili nekoga koga volite, ne propustite posetu našoj radnji.'
  },
  'register-banner': {
    'heading' : 'Postanite deo FWI tima',
    'button' : 'Registruj se',
  },
  'blog':{
    'no-posts': 'Trenutno nema postova. Vratite se kasnije',
    'back-to': 'Povratak na blogove',
    'similar-posts': 'Slične objave'
  },
  'continue-shopping':{
    'paragraph-1': 'Da li želite još nešto?',
    'button': 'Nastavite s kupovinom',
  }
};