
import { Vue, Component } from "vue-property-decorator";
import ArrowRightIcon from "@/icons/ArrowRightIcon.vue";
import AppAsideCart from "@/components/AppAsideCart.vue";
import AppCartSteps from "@/components/AppCartSteps.vue";
import AppThankYouModal from "@/components/modals/AppThankYouModal.vue";

import WalletIcon from "@/icons/WalletIcon.vue";
import BankPaymentForm from "../components/BankPaymentForm.vue";
import { modal } from "@/store/modal";
import { CartStore } from "../store/cart";
import { NavigationStore } from "../store/navigation";
import { OrderStore } from "../store/order";
import { CustomerStore } from "../store/customer";
import { OverlayStore } from "../store/overlayLoader";
import { paymentType } from "@/utilities/constants.ts";

//@ts-ignore
import MasterCard from "@/assets/backgrounds/payment_cards/master-card.png";
//@ts-ignore
import MaestroCard from "@/assets/backgrounds/payment_cards/maestro-card.png";
//@ts-ignore
import Visa from "@/assets/backgrounds/payment_cards/visa.png";
//@ts-ignore
import SecureCode from "@/assets/backgrounds/payment_cards/mc_idcheck.svg";
//@ts-ignore
import DinaCard from "@/assets/backgrounds/payment_cards/DinaCard.jpg";
//@ts-ignore
import VerByVisa from "@/assets/backgrounds/payment_cards/ver-by-visa.jpg";
//@ts-ignore
import Intesa from "@/assets/backgrounds/payment_cards/logo-intesa.png";
//@ts-ignore
import AmericanExpress from "@/assets/backgrounds/payment_cards/AMERICAN EXPRESS.png";

@Component({
  components: {
    WalletIcon,
    ArrowRightIcon,
    AppAsideCart,
    AppCartSteps,
    AppThankYouModal,
    BankPaymentForm,
  },
})
export default class ReviewAndBuy extends Vue {
  CartStore: any = CartStore;
  NavigationStore: any = NavigationStore;
  OrderStore: any = OrderStore;
  CustomerStore: any = CustomerStore;
  shippingCompany!: string;
  deliveryTime!: string;
  showModal: boolean = true;
  modal: any = modal;
  discount: string | null = null;
  imageUrlPrefix: string | undefined = process.env.VUE_APP_BACKEND_IMAGE_PREFIX;
  captchaKey: string | undefined = process.env.VUE_APP_CAPTCHA_KEY;
  termsOfPurchase: boolean = false;
  thankYouModalOrderId: string | null = null;
  thankYouModalEmail: string | null = null;
  warehouseErrorProducts: any = null;
  isWalletPayment: boolean = false;
  isCaptchaVerified: boolean = false;
  isPaymentDisabled: boolean = false;
  selectedPaymentMethod: string = paymentType.cash;
  paymentMethodsAll: any = [
    { id: paymentType.cash, value: this.$t("review-and-buy.cash-payment") },
    { id: paymentType.card, value: this.$t("review-and-buy.card-payment") },
    { id: paymentType.wallet, value: this.$t("review-and-buy.wallet-payment") },
  ];
  paymentCards: any = [
    {
      src: MasterCard,
      alt: "MasterCard",
    },
    { src: MaestroCard, alt: "MaestroCard" },
    {
      src: Visa,
      alt: "Visa",
    },
    {
      src: AmericanExpress,
      alt: "AmericanExpress",
    },
    { src: DinaCard, alt: "DinaCard" },
  ];
  paymentPrograms: any = [
    {
      src: SecureCode,
      alt: "SecureCode",
      link: "http://www.mastercard.com/rs/consumer/credit-cards.html",
      target: "_blank",
    },
    {
      src: VerByVisa,
      alt: "VerifiedByVisa",
      link:
        "https://rs.visa.com/pay-with-visa/security-and-assistance/protected-everywhere.html",
      target: "_blank",
    },
  ];
  bankLogo: any = {
    src: Intesa,
    alt: "BancaIntesa",
    link: "https://www.bancaintesa.rs",
    target: "_blank",
  };
  $refs!: {
    paymentForm: HTMLElement;
  };
  get isSubmitDisabled(): boolean {
    if (!CustomerStore.token) {
      return !this.isCaptchaVerified || !this.termsOfPurchase;
    }
    return !this.termsOfPurchase;
  }
  get paymentMethods() {
    return !CustomerStore.token
      ? this.paymentMethodsAll.filter((m: any) => m.id != paymentType.wallet)
      : this.paymentMethodsAll;
  }
  get cart() {
    return CartStore.cart;
  }
  get shippingCountry() {
    if (
      OrderStore.order &&
      OrderStore.order.details &&
      OrderStore.order.details.country
    ) {
      //@ts-ignore
      const country = this.$t("countries").find(
        (c: any) => c.id === OrderStore.order.details.country
      );
      return country && country.name ? country.name : null;
    }

    return null;
  }
  get orderDetails() {
    return OrderStore.order && OrderStore.order.details
      ? OrderStore.order.details
      : null;
  }
  get orderTotals() {
    return OrderStore.order && OrderStore.order.totals
      ? OrderStore.order.totals
      : null;
  }
  get discountPrice() {
    return this.orderTotals && this.orderTotals.discount_amount
      ? this.orderTotals.discount_amount
      : null;
  }
  get orderShipping() {
    return this.orderTotals && this.orderTotals.shipping_amount
      ? this.orderTotals.shipping_amount
      : null;
  }
  get totalSum() {
    if (this.orderTotals) {
      return this.orderTotals && this.orderTotals.total
        ? this.orderTotals.total
        : null;
    }
    let sum = 0;
    sum += this.totalPrice ? this.totalPrice : 0;
    sum -= this.discountPrice ? this.discountPrice : 0;
    sum += this.orderShipping ? this.orderShipping : 0;
    return sum;
  }

  get totalPrice() {
    if (this.orderTotals && this.orderTotals.session_amount) {
      return this.orderTotals.session_amount;
    }
    let total: number = 0;
    if (CartStore.cart && CartStore.cart.length > 0) {
      CartStore.cart.forEach((item: any, i: number) => {
        let totalItemPrice = item.price * item.quantity;
        total += totalItemPrice;
      });
      return total;
    }
    return 0;
  }
  public async finalizeOrder() {
    this.isPaymentDisabled = true;
    OverlayStore.setLoader(true);
    switch (this.selectedPaymentMethod) {
      case paymentType.cash:
        await this.finalizeCashPayment();
        break;
      case paymentType.card:
        await this.finalizeCardPayment();
        break;
      case paymentType.wallet:
        await this.finalizeWalletPayment();
        break;
      default:
    }
  }

  verifyCaptcha() {
    this.isCaptchaVerified = true;
  }
  public async finalizeWalletPayment() {
    try {
      this.isPaymentDisabled = true;
      const result = await OrderStore.finalizeWalletPayment();
      if (result.status) {
        CartStore.setCart([]);
        OrderStore.setOrder(null);
        this.thankYouModalOrderId = result.orderId;
        this.thankYouModalEmail = result.email;
        this.modal.set({ name: "thankYou", show: true });
      } else {
        if (result.warehouse_error) {
          OrderStore.setWarehouseErrorProducts(result.products);
          this.modal.set({ name: "warehouseError", show: true });
        }
      }
    } finally {
      this.isPaymentDisabled = false;
      OverlayStore.setLoader(false);
    }
  }
  public async finalizeCardPayment() {
    this.isPaymentDisabled = true;
    const result = await OrderStore.finalizeCardOrder();
    if (result.status) {
      CartStore.setCart([]);
      OrderStore.setOrder(null);
      await this.sendPaymentForm(result.form_details);
    } else {
      this.isPaymentDisabled = false;
      if (result.warehouse_error) {
        OrderStore.setWarehouseErrorProducts(result.products);
        this.modal.set({ name: "warehouseError", show: true });
      }
    }
  }
  public async finalizeCashPayment() {
    try {
      this.isPaymentDisabled = true;
      const result = await OrderStore.finalizeCashOrder();
      if (result.status) {
        CartStore.setCart([]);
        OrderStore.setOrder(null);
        this.thankYouModalOrderId = result.orderId;
        this.thankYouModalEmail = result.email;
        this.modal.set({ name: "thankYou", show: true });
      } else {
        this.isPaymentDisabled = false;
        if (result.warehouse_error) {
          OrderStore.setWarehouseErrorProducts(result.products);
          this.modal.set({ name: "warehouseError", show: true });
        }
      }
    } finally {
      OverlayStore.setLoader(false);
    }
  }
  public async sendPaymentForm(paymentParams: any) {
    //@ts-ignore
    this.$refs["paymentForm"]["$refs"]["okUrl"].value = null;
    //@ts-ignore
    this.$refs["paymentForm"]["$refs"]["failUrl"].value = null;
    //@ts-ignore
    this.$refs["paymentForm"]["$refs"]["shopurl"].value = null;
    //@ts-ignore
    this.$refs["paymentForm"]["$refs"]["BFlang"].value = null;
    //@ts-ignore
    this.$refs["paymentForm"]["$refs"]["BFcurrency"].value = null;
    //@ts-ignore
    this.$refs["paymentForm"]["$refs"]["BFamount"].value = null;
    //@ts-ignore
    this.$refs["paymentForm"]["$refs"]["BFoid"].value = null;
    //@ts-ignore
    this.$refs["paymentForm"]["$refs"]["BFrnd"].value = null;
    //@ts-ignore
    this.$refs["paymentForm"]["$refs"]["BFhash"].value = null;

    //@ts-ignore
    this.$refs["paymentForm"]["$refs"]["okUrl"].value =
      process.env.VUE_APP_PAYMENT_REDIRECT;
    //@ts-ignore
    this.$refs["paymentForm"]["$refs"]["failUrl"].value =
      process.env.VUE_APP_PAYMENT_REDIRECT;
    //@ts-ignore
    this.$refs["paymentForm"]["$refs"]["shopurl"].value =
      paymentParams["cancel_url"];
    //@ts-ignore
    this.$refs["paymentForm"]["$refs"]["BFlang"].value = paymentParams["lang"];
    //@ts-ignore
    this.$refs["paymentForm"]["$refs"]["BFcurrency"].value =
      paymentParams["currency_code"];
    //@ts-ignore
    this.$refs["paymentForm"]["$refs"]["BFamount"].value =
      paymentParams["amount"];
    //@ts-ignore
    this.$refs["paymentForm"]["$refs"]["BFoid"].value = paymentParams["oid"];
    //@ts-ignore
    this.$refs["paymentForm"]["$refs"]["BFrnd"].value = paymentParams["rnd"];
    //@ts-ignore
    this.$refs["paymentForm"]["$refs"]["BFhash"].value = paymentParams["hash"];
    //@ts-ignore
    this.$refs["paymentForm"]["$refs"]["BFform"].submit();
  }
  async mounted() {
    await OrderStore.getOrderDetails();
    CartStore.fetchCart();
  }
}
