
import { Component, Vue } from "vue-property-decorator";
import { NavigationStore } from "../store/navigation";
import { CustomerStore } from "../store/customer";
import { OverlayStore } from "../store/overlayLoader";
@Component({})
export default class Register extends Vue {
  NavigationStore: any = NavigationStore;
  CustomerStore: any = CustomerStore;
  email: string | null = null;
  password: string | null = null;
  passwordRepeated: string | null = null;
  firstName: string | null = null;
  lastName: string | null = null;
  address: string | null = null;
  aptSuite: string | null = null;
  postalCode: string | null = null;
  city: string | null = null;
  country: any | null = null;
  stateProvidence: string | null = null;
  phone: string | null = null;
  isInvalidPassword: boolean = false;
  areInvalidFields: boolean = false;

  mounted() {
    if (!!CustomerStore.token) {
      this.$router.push("/");
    }
  }

  public async registerCustomer() {
    // check password and password repeated
    if (this.checkIsDataValid()) {
      let data = {
        email: this.email && this.email.trim() ? this.email.trim() : null,
        password:
          this.password && this.password.trim() ? this.password.trim() : null,
        passwordRepeated:
          this.passwordRepeated && this.passwordRepeated.trim()
            ? this.passwordRepeated.trim()
            : null,
        first_name: this.firstName,
        last_name: this.lastName,
        address:
          this.address && this.address.trim() ? this.address.trim() : null,
        apt_suite:
          this.aptSuite && this.aptSuite.trim() ? this.aptSuite.trim() : null,
        postal_code:
          this.postalCode && this.postalCode.trim()
            ? this.postalCode.trim()
            : null,
        city: this.city && this.city.trim() ? this.city.trim() : null,
        country: this.country && this.country.id ? this.country.id : null,
        state_providence:
          this.stateProvidence && this.stateProvidence.trim()
            ? this.stateProvidence.trim()
            : null,
        phone: this.phone && this.phone.trim() ? this.phone.trim() : null,
      };
      OverlayStore.setLoader(true);
      try {
        if (await CustomerStore.registerCustomer(data)) {
          this.$router.push("/");
        }
      } finally {
        OverlayStore.setLoader(false);
      }
    }
  }
  public checkIsDataValid(): boolean {
    let result: boolean = true;

    if (
      !this.password ||
      !this.passwordRepeated ||
      this.passwordRepeated !== this.password
    ) {
      this.isInvalidPassword = true;
      result = false;
    }
    if (
      !this.email ||
      this.email.trim() === "" ||
      !this.firstName ||
      this.firstName.trim() === "" ||
      !this.lastName ||
      this.lastName.trim() === ""
    ) {
      this.areInvalidFields = true;
      result = false;
    }

    return result;
  }
}
