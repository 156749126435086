import { regions } from './../utilities/constants';
import { Module, VuexModule, Mutation, Action, getModule, } from 'vuex-module-decorators'
import {websiteSearch} from '../api/products';
import store from "./index";
import axios from '../../config/axios';
@Module({
    name: 'Navigation',
    namespaced: true,
    dynamic: true,
    store
})
export default class Navigation extends VuexModule {
    desktopNav: boolean = false;
    mobileNav: boolean = false;
    login: boolean = false;
    dataPrefetched : boolean = false;
    language: string | null = localStorage.getItem('lang') ? localStorage.getItem('lang') : 'rs'; 
    region: string | null = null;
    searchResults: any | null = null;
    currency: string = "";

    get currentLanguage(): string | null{
        return this.language;
    }
    get getSearchResults(): any | null{
        return this.searchResults;
    }
    @Mutation
    setLanguage(data: string){
        this.language = data;
    }
    @Mutation
    setSearchResults(data: any){
        this.searchResults = data;
    }
    @Mutation
    setRegion(data: string){
        this.region = data;
        localStorage.setItem('region', data);
        axios.defaults.headers.common["region"] = data;

        let reg = this.region;
        console.log(reg);
        let currency = "RSD";
        if (reg){
            if( reg == regions.eu){
                currency = "EUR"
            }
            if( reg!= regions.eu && reg != regions.rs){
                currency = "USD"
            }
        }
        this.currency = currency;
        console.log(this.currency, 'currency')
    }

    get isDataPrefetched(): boolean {
        return this.dataPrefetched
    }
    @Mutation
    setDataPrefetched(data: boolean){
        this.dataPrefetched = data;
    }

    @Mutation
    setMobileNav(data: boolean){
        this.mobileNav = data;
    }

    @Mutation
    toggleMobileNavigation(data?: boolean) {
        if (data === false) {
            this.mobileNav = data;
        } else {
            this.mobileNav = !this.mobileNav;
        }
    }

    @Mutation
    toggleDesktopNavigation(data?: boolean) {
        if (data === false) {
            this.desktopNav = data;
        } else {
            this.desktopNav = !this.desktopNav;
        }
    }

    @Mutation
    toggleLogin(data?: boolean) {
        if (data === false) {
            this.login = data;
        } else {
            this.login =! this.login;
        }
    }
    @Action
    async searchSite(searchString: string|null){
        const response = await websiteSearch({'search':searchString});
        if(response){
            this.setSearchResults(response.result);
        }
    }
}
export const NavigationStore = getModule(Navigation);