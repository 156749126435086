var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"w-full mt-10 relative z-10 bg-white"},[(
      _vm.BlogPostPaginatedList &&
        Array.isArray(_vm.BlogPostPaginatedList) &&
        _vm.BlogPostPaginatedList.length > 0
    )?_c('div',{staticClass:"w-full 2xl:container mx-auto"},[_c('div',{staticClass:"w-full md:w-10/12 lg:w-9/12 xl:w-7/12 mx-auto flex items-center gap-x-3 md:gap-x-4 lg:gap-x-8"},[_c('div',{staticClass:"flex items-center justify-start"},[_c('button',{staticClass:"mx-auto focus:outline-none",on:{"click":function($event){return _vm.slide('left', 'parent', 'child')}}},[_c('arrow-photo-overlay-icon',{staticClass:"h-10 w-10 transform rotate-180"})],1)]),(_vm.allHashtags)?_c('div',{staticClass:"mx-auto flex items-center overflow-hidden gap-x-3 lg:gap-x-6 smooth-scroll",attrs:{"id":"parent"}},_vm._l((_vm.allHashtags),function(hashtag,i){return _c('div',{key:i,staticClass:"rounded-full px-10 py-1 border uppercase text-sm font-medium cursor-pointer hover:bg-white-100",class:{
            'bg-gray-600': _vm.filterHashtags.includes(hashtag[0]),
            'bg-white': !_vm.filterHashtags.includes(hashtag[0]),
          },attrs:{"id":"child"},on:{"click":() => {
              _vm.BlogStore.addFilterHashtags(hashtag[0]);
            }}},[_vm._v(" #"+_vm._s(hashtag[0] ? hashtag[0] : "")+" ")])}),0):_vm._e(),_c('div',{staticClass:"flex items-center justify-end"},[_c('button',{staticClass:"mx-auto focus:outline-none",on:{"click":function($event){return _vm.slide('right', 'parent', 'child')}}},[_c('arrow-photo-overlay-icon',{staticClass:"h-10 w-10"})],1)])]),_c('div',{staticClass:"w-11/12 md:w-7/12 lg:w-6/12 xl:w-5/12 mx-auto"},[(
          _vm.BlogPostPaginatedList &&
            _vm.BlogPostPaginatedList.length &&
            _vm.BlogPostPaginatedList[0]
        )?_c('div',{staticClass:"mt-12 border-b"},[_c('div',{staticClass:"w-full pb-10 cursor-pointer group transform hover:scale-105 transition duration-200 ease-in-out",on:{"click":() => {
              if (this.BlogPostPaginatedList[0].id)
                _vm.$router.push(`/blog/${this.BlogPostPaginatedList[0].id}`);
            }}},[_c('div',{staticClass:"w-full items-center inline-flex justify-center"},[(
                _vm.BlogPostPaginatedList[0].heading_image &&
                  _vm.BlogPostPaginatedList[0].heading_image.url
              )?_c('img',{attrs:{"src":_vm.imageURLprefix + _vm.BlogPostPaginatedList[0].heading_image.url}}):_c('img',{attrs:{"src":require("../assets/backgrounds/blogItem.png"),"alt":""}})]),_c('div',{staticClass:"flex justify-center"},[(_vm.BlogPostPaginatedList[0].title)?_c('h3',{staticClass:"mt-5 uppercase font-bold tracking-tight"},[_vm._v(" "+_vm._s(_vm.BlogPostPaginatedList[0].title)+" "),_c('div',{staticClass:"group-hover:hidden block clear-fix"}),_c('div',{staticClass:"underline-animation group-hover:block hidden"})]):_vm._e()]),_c('h3',{staticClass:"mt-5 uppercase font-bold tracking-tight"}),(_vm.BlogPostPaginatedList[0].preview_text)?_c('p',{staticClass:"mt-3 truncate px-2 text-sm text-gray-650"},[_vm._v(" "+_vm._s(_vm.BlogPostPaginatedList[0].preview_text)+" ")]):_vm._e(),(_vm.BlogPostPaginatedList[0].date)?_c('p',{staticClass:"text-gray-650 text-sm mt-3"},[_vm._v(" "+_vm._s(_vm._f("moment")(_vm.BlogPostPaginatedList[0].date,"DD-MM-YYYY"))+" ")]):_vm._e()])]):_vm._e(),_c('div',{staticClass:"mt-8"},_vm._l((_vm.BlogPostPaginatedList.slice(
            1,
            _vm.BlogPostPaginatedList.length > 2
              ? _vm.BlogPostPaginatedList.length - 1
              : _vm.BlogPostPaginatedList.length
          )),function(bp){return _c('div',{key:bp.id,staticClass:"border-b",on:{"click":function($event){return _vm.$router.push(`/blog/${bp.id}`)}}},[_c('div',{staticClass:"w-full py-10 grid grid-cols-2 gap-x-5 group transform hover:scale-105 transition duration-200 ease-in-out"},[_c('div',{staticClass:"cursor-pointer"},[(bp.heading_image && bp.heading_image.url)?_c('img',{attrs:{"src":_vm.imageURLprefix + bp.heading_image.url,"alt":""}}):_c('img',{attrs:{"src":require("../assets/backgrounds/about/about-3.jpg"),"alt":""}})]),_c('div',{staticClass:"text-left relative cursor-pointer"},[_c('div',{staticClass:"flex items-center"},[(bp.title)?_c('h3',{staticClass:"text-left truncate uppercase font-bold tracking-tight"},[_vm._v(" "+_vm._s(bp.title)+" "),_c('div',{staticClass:"group-hover:hidden block clear-fix"}),_c('div',{staticClass:"underline-animation group-hover:block hidden"})]):_vm._e()]),(bp.preview_text)?_c('p',{staticClass:"mt-3 text-sm text-gray-650"},[_vm._v(" "+_vm._s(bp.preview_text)+" ")]):_vm._e(),(bp.date)?_c('p',{staticClass:"text-gray-650 text-sm mt-3 absolute bottom-0"},[_vm._v(" "+_vm._s(_vm._f("moment")(bp.date,"DD-MM-YYYY"))+" ")]):_vm._e()])])])}),0),(
          _vm.BlogPostPaginatedList &&
            _vm.BlogPostPaginatedList[_vm.BlogPostPaginatedList.length - 1] &&
            _vm.BlogPostPaginatedList.length == 7
        )?_c('div',{staticClass:"mt-12 border-b"},[_c('div',{staticClass:"w-full pb-10 cursor-pointer group transform hover:scale-105 transition duration-200 ease-in-out",on:{"click":() => {
              if (
                this.BlogPostPaginatedList[_vm.BlogPostPaginatedList.length - 1]
                  .id
              )
                _vm.$router.push(
                  `/blog/${
                    this.BlogPostPaginatedList[
                      _vm.BlogPostPaginatedList.length - 1
                    ].id
                  }`
                );
            }}},[_c('div',{staticClass:"w-full items-center inline-flex justify-center"},[(
                _vm.BlogPostPaginatedList[_vm.BlogPostPaginatedList.length - 1]
                  .heading_image &&
                  _vm.BlogPostPaginatedList[_vm.BlogPostPaginatedList.length - 1]
                    .heading_image.url
              )?_c('img',{attrs:{"src":_vm.imageURLprefix +
                  _vm.BlogPostPaginatedList[_vm.BlogPostPaginatedList.length - 1]
                    .heading_image.url}}):_c('img',{attrs:{"src":require("../assets/backgrounds/blogItem.png"),"alt":""}})]),_c('div',{staticClass:"flex justify-center"},[(
                _vm.BlogPostPaginatedList[_vm.BlogPostPaginatedList.length - 1].title
              )?_c('h3',{staticClass:"mt-5 uppercase font-bold tracking-tight"},[_vm._v(" "+_vm._s(_vm.BlogPostPaginatedList[_vm.BlogPostPaginatedList.length - 1].title)+" "),_c('div',{staticClass:"group-hover:hidden block clear-fix"}),_c('div',{staticClass:"underline-animation group-hover:block hidden"})]):_vm._e()]),_c('h3',{staticClass:"mt-5 uppercase font-bold tracking-tight"}),(
              _vm.BlogPostPaginatedList[_vm.BlogPostPaginatedList.length - 1]
                .preview_text
            )?_c('p',{staticClass:"mt-3 truncate px-2 text-sm text-gray-650"},[_vm._v(" "+_vm._s(_vm.BlogPostPaginatedList[_vm.BlogPostPaginatedList.length - 1] .preview_text)+" ")]):_vm._e(),(
              _vm.BlogPostPaginatedList[_vm.BlogPostPaginatedList.length - 1].date
            )?_c('p',{staticClass:"text-gray-650 text-sm mt-3"},[_vm._v(" "+_vm._s(_vm._f("moment")(_vm.BlogPostPaginatedList[_vm.BlogPostPaginatedList.length - 1].date,"DD-MM-YYYY"))+" ")]):_vm._e()])]):_vm._e(),(_vm.paginationTotal)?_c('div',[_c('div',{staticClass:"w-full items-center text-center space-x-12 text-3xl border-b py-4"},_vm._l((Math.floor(_vm.paginationTotal / 7) +
              (_vm.paginationTotal % 7 == 0 ? 0 : 1)),function(n,i){return _c('span',{key:i,staticClass:"cursor-pointer select-none",class:{
              'text-gray-650': n === _vm.filters.paginationPage,
              'text-gray-300': n !== _vm.filters.paginationPage,
            },on:{"click":() => {
                if (n !== _vm.filters.paginationPage) _vm.$router.push(`/blogs/${n}`);
              }}},[_vm._v(" "+_vm._s(n)+" ")])}),0),_c('p',{staticClass:"text-xs mt-1"},[_vm._v(" "+_vm._s((_vm.filters.paginationPage - 1) * 7 + 1)+" - "+_vm._s(_vm.paginationTotal - _vm.filters.paginationPage * 7 > 0 ? _vm.filters.paginationPage * 7 : (_vm.filters.paginationPage - 1) * 7 + (_vm.paginationTotal - (_vm.filters.paginationPage - 1) * 7))+" of "+_vm._s(_vm.paginationTotal)+" items ")])]):_vm._e()])]):_c('div',{staticClass:"w-full 2xl:container mx-auto h-100"},[_c('div',{staticClass:"flex flex-column items-center justify-center w-full h-full"},[_c('h1',{staticClass:"text-xl"},[_vm._v(_vm._s(_vm.$t("blog.no-posts")))])])]),_c('div',{staticClass:"py-10 items-center border-b border-t mt-16"},[_c('button',{staticClass:"bg-black rounded-full text-white py-2 px-5 uppercase text-sm font-medium hover:text-gray-300 transform hover:scale-105 transition duration-200 ease-linear hover:shadow-3xl focus:outline-none",on:{"click":function($event){return _vm.$router.push('/shop')}}},[_vm._v(" "+_vm._s(_vm.$t("shop-navigation.to-shop"))+" ")])]),_c('app-recommended-products-slider',{attrs:{"element-id":'blog_item',"title":_vm.$t('popular-products.title'),"products":_vm.popularProducts}}),_c('app-register-banner')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }