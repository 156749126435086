
import { Component, Vue, Watch } from "vue-property-decorator";
import Logo from "../../icons/Logo.vue";
import LogoFull from "../../icons/LogoFull.vue";
import FacebookIcon from "../../icons/FacebookIcon.vue";
import FacebookIconWhite from "@/icons/FacebookIconWhite.vue";
import InstagramIcon from "../../icons/InstagramIcon.vue";
import InstagramIconWhite from "@/icons/InstagramIconWhite.vue";
import SearchIcon from "../../icons/SearchIcon.vue";
import MenuIcon from "../../icons/MenuIcon.vue";
import CartIcon from "../../icons/CartIcon.vue";
import UserIcon from "../../icons/UserIcon.vue";
import MaskIcon from "../../icons/MaskIcon.vue";
import TriangleMenuIcon from "../../icons/TriangleMenuIcon.vue";
import AppMobileNavigation from "@/components/shared/AppMobileNavigation.vue";
import AppLogin from "@/components/AppLogin.vue";
import AppSearchModal from "@/components/modals/AppSearchModal.vue";
import ContactInfoModal from "../modals/Footer/ContactInfoModal.vue";
import { modal } from "@/store/modal";
import { CartStore } from "@/store/cart";
import { NavigationStore } from "@/store/navigation";
import { CategoriesStore } from "@/store/categories";
import { ProductStore } from "@/store/product";
import { CustomerStore } from "@/store/customer";
import { Locales } from "@/types/i18n";
import AboutUsModal from "@/components/modals/Footer/AboutUsModal.vue";
import TermsOfUseModal from "@/components/modals/Footer/TermsOfUseModal.vue";
import ShippingInfoModal from "@/components/modals/Footer/ShippingInfoModal.vue";
import ComplaintsModal from "@/components/modals/Footer/ComplaintsModal.vue";
import GoodsReplacementModal from "@/components/modals/Footer/GoodsReplacementModal.vue";
import AppShopFiltersModal from "@/components/AppShopFilters.vue";
import PrivacyPolicyModal from "@/components/modals/Footer/PrivacyPolicyModal.vue";
import ProtectionOfConfidentialInfoModal from "@/components/modals/Footer/ProtectionOfConfidentialInfoModal.vue";
import RefundInfoModal from "@/components/modals/Footer/RefundInfoModal.vue";
import { instagramAddress, facebookAddress } from "../../utilities/constants";
import AppHeaderCart from "@/components/shared/AppHeaderCart.vue";
import { regions } from "../../utilities/constants";
import axios from "../../../config/axios";
import AppImageViewer from "@/components/AppImageViewer.vue";

@Component({
  components: {
    Logo,
    LogoFull,
    FacebookIcon,
    FacebookIconWhite,
    InstagramIcon,
    InstagramIconWhite,
    SearchIcon,
    MenuIcon,
    CartIcon,
    UserIcon,
    MaskIcon,
    TriangleMenuIcon,
    AppMobileNavigation,
    AppLogin,
    AppSearchModal,
    ContactInfoModal,
    AboutUsModal,
    AppShopFiltersModal,
    TermsOfUseModal,
    ShippingInfoModal,
    ComplaintsModal,
    GoodsReplacementModal,
    PrivacyPolicyModal,
    ProtectionOfConfidentialInfoModal,
    RefundInfoModal,
    AppHeaderCart,
    AppImageViewer,
  },
})
export default class AppHeader extends Vue {
  CartStore: any = CartStore;
  NavigationStore: any = NavigationStore;
  ProductStore: any = ProductStore;
  CategoriesStore: any = CategoriesStore;
  CustomerStore: any = CustomerStore;
  modal: any = modal;
  lang: string = "ENGLISH";
  Locales: any = Locales;
  langs: string[] = ["ENGLISH", "SERBIAN"];
  activeLink: number | null = null;
  miniCart: boolean = false;
  regions: any = regions;

  private imageURLprefix: string | undefined =
    process.env.VUE_APP_BACKEND_IMAGE_PREFIX;

  get isCustomerLoggedIn() {
    return CustomerStore.token;
  }
  get region() {
    return NavigationStore.region;
  }

  get totalPrice() {
    let total: number = 0;
    CartStore.cart.forEach((item: any, i: number) => {
      let totalItemPrice = item.price * item.quantity;
      total += totalItemPrice;
    });
    return total;
  }

  get selectedMenuKey() {
    return CategoriesStore.selectedMenuKey;
  }
  get mainMenuItems() {
    return CategoriesStore.navigationMenu;
  }
  get cartLength() {
    return CartStore.cart.length;
  }

  get cart() {
    return CartStore.cart;
  }

  get mobileNav() {
    return NavigationStore.mobileNav;
  }

  get desktopNav() {
    return NavigationStore.desktopNav;
  }
  set desktopNav(value: boolean) {
    NavigationStore.toggleDesktopNavigation(value);
  }

  get login() {
    return NavigationStore.login;
  }
  set selectedMenuKey(value: number | null) {
    CategoriesStore.setSelectedMenuKey(value);
  }
  public redirectToInstagram() {
    window.open(instagramAddress);
  }

  async mounted() {
    await CategoriesStore.fetchCategoriesSubcategories();
    this.fetchLanguage();
    this.fetchRegion();
    await ProductStore.fetchProducts();
  }
  public goToSubcategoryDetails(subcategory: any): void {
    this.$router
      .push({
        path: "/shop",
        query: {
          sort_by: "popularity",
          page: "1",
          category: subcategory.id,
        },
      })
      .catch(() => {});
    NavigationStore.toggleDesktopNavigation();
    this.selectedMenuKey = null;
    //Todo set product
  }

  public goToProductDetails(product: any): void {
    if (product && product.id) {
      if (this.$route.path !== `/product-details/${product.id}`) {
        this.$router.push(`/product-details/${product.id}`);
        this.selectedMenuKey = null;
      }
    }
  }

  public fetchLanguage() {
    const lang = localStorage.getItem("lang");
    if (lang && lang !== "rs") {
      this.$i18n.locale = Locales.EN;
    } else {
      this.$i18n.locale = Locales.SR;
    }
    return this.$i18n.locale;
  }

  public toggleLanguage() {
    if (this.$i18n.locale === "en") {
      localStorage.setItem("lang", "rs");
      axios.defaults.headers.common["lang"] = "rs";
    } else {
      localStorage.setItem("lang", "en");
      axios.defaults.headers.common["lang"] = "en";
    }
    this.$router.go(0);
  }

  public fetchRegion() {
    const region = localStorage.getItem("region");
    if (region) this.NavigationStore.setRegion(region);
    return region;
  }

  public setRegion(event: any) {
    this.NavigationStore.setRegion(event.target.value);
    this.$router.go(0);
  }
  @Watch("$route.path")
  onRouteChange(val: boolean): void {
    window.scrollTo(0, 0);
    this.NavigationStore.toggleMobileNavigation(false);
    this.NavigationStore.toggleDesktopNavigation(false);
    this.NavigationStore.toggleLogin(false);
  }
}
