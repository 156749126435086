
import { Vue, Prop, Component } from "vue-property-decorator";
import { CustomerStore } from "../store/customer";
@Component
export default class ShopSingleProduct extends Vue {
  CustomerStore: any = CustomerStore;
  private selectedColor: any = null;
  imageURLprefix: string | undefined = process.env.VUE_APP_BACKEND_IMAGE_PREFIX;

  @Prop({ required: false })
  product!: any;

  get productColors() {
    if (
      this.product &&
      this.product.images &&
      Array.isArray(this.product.images) &&
      this.product.images.length > 0
    ) {
      let colorArray: any[] = [];
      this.product.images.forEach((image: any) => {
        if (image.color) {
          if (!this.selectedColor) this.selectedColor = image.color;
          if (!colorArray.find((color) => color.id == image.color.id)) {
            colorArray.push(image.color);
          }
        }
      });
      return colorArray;
    }
    return [];
  }
  public setColor(val: any): void {
    this.selectedColor = val;
  }
  get imagesPerColor() {
    if (this.selectedColor) {
      return this.product.images.filter(
        (image: any) => image.color.id === this.selectedColor.id
      );
    }
    return [];
  }
}
