import { Module, VuexModule, Mutation, Action, getModule, } from 'vuex-module-decorators';
import {initOrder, setOrderContactDetails,fetchOrder,
    createDiscountWithCode, updateOrderShippingDetails,
    finalizeCardOrder, finalizeCashOrder} from '../api/order';
import {finalizeWalletPayment} from '../api/wallet';
import store from "./index";

@Module({
    name: 'order',
    namespaced: true,
    dynamic: true,
    store
})
export default class Order extends VuexModule {
    order: any = null;
    contactInfo: any = null;
    shippingInfo: any = null;
    warehouseErrorProducts: any = null;


    @Mutation
    setWarehouseErrorProducts(data: any){
        this.warehouseErrorProducts = data;
    }
    @Mutation
    setOrder(data: any){
        this.order = data;
    }
    @Mutation
    clearAllInfo(){
        this.order = null;
        this.contactInfo = null;
        this.shippingInfo = null;
    }
    @Action
    async createDiscountWithCode(discountCode:string){
        const response = await createDiscountWithCode({discount_code:discountCode})
        if(response){
            if(response.status && response.status == 'success'){
                this.getOrderDetails();
            }
            return response.message ? response.message : 'Žao nam je, došlo je do greške';
        }
    }
    @Action
    async initOrder(){
        const response = await initOrder();
        if(response && response.status && String(response['status']) === 'success'){
            return { status :true};
        }
        if( response && response.error && response.error === 'WAREHOUSE'){
            return {
                status: false, warehouse_error: true, products: response.products
            };
        }
        return  { status :true};
    }
    @Action
    async getOrderDetails(){
        const response = await fetchOrder();
        if(response){
            this.setOrder(response);
        }
    }
    @Action
    async uploadOrderContactDetails(params:any){
        const response = await setOrderContactDetails(params);
        return response && response.message  ? response.message : 'Žao nam je, došlo je do greške';
    }
    @Action
    async updateShippingDetails(){
        await updateOrderShippingDetails();
    }
    @Action
    async finalizeCardOrder(){
        const response = await finalizeCardOrder();
        if( response && response.status && response.status === 'ok'){
            return {
                status: true, 
                paymentType: response.payment_type, 
                form_details: response.form_details
            };
        }
        if( response && response.error && response.error === 'WAREHOUSE'){
            return {
                status: false, warehouse_error: true, products: response.products
            };
        }
        return {status: false};
    }
    @Action
    async finalizeCashOrder(){
        const response = await finalizeCashOrder();
        if( response && response.status && response.status === 'ok'){
            return {
                status: true, 
                orderId: response.order_id,  
                email : response.email
            };
        }
        if( response && response.error && response.error === 'WAREHOUSE'){
            return {
                status: false, warehouse_error: true, products: response.products
            };
        }
        return {status: false};
    }
    @Action
    async finalizeWalletPayment(){
        const response = await finalizeWalletPayment();
        if( response && response.status && response.status === 'ok'){
            return {
                status: true, 
                email : response.details.email,
                orderId: response.details.confirmation_code
            };
        }
        if( response && response.error && response.error === 'WAREHOUSE'){
            return {
                status: false, warehouse_error: true, products: response.products
            };
        }
        return {status: false};
    }
}
export const OrderStore = getModule(Order);