
import { Vue, Component } from "vue-property-decorator";
import { NavigationStore } from "@/store/navigation";
import axiosInstance from "../../config/axios";

@Component({})
export default class AppVideoBanner extends Vue {
  NavigationStore: any = NavigationStore;
  scroll: boolean = false;
  newsletterCount: number = 0;
  email: string = "";

  public created() {
    window.addEventListener("scroll", this.handleScroll);
  }

  public destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleScroll(event: any): void {
    if (
      document.documentElement.scrollTop > 25 &&
      document.documentElement.scrollTop < 50 &&
      this.newsletterCount < 2
    ) {
      this.scroll = true;
      this.newsletterCount++;
    }
  }

  public async subscribeNewsletter(email: string) {
    const response = await axiosInstance.post("/mailing_list", {
      email: email,
    });
  }
}
