
import { Component, Vue, Prop } from "vue-property-decorator";
import { modal } from "@/store/modal";
@Component({})
export default class AppThankYouModal extends Vue {
  Modal: any = modal;
  @Prop({ required: true })
  orderId!: string | null;  
  @Prop({ required: true })
  email!: string | null;
}
