
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { modal } from "@/store/modal";
@Component({})
export default class AppSearchModal extends Vue {
  Modal: any = modal;

  @Prop({ required: true, default: null })
  videoId!: string | null;

  get width() {
    if (window.innerWidth < 600) return "97%";
    if (window.innerWidth < 900) return "80%";
    if (window.innerWidth < 1025) return "60%";
    if (window.innerWidth >= 1025) return "55%";
  }
}
