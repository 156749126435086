
import { Component, Vue } from "vue-property-decorator";
import WalletIcon from "@/icons/WalletIcon.vue";
import ArrowRightIcon from "@/icons/ArrowRightIcon.vue";
import AppAsideCart from "@/components/AppAsideCart.vue";
import AppCartSteps from "@/components/AppCartSteps.vue";
import { NavigationStore } from "../store/navigation";
import { CartStore } from "../store/cart";
import { OrderStore } from "../store/order";
import { shippingCompanies } from "../utilities/constants";
@Component({
  components: {
    WalletIcon,
    ArrowRightIcon,
    AppAsideCart,
    AppCartSteps,
  },
})
export default class Shipping extends Vue {
  NavigationStore: any = NavigationStore;
  OrderStore: any = OrderStore;
  shippingCompany: string | null = null;
  deliveryTime: string | null = null;
  isShippingDataUpdated: boolean = false;
  shippingCompanies: any = shippingCompanies;

  get shippingAddress() {
    return OrderStore.order &&
      OrderStore.order.details &&
      OrderStore.order.details.address
      ? OrderStore.order.details.address
      : null;
  }
  get shippingPostalCode() {
    return OrderStore.order &&
      OrderStore.order.details &&
      OrderStore.order.details.postal_code
      ? OrderStore.order.details.postal_code
      : null;
  }
  get shippingCountry() {
    if (
      OrderStore.order &&
      OrderStore.order.details &&
      OrderStore.order.details.country
    ) {
      //@ts-ignore
      const country = this.$t("countries").find(
        (c: any) => c.id === OrderStore.order.details.country
      );
      return country && country.name ? country.name : null;
    }

    return null;
  }
  get completeShippingAddress() {
    if (this.shippingCountry && this.shippingPostalCode && this.shippingAddress)
      return (
        this.shippingAddress +
        ", " +
        this.shippingPostalCode +
        ", " +
        this.shippingCountry
      );
    return;
  }
  get shippingParams() {
    return {
      shipping_company: this.shippingCompany,
      shipping_type: this.deliveryTime,
    };
  }
  public async transitionToReview() {
    this.$router.push("/review-and-finish");
  }

  async mounted() {
    // let localCart: any = localStorage.getItem("cart");
    this.isShippingDataUpdated = false;
    await OrderStore.updateShippingDetails();
    this.isShippingDataUpdated = true;
    CartStore.fetchCart();
    await OrderStore.getOrderDetails();
    if (
      !(
        OrderStore.order &&
        OrderStore.order.details &&
        OrderStore.order.details.address
      )
    ) {
      this.$router.push("/contact-information");
    }
    this.shippingCompany = "DHL";
    this.deliveryTime = "STANDARD";
    if (
      OrderStore.order &&
      OrderStore.order.details &&
      OrderStore.order.details.shipping_company
    ) {
      this.shippingCompany = OrderStore.order.details.shipping_company;
    }
    if (
      OrderStore.order &&
      OrderStore.order.details &&
      OrderStore.order.details.shipping_type
    ) {
      this.deliveryTime = OrderStore.order.details.shipping_type;
    }
  }
}
