import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "@/assets/css/tailwind.scss";
import "./css/multiselect.min.css";
import "./filters/filters.ts";
import VModal from "vue-js-modal";
import axiosInstance from "../config/axios";
// @ts-ignore
import VueCarousel from "vue-carousel";
// @ts-ignore
import Toasted from "vue-toasted";
// @ts-ignore
import VueI18n from "vue-i18n";
// @ts-ignore
import * as VueGoogleMaps from 'vue2-google-maps';
import { messages, defaultLocale } from "@/i18n";
import Multiselect from 'vue-multiselect';
import Loader from '@/components/shared/Loader.vue';
import { VueRecaptcha } from 'vue-recaptcha';
// @ts-ignore
import { CustomerStore } from "/src/store/customer";
// @ts-ignore
import VueYoutube from 'vue-youtube';
import EasySlider from 'vue-easy-slider'



Vue.prototype.$axios = axiosInstance;
Vue.config.productionTip = false;
Vue.use(require('vue-moment'));
Vue.use(VModal);
Vue.use(Toasted);
Vue.use(VueCarousel);
Vue.use(VueI18n);
Vue.use(VueYoutube);
Vue.use(EasySlider)
Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyBE1Z5cgjqNEfdX0HKOSjYLU5m8VqLzJDQ',
  },
});

Vue.component('dropdown', Multiselect);
Vue.component('loader', Loader);
Vue.component('vue-recaptcha', VueRecaptcha);
const i18n = new VueI18n({
  messages,
  locale: defaultLocale,
  fallbackLocale: defaultLocale
});

const token: string | null = localStorage.getItem("token");
store.commit('Customer/setToken', token);
const vueInstance = new Vue({
  i18n,
  router,
  store,
  render: h => h(App)
}).$mount('#app');


Vue.prototype.$info = (message:string) => {
  vueInstance.$toasted.show(`${message}`,
  {
    position: 'bottom-left',
    theme: 'outline',
    duration: 5000,
    closeOnSwipe: true,
  }
)}

Vue.prototype.$axios = axiosInstance;

axiosInstance.interceptors.response.use(
  function(response: any) {
    if (
      response &&
      response.data &&
      response.data &&
      response.status == 200 &&
      response.data.message
    ) {

      (vueInstance as any).$info(response.data.message);
    }
    return response;
  },
  function(error: any) {
    if (error && error.response && error.response.status) {
      if (
        error.response.status == 400 &&
        error.response.data &&
        error.response.data.error
      ) {
        (vueInstance as any).$info(error.response.data.error);
      }

      if (error.response.status === 401) {
        (vueInstance as any).$info(i18n.t('session-expired-message'));
        CustomerStore.logout();
      }

      if (error.response.status == 500) {
        (vueInstance as any).$info(
          "Došlo je do greške. Kontaktirajte tehničku podršku."
        );
      }
    } else{
      return Promise.reject(error);
    }
  }
);