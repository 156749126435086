import { Module, VuexModule, Mutation, Action, getModule } from 'vuex-module-decorators'
import store from "./index";

@Module({
  name: 'Modal',
  namespaced: true,
  dynamic: true,
  store
})
export default class Modal extends VuexModule {
  showModal: boolean = false;
  modalTitle: string = '';

  @Mutation
  set(data: {name: string, show: boolean}):void {
    this.modalTitle = data.name;
    this.showModal = data.show;
  }

  // // action 'incr' commits mutation 'increment' when done with return value as payload
  // @Action({ commit: 'increment' })
  // incr() {
  //   return 5
  // }
  // // action 'decr' commits mutation 'decrement' when done with return value as payload
  // @Action({ commit: 'decrement' })
  // decr() {
  //   return 5
  // }
}
export const modal = getModule(Modal);