
import { Vue, Component } from "vue-property-decorator";
import { NavigationStore } from "../../store/navigation";
import { CustomerStore } from "../../store/customer";
import { modal } from "@/store/modal";
import DeactivateAccountModal from "../modals/DeactivateAccountModal.vue";
import ChangePasswordModal from "../modals/ChangePasswordModal.vue";
import { countryArray } from "@/utilities/constants";
@Component({ components: { DeactivateAccountModal, ChangePasswordModal } })
export default class ProfileInformation extends Vue {
  NavigationStore: any = NavigationStore;
  Modal: any = modal;
  countryOptions: any = countryArray;
  form: any = {
    emailInput: null,
    firstNameInput: null,
    lastNameInput: null,
    addressInput: null,
    aptSuiteInput: null,
    postalCodeInput: null,
    cityInput: null,
    countryInput: null,
    numberInput: null,
    stateProvidence: null,
    isMailingList: false,
  };

  async mounted() {
    await CustomerStore.fetchCustomerInfo();
    this.setCustomerInfoData();
  }
  public setCustomerInfoData() {
    if (CustomerStore.customerInfo) {
      this.form.emailInput = CustomerStore.customerInfo.email
        ? CustomerStore.customerInfo.email
        : null;
      this.form.firstNameInput = CustomerStore.customerInfo.first_name
        ? CustomerStore.customerInfo.first_name
        : null;
      this.form.lastNameInput = CustomerStore.customerInfo.last_name
        ? CustomerStore.customerInfo.last_name
        : null;
      this.form.addressInput = CustomerStore.customerInfo.address
        ? CustomerStore.customerInfo.address
        : null;
      this.form.aptSuiteInput = CustomerStore.customerInfo.apt_suite
        ? CustomerStore.customerInfo.apt_suite
        : null;
      this.form.postalCodeInput = CustomerStore.customerInfo.postal_code
        ? String(CustomerStore.customerInfo.postal_code)
        : null;
      this.form.cityInput = CustomerStore.customerInfo.city
        ? CustomerStore.customerInfo.city
        : null;
      this.form.countryInput = this.countryOptions.find(
        (country: any) => country.id == CustomerStore.customerInfo.country
      );
      this.form.stateProvidence = CustomerStore.customerInfo.state_providence
        ? CustomerStore.customerInfo.state_providence
        : null;
      this.form.numberInput = CustomerStore.customerInfo.phone_number
        ? CustomerStore.customerInfo.phone_number
        : null;
      this.form.isMailingList = CustomerStore.customerInfo.is_subscribed
        ? CustomerStore.customerInfo.is_subscribed
        : null;
    }
  }
  get getInfoData() {
    return {
      first_name: this.form.firstNameInput,
      last_name: this.form.lastNameInput,
      address: this.form.addressInput,
      apt_suite: this.form.aptSuiteInput,
      postal_code: this.form.postalCodeInput,
      state_providence: this.form.stateProvidence,
      city: this.form.cityInput,
      country:
        this.form.countryInput && this.form.countryInput.id
          ? this.form.countryInput.id
          : null,
      phone_number: this.form.numberInput,
      is_subscribed: this.form.isMailingList,
    };
  }

  get areMainInputsFilled() {
    return (
      this.form.emailInput &&
      this.form.emailInput.trim() &&
      this.form.emailInput.trim() !== "" &&
      this.form.firstNameInput &&
      this.form.firstNameInput.trim() &&
      this.form.firstNameInput.trim() !== "" &&
      this.form.lastNameInput &&
      this.form.lastNameInput.trim() &&
      this.form.lastNameInput.trim() !== ""
    );
  }

  get isFormValid() {
    return this.areMainInputsFilled ? true : false;
  }

  public validateForm() {
    Object.keys(this.form).forEach((key) => {
      if (this.form[key] === null) this.form[key] = "";
    });
  }

  public async updateInformation() {
    this.validateForm();
    if (this.isFormValid)
      await CustomerStore.updateInformation(this.getInfoData);
    //@ts-ignore
    else this.$info(this.$t("errors.you-must-fill-required-fields"));
  }
}
