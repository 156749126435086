
import { Vue, Component, Prop } from "vue-property-decorator";

@Component({})

export default class Loader extends Vue {
  @Prop({
    default: {width: "50px", height: "50px"},
  })
  size: any;
}
