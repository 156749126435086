export enum Regions {
  RS = 'Serbia',
  EU = 'Europe',
  USA = 'America',
};

export type REGION =
  | Regions.RS
  | Regions.EU
  | Regions.USA;
