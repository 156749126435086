
import { Component, Vue, Watch } from "vue-property-decorator";
import ArrowPhotoOverlayIcon from "@/icons/ArrowPhotoOverlayIcon.vue";
import AppRegisterBanner from "@/components/AppRegisterBanner.vue";
import ShopSingleProduct from "@/components/ShopSingleProduct.vue";
import { ProductStore } from "../store/product";
import { NavigationStore } from "../store/navigation";
import { ShopStore } from "../store/shop";
import { CategoriesStore } from "../store/categories";
import pagination from "../utilities/pagination";
import { mixins } from "vue-class-component";
import { modal } from "@/store/modal";

@Component({
  components: { ArrowPhotoOverlayIcon, AppRegisterBanner, ShopSingleProduct },
})
export default class Shop extends mixins(pagination) {
  imageURLprefix: string | undefined = process.env.VUE_APP_BACKEND_IMAGE_PREFIX;
  perPage: number = 24;
  openProducts: boolean = false;
  openSize: boolean = false;
  openColors: boolean = false;
  openPrice: boolean = false;
  ProductStore: any = ProductStore;
  CategoriesStore: any = CategoriesStore;
  NavigationStore: any = NavigationStore;
  ShopStore: any = ShopStore;
  modal: any = modal;

  get sortBy() {
    return ShopStore.sortBy;
  }
  set sortBy(val) {
    ShopStore.setSortBy(val);
  }
  get subcategoryFiltering() {
    return ShopStore.filterCategories;
  }
  get sizeFilters() {
    return ShopStore.sizeFilters;
  }
  get priceFilters() {
    return ShopStore.priceRange;
  }
  get allProducts() {
    this.paginationTotal = ShopStore.shopProducts.length;
    return ShopStore.shopProducts;
  }
  get getPaginatedProducts() {
    const retVal = this.allProducts.filter(
      (item, index) =>
        index < ShopStore.pageNum * this.perPage &&
        index >= (ShopStore.pageNum - 1) * this.perPage
    );
    return retVal;
  }
  get colorFiltering() {
    return ShopStore.filterColors;
  }
  public setFiltersFromQuery() {
    if (this.$route && this.$route.query) {
      if (this.$route.query.category) {
        let categories: string[] = String(this.$route.query.category).split(
          ","
        );
        categories.forEach((cat: any) => {
          ShopStore.setfilterCategory({
            id: cat,
            val: true,
          });
        });
      } else {
        ShopStore.clearFilterCategory();
      }

      if (this.$route.query.sort_by) {
        ShopStore.setSortBy(this.$route.query.sort_by);
      }
      if (this.$route.query.page) {
        let pageNum: number = Number(this.$route.query.page);
        const total: number =
          Math.floor(this.paginationTotal / this.perPage) +
          (this.paginationTotal % this.perPage == 0 ? 0 : 1);
        if (pageNum == 0 && total != 0) {
          ShopStore.setPageNum(1);
          this.updateRoute();
        }
        if (pageNum > total) {
          ShopStore.setPageNum(Number(total));
          this.updateRoute();
        }
        ShopStore.setPageNum(this.$route.query.page);
      }
      if (this.$route.query.size) {
        let sizes: string[] = String(this.$route.query.size).split(",");
        sizes.forEach((size: any) => {
          if (size != "")
            ShopStore.setfilterSize({
              id: size,
              val: true,
            });
        });
      } else {
        ShopStore.clearFilterSize();
      }
      if (this.$route.query.price) {
        let prices: string[] = String(this.$route.query.price).split(",");
        prices.forEach((price: any) => {
          if (price != "")
            ShopStore.setFilterPrice({
              id: price,
              val: true,
            });
        });
      } else {
        ShopStore.clearFilterPrice();
      }
      if (this.$route.query.color) {
        let colors: string[] = String(this.$route.query.color).split(",");
        colors.forEach((color: any) => {
          if (color != "")
            ShopStore.setfilterColor({
              id: color,
              val: true,
            });
        });
      } else {
        ShopStore.clearFilterColor();
      }
    }
  }
  public setSizeValue(val: any, key: any) {
    if (val && val.target && val.target.checked !== null) {
      ShopStore.setSizeFilters({ key: key, value: val.target.checked });
      this.updateRoute();
    }
  }
  public setPriceRange(val: any, key: any) {
    if (val && val.target && val.target.checked !== null) {
      ShopStore.setPriceRangeFilter({ key: key, value: val.target.checked });
      this.updateRoute();
    }
  }
  public changeSortBy(event: any) {
    const value = event.target.value;
    ShopStore.setSortBy(value);
    this.updateRoute();
  }
  public setPageNum(pageNum: number) {
    const total: number =
      Math.floor(this.paginationTotal / this.perPage) +
      (this.paginationTotal % this.perPage == 0 ? 0 : 1);
    if (pageNum > total) pageNum = Number(total);
    ShopStore.setPageNum(pageNum);
    this.updateRoute();
  }
  public updateRoute() {
    this.$router
      .push({
        path: "/shop",
        query: ShopStore.queryParams,
      })
      .catch(() => {});
  }
  get filterCount() {
    return ShopStore.FilterCount;
  }
  @Watch("$route", { deep: true, immediate: true })
  async onRouteChanged(newVal: any) {
    await ShopStore.fetchShopProducts(newVal.query);
    this.setFiltersFromQuery();
  }

  async mounted() {
    await ShopStore.fetchShopProducts(this.$route.query);
    await CategoriesStore.fetchAllSubcategories();
    await ProductStore.fetchColors();
    ShopStore.initFilterColors();
    ShopStore.initFilterCategories();
    this.setFiltersFromQuery();
  }
}
