import axiosInstance from "../../config/axios";

export const getMenuItems = async() => {
    const response = await axiosInstance.get('/categories');
    return response && response.data ? response.data : null ;
}
export const getHomepageSubcategories = async() => {
    const response = await axiosInstance.get('/subcategory/homepage');
    return response && response.data ? response.data : null ;
}
export const getAllSubcategories = async() => {
    const response = await axiosInstance.get('/subcategories');
    return response && response.data ? response.data : null ;
}