import { Module, VuexModule, Mutation, Action, getModule, } from 'vuex-module-decorators';
import store from "./index";
import {getWalletBalance, addFunds} from '../api/wallet';


@Module({
    name: 'Wallet',
    namespaced: true,
    dynamic: true,
    store
})

export default class Wallet extends VuexModule {
    walletBalance: Number = 0;

    @Mutation
    setWalletBalance(balance: Number){
        this.walletBalance = balance;
    }

    @Action
    async fetchWalletBalance() {
        const response = await getWalletBalance();
        if(response && response.hasOwnProperty('balance')){
            this.setWalletBalance(response.balance);
        }
    }
    @Action
    async addWalletFunds(amount:any) {
        return await addFunds({amount : amount});
    }
}
export const WalletStore = getModule(Wallet);