export const instagramAddress = 'https://www.instagram.com/fwibyivanakorab';
export const facebookAddress = 'https://www.facebook.com/korabivana';

export const regions = {
    rs : 'Serbia',
    eu : 'Europe',
    us: 'America'
}

export const paymentType = {
    cash : 'cash',
    card : 'card',
    wallet: 'wallet'
}

export const sizes = {
            'XS' : false,
            'S' :  false,
            'M' :  false,
            'L' :  false,
            'XL':   false,
    };

export const shippingCompanies = {
    DHL : 'DHL',
    PostExpress : 'PostExpress',
    CityExpress : 'CityExpress',
    AKS : 'AKS',
    BEX : 'BEX',
    DExpress : 'DExpress',
}

export const priceRange = {
        1: {
            text : '0 - 5.000',
            value: false,
        },
        2:{
            text : '5.000 - 10.000',
            value: false,
        },
        3:{
            text : '10.000 - 15.000',
            value: false,
        },
        4:{
            text :  '> 15.000',
            value: false,
        }
    };

export const countries = {
'Albanija':{ rs : " Albanija" , en : "Albania"},
'Alžir':{ rs : " Alžir" , en : "Algeria"},
'Američka Samoa':{ rs : " Američka Samoa" , en : "American Samoa"},
'Andora':{ rs : " Andora" , en : "Andorra"},
'Angola':{ rs : " Angola" , en : "Angola"},
'Angvila':{ rs : " Angvila" , en : "Anguilla"},
'Antigva':{ rs : " Antigva" , en : "Antigua"},
'Argentina':{ rs : " Argentina" , en : "Argentina"},
'Aruba':{ rs : " Aruba" , en : "Aruba"},
'Australija':{ rs : " Australija" , en : "Australia"},
'Austrija':{ rs : " Austrija" , en : "Austria"},
'Avganistan':{ rs : " Avganistan" , en : "Afghanistan"},
'Azerbejdžan':{ rs : " Azerbejdžan" , en : "Azerbaijan"},
'Bahami':{ rs : " Bahami" , en : "Bahamas"},
'Bahrein':{ rs : " Bahrein" , en : "Bahrain"},
'Bangladeš':{ rs : " Bangladeš" , en : "Bangladesh"},
'Barbados':{ rs : " Barbados" , en : "Barbados"},
'Belgija':{ rs : " Belgija" , en : "Belgium"},
'Belize':{ rs : " Belize" , en : "Belize"},
'Belorusija':{ rs : " Belorusija" , en : "Belarus"},
'Benin':{ rs : " Benin" , en : "Benin"},
'Bermudska ostrva':{ rs : " Bermudska ostrva" , en : "Bermuda"},
'Bocvana':{ rs : " Bocvana" , en : "Botswana"},
'Bolivija':{ rs : " Bolivija" , en : "Bolivia"},
'Boner':{ rs : " Boner" , en : "Bonaire"},
'Bosna i Hercegovina':{ rs : " Bosna i Hercegovina" , en : "Bosnia and Herzegovina"},
'Brazil':{ rs : " Brazil" , en : "Brazil"},
'Brunej':{ rs : " Brunej" , en : "Brunei"},
'Bugarska':{ rs : " Bugarska" , en : "Bulgaria"},
'Burkina Faso':{ rs : " Burkina Faso" , en : "Burkina Faso"},
'Burundi':{ rs : " Burundi" , en : "Burundi"},
'Butan':{ rs : " Butan" , en : "Bhutan"},
'Centralnoafrička Republika':{ rs : " Centralnoafrička Republika" , en : "Central African Republic"},
'Crna Gora':{ rs : " Crna Gora" , en : "Montenegro"},
'Čad':{ rs : " Čad" , en : "Chad"},
'Češka Republika':{ rs : " Češka Republika" , en : "Czech Republic, The"},
'Čile':{ rs : " Čile" , en : "Chile"},
'Danska':{ rs : " Danska" , en : "Denmark"},
'Devičanska ostrva (Američka)':{ rs : " Devičanska ostrva (Američka)" , en : "Virgin Islands (US)"},
'Devičanska ostrva (Britanska)':{ rs : " Devičanska ostrva (Britanska)" , en : "Virgin Islands (British)"},
'Dominika':{ rs : " Dominika" , en : "Dominica"},
'Dominikanska Republika':{ rs : " Dominikanska Republika" , en : "Dominican Republic"},
'Džerzi':{ rs : " Džerzi" , en : "Jersey"},
'Džibuti':{ rs : " Džibuti" , en : "Djibouti"},
'Egipat':{ rs : " Egipat" , en : "Egypt"},
'Ekvador':{ rs : " Ekvador" , en : "Ecuador"},
'El Salvador':{ rs : " El Salvador" , en : "El Salvador"},
'Eritreja':{ rs : " Eritreja" , en : "Eritrea"},
'Estonija':{ rs : " Estonija" , en : "Estonia"},
'Etiopija':{ rs : " Etiopija" , en : "Ethiopia"},
'Farska ostrva':{ rs : " Farska ostrva" , en : "Faroe Islands"},
'Fidži':{ rs : " Fidži" , en : "Fiji"},
'Filipini':{ rs : " Filipini" , en : "The Philippines"},
'Finska':{ rs : " Finska" , en : "Finland"},
'Folklandska ostrva':{ rs : " Folklandska ostrva" , en : "Falkland Islands"},
'Francuska':{ rs : " Francuska" , en : "France"},
'Francuska Gvajana':{ rs : " Francuska Gvajana" , en : "French Guyana"},
'Gabon':{ rs : " Gabon" , en : "Gabon"},
'Gambija':{ rs : " Gambija" , en : "Gambia"},
'Gana':{ rs : " Gana" , en : "Ghana"},
'Gernzi':{ rs : " Gernzi" , en : "Guernsey"},
'Gibraltar':{ rs : " Gibraltar" , en : "Gibraltar"},
'Grčka':{ rs : " Grčka" , en : "Greece"},
'Grenada':{ rs : " Grenada" , en : "Grenada"},
'Grenland':{ rs : " Grenland" , en : "Greenland"},
'Gruzija':{ rs : " Gruzija" , en : "Georgia"},
'Guam':{ rs : " Guam" , en : "Guam"},
'Gvadalupe':{ rs : " Gvadalupe" , en : "Guadeloupe"},
'Gvajana (Britanska)':{ rs : " Gvajana (Britanska)" , en : "Guyana (British)"},
'Gvatemala':{ rs : " Gvatemala" , en : "Guatemala"},
'Gvineja - Bisao':{ rs : " Gvineja - Bisao" , en : "Guinea-Bissau"},
'Gvineja - Ekvatorijalna':{ rs : " Gvineja - Ekvatorijalna" , en : "Guinea-Equatorial"},
'Gvineja Republika':{ rs : " Gvineja Republika" , en : "Guinea Republic"},
'Haiti':{ rs : " Haiti" , en : "Haiti"},
'Holandija':{ rs : " Holandija" , en : "Netherlands, The"},
'Holandski Antili':{ rs : " Holandski Antili" , en : "Netherlands Antilles"},
'Honduras':{ rs : " Honduras" , en : "Honduras"},
'Hong Kong':{ rs : " Hong Kong" , en : "Hong Kong SAR China"},
'Hrvatska':{ rs : " Hrvatska" , en : "Croatia"},
'Indija':{ rs : " Indija" , en : "India"},
'Indonezija':{ rs : " Indonezija" , en : "Indonesia"},
'Irak':{ rs : " Irak" , en : "Iraq"},
'Iran':{ rs : " Iran" , en : "Iran, Islamic Rep. of"},
'Irska':{ rs : " Irska" , en : "Ireland, Rep. of"},
'Island':{ rs : " Island" , en : "Iceland"},
'Istočni Timor':{ rs : " Istočni Timor" , en : "Timor-Leste"},
'Italija':{ rs : " Italija" , en : "Italy"},
'Izrael':{ rs : " Izrael" , en : "Israel"},
'Jamajka':{ rs : " Jamajka" , en : "Jamaica"},
'Japan':{ rs : " Japan" , en : "Japan"},
'Jemen':{ rs : " Jemen" , en : "Yemen"},
'Jermenija':{ rs : " Jermenija" , en : "Armenia"},
'Jordan':{ rs : " Jordan" , en : "Jordan"},
'Južna Afrika':{ rs : " Južna Afrika" , en : "South Africa"},
'Južna Koreja':{ rs : " Južna Koreja" , en : "Korea, Rep. of (South)"},
'Južni Sudan':{ rs : " Južni Sudan" , en : "South Sudan"},
'Kajmanska ostrva':{ rs : " Kajmanska ostrva" , en : "Cayman Islands"},
'Kambodža':{ rs : " Kambodža" , en : "Cambodia"},
'Kamerun':{ rs : " Kamerun" , en : "Cameroon"},
'Kanada':{ rs : " Kanada" , en : "Canada"},
'Kanarska ostrva':{ rs : " Kanarska ostrva" , en : "Canary Islands, The"},
'Katar':{ rs : " Katar" , en : "Qatar"},
'Kazahstan':{ rs : " Kazahstan" , en : "Kazakhstan"},
'Kenija':{ rs : " Kenija" , en : "Kenya"},
'Kina':{ rs : " Kina" , en : "China, People’s Republic"},
'Kipar':{ rs : " Kipar" , en : "Cyprus"},
'Kirgistan':{ rs : " Kirgistan" , en : "Kyrgyzstan"},
'Kiribati':{ rs : " Kiribati" , en : "Kiribati"},
'Kolumbija':{ rs : " Kolumbija" , en : "Colombia"},
'Komori':{ rs : " Komori" , en : "Comoros"},
'Kongo':{ rs : " Kongo" , en : "Congo"},
'Kongo, Demokratska Republika':{ rs : " Kongo, Demokratska Republika" , en : "Congo, Democratic Rep. of"},
'Kostarika':{ rs : " Kostarika" , en : "Costa Rica"},
'Kuba':{ rs : " Kuba" , en : "Cuba"},
'Kukova ostrva':{ rs : " Kukova ostrva" , en : "Cook Islands"},
'Kurasao':{ rs : " Kurasao" , en : "Curacao"},
'Kuvajt':{ rs : " Kuvajt" , en : "Kuwait"},
'Laos':{ rs : " Laos" , en : "Lao P.D.R."},
'Letonija':{ rs : " Letonija" , en : "Latvia"},
'Lesoto':{ rs : " Lesoto" , en : "Lesotho"},
'Liberija':{ rs : " Liberija" , en : "Liberia"},
'Liban':{ rs : " Liban" , en : "Lebanon"},
'Libija':{ rs : " Libija" , en : "Libya"},
'Lihtenštajn':{ rs : " Lihtenštajn" , en : "Liechtenstein"},
'Litvanija':{ rs : " Litvanija" , en : "Lithuania"},
'Luksemburg':{ rs : " Luksemburg" , en : "Luxembourg"},
'Madagaskar':{ rs : " Madagaskar" , en : "Madagascar"},
'Mađarska':{ rs : " Mađarska" , en : "Hungary"},
'Majote':{ rs : " Majote" , en : "Mayotte"},
'Makao':{ rs : " Makao" , en : "Macau SAR China"},
'Malavi':{ rs : " Malavi" , en : "Malawi"},
'Maldivi':{ rs : " Maldivi" , en : "Maldives"},
'Malezija':{ rs : " Malezija" , en : "Malaysia"},
'Mali':{ rs : " Mali" , en : "Mali"},
'Malta':{ rs : " Malta" , en : "Malta"},
'Maroko':{ rs : " Maroko" , en : "Morocco"},
'Maršalska ostrva':{ rs : " Maršalska ostrva" , en : "Marshall Islands"},
'Martinik':{ rs : " Martinik" , en : "Martinique"},
'Mauricijus':{ rs : " Mauricijus" , en : "Mauritius"},
'Mauritanija':{ rs : " Mauritanija" , en : "Mauritania"},
'Meksiko':{ rs : " Meksiko" , en : "Mexico"},
'Mijanmar':{ rs : " Mijanmar" , en : "Myanmar"},
'Mikronezija':{ rs : " Mikronezija" , en : "Micronesia, Fed. States of"},
'Moldavija':{ rs : " Moldavija" , en : "Moldova, Rep. of"},
'Monako':{ rs : " Monako" , en : "Monaco"},
'Mongolija':{ rs : " Mongolija" , en : "Mongolia"},
'Monserat':{ rs : " Monserat" , en : "Montserrat"},
'Mozambik':{ rs : " Mozambik" , en : "Mozambique"},
'Namibija':{ rs : " Namibija" , en : "Namibia"},
'Nauru':{ rs : " Nauru" , en : "Nauru, Rep. of"},
'Nemačka':{ rs : " Nemačka" , en : "Germany"},
'Nepal':{ rs : " Nepal" , en : "Nepal"},
'Nevis':{ rs : " Nevis" , en : "Nevis"},
'Niger':{ rs : " Niger" , en : "Niger"},
'Nigerija':{ rs : " Nigerija" , en : "Nigeria"},
'Nikaragva':{ rs : " Nikaragva" , en : "Nicaragua"},
'Niue':{ rs : " Niue" , en : "Niue"},
'Norveška':{ rs : " Norveška" , en : "Norway"},
'Nova Kaledonija':{ rs : " Nova Kaledonija" , en : "New Caledonia"},
'Novi Zeland':{ rs : " Novi Zeland" , en : "New Zealand"},
'Obala Slonovače':{ rs : " Obala Slonovače" , en : "Cote d’Ivoire"},
'Oman':{ rs : " Oman" , en : "Oman"},
'Pakistan':{ rs : " Pakistan" , en : "Pakistan"},
'Palau':{ rs : " Palau" , en : "Palau"},
'Panama':{ rs : " Panama" , en : "Panama"},
'Papua Nova Gvineja':{ rs : " Papua Nova Gvineja" , en : "Papua New Guinea"},
'Paragvaj':{ rs : " Paragvaj" , en : "Paraguay"},
'Peru':{ rs : " Peru" , en : "Peru"},
'Poljska':{ rs : " Poljska" , en : "Poland"},
'Portugalija':{ rs : " Portugalija" , en : "Portugal"},
'Portoriko':{ rs : " Portoriko" , en : "Puerto Rico"},
'Reunion ostrva':{ rs : " Reunion ostrva" , en : "Reunion, Island of"},
'Ruanda':{ rs : " Ruanda" , en : "Rwanda"},
'Rumunija':{ rs : " Rumunija" , en : "Romania"},
'Ruska Federacija':{ rs : " Ruska Federacija" , en : "Russian Federation, The"},
'Samoa':{ rs : " Samoa" , en : "Samoa"},
'San Marino':{ rs : " San Marino" , en : "San Marino"},
'Saudijska Arabija':{ rs : " Saudijska Arabija" , en : "Saudi Arabia"},
'Sejšeli':{ rs : " Sejšeli" , en : "Seychelles"},
'Senegal':{ rs : " Senegal" , en : "Senegal"},
'Severna Koreja':{ rs : " Severna Koreja" , en : "Korea, D.P.R. of (North)"},
'Severna Makedonija':{ rs : " Severna Makedonija" , en : "North Macedonia"},
'Severna Marijanska ostrva (Saipan)':{ rs : " Severna Marijanska ostrva (Saipan)" , en : "Northern Mariana Islands"},
'Sijera Leone':{ rs : " Sijera Leone" , en : "Sierra Leone"},
'Singapur':{ rs : " Singapur" , en : "Singapore"},
'Sirija':{ rs : " Sirija" , en : "Syria"},
'Sjedinjene Američke Države':{ rs : " Sjedinjene Američke Države" , en : "United States of America"},
'Slovačka':{ rs : " Slovačka" , en : "Slovakia"},
'Slovenija':{ rs : " Slovenija" , en : "Slovenia"},
'Solomonska ostrva':{ rs : " Solomonska ostrva" , en : "Solomon Islands"},
'Somalija':{ rs : " Somalija" , en : "Somalia"},
'Somalilend (Severna Somalija)':{ rs : " Somalilend (Severna Somalija)" , en : "Somaliland, Rep. (N. Somalia)"},
'Srbija':{ rs : " Srbija" , en : "Serbia"},
'Svazilend':{ rs : " Svazilend" , en : "Eswatini"},
'Sveti Bartolomej':{ rs : " Sveti Bartolomej" , en : "St. Barthelemy"},
'Sveti Eustahije':{ rs : " Sveti Eustahije" , en : "St. Eustatius"},
'Sveti Kits':{ rs : " Sveti Kits" , en : "St. Kitts"},
'Sveta Lucija':{ rs : " Sveta Lucija" , en : "St. Lucia"},
'Sveti Martin':{ rs : " Sveti Martin" , en : "St. Maarten"},
'Sveti Toma i Princip':{ rs : " Sveti Toma i Princip" , en : "Sao Tome & Principe"},
'Sveta Jelena':{ rs : " Sveta Jelena" , en : "St. Helena"},
'Sveti Vinsent':{ rs : " Sveti Vinsent" , en : "St. Vincent"},
'Sudan':{ rs : " Sudan" , en : "Sudan"},
'Surinam':{ rs : " Surinam" , en : "Suriname"},
'Španija':{ rs : " Španija" , en : "Spain"},
'Šri Lanka':{ rs : " Šri Lanka" , en : "Sri Lanka"},
'Švedska':{ rs : " Švedska" , en : "Sweden"},
'Švajcarska':{ rs : " Švajcarska" , en : "Switzerland"},
'Tahiti':{ rs : " Tahiti" , en : "Tahiti"},
'Tajvan':{ rs : " Tajvan" , en : "Taiwan"},
'Tadžikistan':{ rs : " Tadžikistan" , en : "Tajikistan"},
'Tanzanija':{ rs : " Tanzanija" , en : "Tanzania"},
'Tajland':{ rs : " Tajland" , en : "Thailand"},
'Togo':{ rs : " Togo" , en : "Togo"},
'Tonga':{ rs : " Tonga" , en : "Tonga"},
'Trinidad i Tobago':{ rs : " Trinidad i Tobago" , en : "Trinidad and Tobago"},
'Tunis':{ rs : " Tunis" , en : "Tunisia"},
'Turkmenistan*':{ rs : " Turkmenistan*" , en : "Turkmenistan"},
'Turska':{ rs : " Turska" , en : "Turkey"},
'Turks i Kaikos ostrva':{ rs : " Turks i Kaikos ostrva" , en : "Turks and Caicos Islands"},
'Tuvalu':{ rs : " Tuvalu" , en : "Tuvalu"},
'Uganda':{ rs : " Uganda" , en : "Uganda"},
'Ukrajina':{ rs : " Ukrajina" , en : "Ukraine"},
'Ujedinjeni Arapski Emirati':{ rs : " Ujedinjeni Arapski Emirati" , en : "United Arab Emirates"},
'Urugvaj':{ rs : " Urugvaj" , en : "Uruguay"},
'Uzbekistan':{ rs : " Uzbekistan" , en : "Uzbekistan"},
'Vanuatu':{ rs : " Vanuatu" , en : "Vanuatu"},
'Vatikan':{ rs : " Vatikan" , en : "Vatican City"},
'Velika Britanija':{ rs : " Velika Britanija" , en : "United Kingdom"},
'Venecuela':{ rs : " Venecuela" , en : "Venezuela"},
'Vijetnam':{ rs : " Vijetnam" , en : "Vietnam"},
'Zambija':{ rs : " Zambija" , en : "Zambia"},
'Zelenortska ostrva':{ rs : " Zelenortska ostrva" , en : "Cape Verde"},
'Zimbabve':{ rs : " Zimbabve" , en : "Zimbabwe"},
};

let tempCountries: any = [];
 Object.entries(countries).forEach(([key, value])=>{
     tempCountries.push({id: key, name: value.rs, en: value.en })
 })
export const countryArray = tempCountries;