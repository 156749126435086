
import { Component, Vue } from "vue-property-decorator";
import WalletIcon from "@/icons/WalletIcon.vue";
import { CartStore } from "../store/cart";
import { NavigationStore } from "@/store/navigation";
import { OrderStore } from "@/store/order";

@Component({ components: { WalletIcon } })
export default class AppAsideCart extends Vue {
  NavigationStore: any = NavigationStore;
  OrderStore: any = OrderStore;
  imageUrlPrefix: string | undefined = process.env.VUE_APP_BACKEND_IMAGE_PREFIX;
  get cart() {
    return CartStore.cart;
  }
  get orderTotals() {
    return OrderStore.order && OrderStore.order.totals
      ? OrderStore.order.totals
      : null;
  }
  get discountPrice() {
    return this.orderTotals && this.orderTotals.discount_amount
      ? this.orderTotals.discount_amount
      : null;
  }
  get orderShipping() {
    return this.orderTotals && this.orderTotals.shipping_amount
      ? this.orderTotals.shipping_amount
      : null;
  }
  get totalSum() {
    if (this.orderTotals) {
      return this.orderTotals && this.orderTotals.total
        ? this.orderTotals.total
        : null;
    }
    let sum = 0;
    sum += this.totalPrice ? this.totalPrice : 0;
    sum -= this.discountPrice ? this.discountPrice : 0;
    sum += this.orderShipping ? this.orderShipping : 0;
    return sum;
  }

  get totalPrice() {
    if (this.orderTotals && this.orderTotals.session_amount) {
      return this.orderTotals.session_amount;
    }
    let total: number = 0;
    if (CartStore.cart && CartStore.cart.length > 0) {
      CartStore.cart.forEach((item: any, i: number) => {
        let totalItemPrice = item.price * item.quantity;
        total += totalItemPrice;
      });
      return total;
    }
    return 0;
  }

  mounted() {
    CartStore.fetchCart();
    OrderStore.getOrderDetails();
  }
}
