
import { Vue, Component } from "vue-property-decorator";
import { ProductStore } from "@/store/product";
import { LoaderStore } from "@/store/loader";

@Component({
  components: {},
})
export default class Wallet extends Vue {
  activeRow: number | null = null;
  imageURLprefix: string | undefined = process.env.VUE_APP_BACKEND_IMAGE_PREFIX;

  get purchases() {
    return ProductStore.purchases ? ProductStore.purchases : null;
  }

  get selectedPurchase() {
    return ProductStore.purchase ? ProductStore.purchase : null;
  }
  get loading() {
    return LoaderStore.loading;
  }

  async mounted() {
    await ProductStore.fetchPurchases();
  }

  async setActiveRow(rowIndex: number, purchaseId: number) {
    if (this.activeRow === rowIndex) this.activeRow = null;
    else {
      this.activeRow = rowIndex;
      await ProductStore.fetchPurchase(purchaseId);
    }
  }
}
