var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('header',{staticClass:"w-full h-full grid grid-cols-1 select-none relative z-20 border-b"},[_c('div',{staticClass:"w-full h-full grid gird-cols-1 sticky top-0"},[_c('div',{staticClass:"w-full h-12 bg-black flex"},[_c('div',{staticClass:"w-full xl:w-10/12 2xl:w-1440 mx-auto text-center relative text-white"},[_c('div',{staticClass:"absolute left-0 top-0 flex font-sans shadow-lg tracking-wider shadow-none"},[_c('div',{staticClass:"w-28 h-24 bg-white transform translate-y-0",on:{"click":function($event){_vm.$router.push('/').catch(() => {});
                _vm.NavigationStore.toggleMobileNavigation(false);
                _vm.NavigationStore.toggleLogin(false);}}},[_c('logo')],1),_c('div',{staticClass:"mt-3.5 ml-12 hidden lg:block",on:{"click":function($event){_vm.$router.push('/').catch(() => {});
                _vm.NavigationStore.toggleMobileNavigation(false);
                _vm.NavigationStore.toggleLogin(false);}}},[_vm._m(0)])]),_c('p',{staticClass:"h-12 w-12 m-auto hidden md:flex"},[_c('logo-full')],1),_c('ul',{staticClass:"absolute lg:right-4 xl:right-0 top-4 space-x-12 text-xs hidden lg:flex items-center"},[_c('li',{staticClass:"hover:text-gray-300 cursor-pointer",on:{"click":function($event){return _vm.$router.push('/about')}}},[(_vm.NavigationStore.language === 'en')?[_vm._v(" ABOUT FWI ")]:_vm._e(),(_vm.NavigationStore.language === 'rs')?[_vm._v(" O NAMA ")]:_vm._e()],2),_c('li',{staticClass:"hover:text-gray-300 cursor-pointer",on:{"click":() => {
                  if (this.$route.path !== '/blogs/1')
                    this.$router.push('/blogs/1');
                }}},[_vm._v(" BLOG ")]),_c('li',{staticClass:"hover:text-gray-300 cursor-pointer",on:{"click":_vm.toggleLanguage}},[_vm._v(" "+_vm._s(_vm.fetchLanguage() === _vm.Locales.SR ? "ENGLISH" : "SRPSKI")+" ")]),_c('li',{staticClass:"hover:text-gray-300 cursor-pointer"},[_c('select',{staticClass:"bg-black",domProps:{"value":_vm.region},on:{"change":_vm.setRegion}},[_c('option',{domProps:{"value":_vm.regions.rs}},[_vm._v("RS")]),_c('option',{domProps:{"value":_vm.regions.eu}},[_vm._v("EU")]),_c('option',{domProps:{"value":_vm.regions.us}},[_vm._v("GLOBAL")])])])]),_c('div',{staticClass:"absolute right-4 top-1 space-x-12 flex items-center lg:hidden"},[_c('div',{staticClass:"flex mr-auto",on:{"click":function($event){_vm.NavigationStore.toggleMobileNavigation();
                _vm.NavigationStore.toggleLogin(false);}}},[(!_vm.mobileNav)?_c('menu-icon',{staticClass:"h-10 w-10"}):_vm._e(),(_vm.mobileNav)?_c('div',{staticClass:"h-10 w-10 flex m-auto text-3xl pl-2 text-gray-200 transform scale-125"},[_vm._v(" × ")]):_vm._e()],1)])])]),_c('div',{staticClass:"relative w-full h-16"},[_c('div',{staticClass:"w-full xl:w-10/12 2xl:w-1440 mx-auto flex items-center h-16 relative"},[_c('div',{staticClass:"w-full transform translate-x-36 hidden lg:block"},[_c('ul',{staticClass:"w-full h-full flex space-x-6 uppercase text-sm font-medium"},_vm._l((_vm.mainMenuItems),function(value,key,index){return _c('li',{key:index,staticClass:"group h-16 items-center tracking-widest cursor-pointer flex items-center",on:{"click":(index) => {
                    if (_vm.selectedMenuKey !== key) {
                      _vm.selectedMenuKey = key;
                      _vm.activeLink = key;
                    } else {
                      _vm.selectedMenuKey = null;
                      _vm.activeLink = null;
                    }
                    _vm.miniCart = false;
                  }}},[_c('span',{staticClass:"p-2 px-4 hover:ring-1 ring-black rounded-full transition duration-200 ease-linear",class:_vm.activeLink === key ? 'ring-1 ring-black rounded-full' : ''},[(_vm.mainMenuItems[key].name)?[_vm._v(" "+_vm._s(_vm.mainMenuItems[key].name)+" ")]:_vm._e()],2)])}),0)]),_c('div',{staticClass:"ml-auto mr-4 xl:mr-0"},[_c('ul',{staticClass:"flex space-x-9 relative"},[_c('li',{staticClass:"relative cursor-pointer",on:{"click":function($event){return _vm.modal.set({ name: 'searchModal', show: true })}}},[_c('search-icon',{staticClass:"h-5 w-5"})],1),_c('li',{staticClass:"relative cursor-pointer relative",on:{"click":function($event){_vm.$router.push('/cart').catch(() => {});
                  _vm.miniCart = false;
                  _vm.NavigationStore.toggleLogin(false);
                  _vm.NavigationStore.toggleMobileNavigation(false);},"mouseover":function($event){_vm.miniCart = true}}},[_c('cart-icon',{staticClass:"h-5 w-5"}),(_vm.cart && _vm.cart.length && _vm.cart.length > 0)?_c('span',{staticClass:"absolute -right-1 -bottom-1 text-xxs font-medium bg-black rounded-full text-white px-1"},[_vm._v(_vm._s(_vm.cartLength))]):_vm._e()],1),_c('li',{staticClass:"cursor-pointer relative",on:{"click":function($event){_vm.NavigationStore.toggleLogin();
                  _vm.NavigationStore.toggleDesktopNavigation(false);
                  _vm.NavigationStore.toggleMobileNavigation(false)
                  if (_vm.isCustomerLoggedIn)
                    _vm.$router.push('/profile').catch(() => {});}}},[_c('user-icon',{staticClass:"h-5 w-5"}),(_vm.login && !_vm.isCustomerLoggedIn)?_c('triangle-menu-icon',{staticClass:"h-4 w-4 absolute inset-x-0.5 inset-y-7"}):_vm._e()],1)])]),(_vm.selectedMenuKey)?_c('div',{staticClass:"subMenu ease-linear w-full z-50 absolute top-16 z-20 bg-white border-t pt-5 shadow-3xl"},[_c('div',{staticClass:"relative w-full h-full pl-5 pb-5"},[(_vm.mainMenuItems[_vm.selectedMenuKey])?[_c('div',{staticClass:"w-10/12 2xl:container flex mx-auto relative"},[_c('div',{staticClass:"w-2/12 border-r text-left ml-3"},[(_vm.mainMenuItems[_vm.selectedMenuKey].name)?_c('p',{staticClass:"my-2 font-bold uppercase"},[_vm._v(" "+_vm._s(_vm.mainMenuItems[_vm.selectedMenuKey].name)+" ")]):_vm._e(),(_vm.mainMenuItems[_vm.selectedMenuKey].subcategories)?_c('ul',{staticClass:"space-y-2"},_vm._l((_vm.mainMenuItems[
                          _vm.selectedMenuKey
                        ].subcategories),function(item,key,index){return _c('li',{key:index,staticClass:"cursor-pointer",on:{"click":function($event){return _vm.goToSubcategoryDetails(item)}}},[_vm._v(" "+_vm._s(item.name)+" ")])}),0):_vm._e()]),_c('div',{staticClass:"w-10/12 flex gap-x-2 ml-6"},_vm._l((_vm.mainMenuItems[_vm.selectedMenuKey]
                        .products),function(item,index){return _c('div',{key:index,staticClass:"h-full w-28 cursor-pointer hover:shadow-2xl p-2 transition duration-200 ease-linear",on:{"click":function($event){return _vm.goToProductDetails(item)}}},[[(
                            item.images &&
                              Array.isArray(item.images) &&
                              item.images.length > 0
                          )?[_c('img',{attrs:{"src":_vm.imageURLprefix + item.images[0].url,"alt":""}})]:_c('img',{staticClass:"h-5/6 w-auto",attrs:{"src":require("../../assets/backgrounds/item3.png"),"alt":""}}),_c('p',{staticClass:"text-xs font-medium text-left"},[_vm._v(" "+_vm._s(item.name)+" ")]),_c('p',{staticClass:"text-xs font-medium text-left"},[_vm._v(" "+_vm._s(_vm._f("monetary")(item.price))+" ")])]],2)}),0)])]:_vm._e()],2),_c('div',{staticClass:"ml-auto w-32 h-10 flex justify-center items-center bg-white-200 font-medium cursor-pointer",on:{"click":function($event){_vm.selectedMenuKey = null; _vm.activeLink = null;}}},[_vm._v(" CLOSE ")])]):_vm._e()])])]),_c('transition',{attrs:{"name":"enter-from-right"}},[(_vm.mobileNav)?_c('app-mobile-navigation',{on:{"setRegion":function($event){return _vm.setRegion($event)},"toggleLanguage":_vm.toggleLanguage,"fetchLanguage":_vm.fetchLanguage,"closeMobileNav":function($event){return _vm.NavigationStore.setMobileNav(!_vm.mobileNav)}}}):_vm._e()],1),_c('transition',{attrs:{"name":"scaleX-animation"}},[(_vm.login && !_vm.isCustomerLoggedIn)?_c('app-login'):_vm._e()],1),_c('app-search-modal'),_c('contact-info-modal'),_c('about-us-modal'),_c('terms-of-use-modal'),_c('shipping-info-modal'),_c('complaints-modal'),_c('goods-replacement-modal'),_c('privacy-policy-modal'),_c('protection-of-confidential-info-modal'),_c('refund-info-modal'),_c('app-shop-filters-modal'),_c('app-image-viewer')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('span',{staticClass:"cursor-pointer hover:text-gray-300"},[_c('span',{staticClass:"font-bold mr-1"},[_vm._v("FWI")]),_vm._v(" BY IVANA KORAB")])
}]

export { render, staticRenderFns }