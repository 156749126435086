
import { Component, Vue } from "vue-property-decorator";
import { NavigationStore } from "@/store/navigation";
import { CustomerStore } from "../store/customer";
@Component({})
export default class AppRegisterBanner extends Vue {
  NavigationStore: any = NavigationStore;
  CustomerStore: any = CustomerStore;

  isRegistered() {
    return CustomerStore.token;
  }
}
