import axiosInstance from 'axios';
import Vue from "vue";
import {regions} from '../utilities/constants';
import { NavigationStore } from "@/store/navigation";
Vue.filter('monetary', function (value: any) {
  if(value === "") {
    return ""
  }
  // // TODO izmeniti reg da se hvata ili iz headera ili iz nekog storea
  //
  // let reg = NavigationStore.region;
  // console.log(reg);
  // let currency = "RSD";
  // if (reg){
  //   if( reg == regions.eu){
  //     currency = "EUR"
  //   }
  //   if( reg!= regions.eu && reg != regions.rs){
  //     currency = "USD"
  //   }
  // }
  const formated = new Intl.NumberFormat('en-EN', { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(value)
  return formated + " " + NavigationStore.currency;
})
