
import { NavigationStore } from "@/store/navigation";
import { CustomerStore } from "@/store/customer";
import { Component, Vue } from "vue-property-decorator";
import ResetPasswordModal from "./modals/ResetPasswordModal.vue";
import { modal } from "@/store/modal";
@Component({ components: { ResetPasswordModal } })
export default class AppLogin extends Vue {
  Modal: any = modal;
  NavigationStore: any = NavigationStore;
  CustomerStore: any = CustomerStore;
  showPass: boolean = false;
  keepMeLoggedIn: boolean = false;
  year: number = 2021;
  email: string | null = null;
  password: string | null = null;

  public async loginCustomer() {
    let data = {
      email: this.email && this.email.trim() ? this.email.trim() : null,
      password:
        this.password && this.password.trim() ? this.password.trim() : null,
      keepMeLoggedIn: this.keepMeLoggedIn,
    };
    if (await CustomerStore.loginCustomer(data)) {
      this.email = null;
      this.password = null;
    }
  }

  mounted() {
    this.getYear();
  }

  getYear() {
    let d = new Date();
    this.year = d.getFullYear();
  }
}
