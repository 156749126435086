
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import ArrowPhotoOverlayIcon from "../icons/ArrowPhotoOverlayIcon.vue";
import RecomendedProductsSingleProduct from "./RecomendedProductsSingleProduct.vue";

@Component({
  components: { ArrowPhotoOverlayIcon, RecomendedProductsSingleProduct },
})
export default class AppRecommendedProductsSlider extends Vue {
  private imageURLprefix: string | undefined =
    process.env.VUE_APP_BACKEND_IMAGE_PREFIX;
  liked: boolean = false;
  imgId!: number;
  parentId!: number;
  test?: number;

  @Prop({ required: true })
  title!: string;

  @Prop({ required: true })
  products!: any[] | any;

  @Prop({})
  link!: string;

  @Prop({})
  elementId!: string;

  get itemsToShow() {
    if (window.innerWidth < 600) return 1;
    if (window.innerWidth < 900) return 2;
    if (window.innerWidth < 1025) return 3;
    if (window.innerWidth >= 1025) return 4;
  }

  slide(side: string, elementId: any, itemId: any): void {
    let content = document.getElementById(elementId) as HTMLElement;
    let itemWidth = document.getElementById(itemId) as HTMLElement;
    let contentWidth: number = content.offsetWidth;
    let containerWidth: number = itemWidth.offsetWidth;
    if (side === "left") {
      content.scrollLeft -= containerWidth + 25;
    } else {
      content.scrollLeft += containerWidth + 25;
    }
  }
}
