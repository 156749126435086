
import { Component, Vue } from "vue-property-decorator";
import WalletIcon from "@/icons/WalletIcon.vue";
import AppAsideCart from "@/components/AppAsideCart.vue";
import AppCartSteps from "@/components/AppCartSteps.vue";
import { NavigationStore } from "../store/navigation";
import { CustomerStore } from "../store/customer";
import { CartStore } from "@/store/cart";
import { OrderStore } from "../store/order";
import { countryArray } from "../utilities/constants";

@Component({ components: { WalletIcon, AppAsideCart, AppCartSteps } })
export default class ContactInformation extends Vue {
  NavigationStore: any = NavigationStore;
  CartStore: any = CartStore;
  CustomerStore: any = CustomerStore;
  OrderStore: any = OrderStore;
  isAnotherAddress: boolean = false;
  isNewsletterChecked: boolean = false;
  countryOptions: any = countryArray;
  selectedCountry: any = null;
  selectedAdditionalCountry: any = null;
  shouldAddToMailingList: boolean = false;

  form: any = {
    emailInput: null,
    firstNameInput: null,
    lastNameInput: null,
    addressInput: null,
    aptSuiteInput: null,
    postalCodeInput: null,
    cityInput: null,
    numberInput: null,
    selectedCountry: null,
    stateProvidence: null,
  };

  additionalForm: any = {
    additionalAddressInput: null,
    additionalAptSuiteInput: null,
    additionalPostalCodeInput: null,
    additionalCityInput: null,
    additionalNumberInput: null,
    shouldAddToMailingList: false,
    selectedAdditionalCountry: null,
    additionalStateProvidence: null,
  };
  get isMailDisabled() {
    return CustomerStore.token ? true : false;
  }

  get info() {
    return this.OrderStore.order && this.OrderStore.order.details
      ? this.OrderStore.order.details
      : null;
  }

  get countryInput() {
    return this.form.selectedCountry && this.form.selectedCountry.id
      ? this.form.selectedCountry.id
      : null;
  }
  get additionalCountryInput() {
    return this.additionalForm.selectedAdditionalCountry &&
      this.additionalForm.selectedAdditionalCountry.id
      ? this.additionalForm.selectedAdditionalCountry.id
      : null;
  }
  get areMainInputsFilled() {
    return (
      this.form.emailInput &&
      this.form.emailInput.trim() &&
      this.form.emailInput.trim() !== "" &&
      this.form.firstNameInput &&
      this.form.firstNameInput.trim() &&
      this.form.firstNameInput.trim() !== "" &&
      this.form.lastNameInput &&
      this.form.lastNameInput.trim() &&
      this.form.lastNameInput.trim() !== "" &&
      this.form.addressInput &&
      this.form.addressInput.trim() &&
      this.form.addressInput.trim() !== "" &&
      this.form.aptSuiteInput &&
      this.form.aptSuiteInput.trim() &&
      this.form.aptSuiteInput.trim() !== "" &&
      this.form.postalCodeInput &&
      this.form.postalCodeInput.trim() &&
      this.form.postalCodeInput.trim() !== "" &&
      this.form.cityInput &&
      this.form.cityInput.trim() &&
      this.form.cityInput.trim() !== "" &&
      this.form.selectedCountry &&
      this.form.selectedCountry &&
      this.form.selectedCountry !== "" &&
      this.form.numberInput &&
      this.form.numberInput.trim() &&
      this.form.numberInput.trim() !== ""
    );
  }
  get areAdditionalInputsFilled() {
    return (
      this.form.emailInput &&
      this.form.emailInput.trim() &&
      this.form.emailInput.trim() !== "" &&
      this.form.firstNameInput &&
      this.form.firstNameInput.trim() &&
      this.form.firstNameInput.trim() !== "" &&
      this.form.lastNameInput &&
      this.form.lastNameInput.trim() &&
      this.form.lastNameInput.trim() !== "" &&
      this.additionalForm.additionalAddressInput &&
      this.additionalForm.additionalAddressInput.trim() &&
      this.additionalForm.additionalAddressInput.trim() !== "" &&
      this.additionalForm.additionalAptSuiteInput &&
      this.additionalForm.additionalAptSuiteInput.trim() &&
      this.additionalForm.additionalAptSuiteInput.trim() !== "" &&
      this.additionalForm.additionalPostalCodeInput &&
      this.additionalForm.additionalCityInput &&
      this.additionalForm.additionalCityInput.trim() &&
      this.additionalForm.additionalCityInput.trim() !== "" &&
      this.additionalForm.selectedAdditionalCountry &&
      this.additionalForm.selectedAdditionalCountry !== "" &&
      this.additionalForm.additionalNumberInput &&
      this.additionalForm.additionalNumberInput.trim() &&
      this.additionalForm.additionalNumberInput.trim() !== ""
    );
  }

  get isLoggedIn() {
    return !!CustomerStore.token;
  }

  get isAnonymousDataValid() {
    return !this.isLoggedIn && this.areMainInputsFilled;
  }
  get isCustomerDataValid() {
    return (
      this.isLoggedIn &&
      ((this.areMainInputsFilled && !this.isAnotherAddress) ||
        (this.isAnotherAddress && this.areAdditionalInputsFilled))
    );
  }
  get isTransitionAllowed() {
    return this.isAnonymousDataValid || this.isCustomerDataValid ? true : false;
  }
  get contactParams() {
    if (!this.isAnotherAddress) {
      return {
        email: this.form.emailInput,
        first_name: this.form.firstNameInput,
        last_name: this.form.lastNameInput,
        address: this.form.addressInput,
        apt_suite: this.form.aptSuiteInput,
        postal_code: Number(this.form.postalCodeInput),
        city: this.form.cityInput,
        country: this.countryInput,
        state_providence: this.form.stateProvidence,
        phone_number: this.form.numberInput,
        should_add_to_mailing_list: this.shouldAddToMailingList,
        is_other_delivery_address: false,
      };
    } else {
      return {
        email: this.form.emailInput,
        first_name: this.form.firstNameInput,
        last_name: this.form.lastNameInput,
        address: this.additionalForm.additionalAddressInput,
        apt_suite: this.additionalForm.additionalAptSuiteInput,
        postal_code: this.additionalForm.additionalPostalCodeInput,
        city: this.additionalForm.additionalCityInput,
        country: this.additionalCountryInput,
        state_providence: this.additionalForm.additionalStateProvidence,
        phone_number: this.additionalForm.additionalNumberInput,
        should_add_to_mailing_list: this.shouldAddToMailingList,
        is_other_delivery_address: true,
      };
    }
  }

  public validateForm() {
    if (!this.isAnotherAddress) {
      Object.keys(this.form).forEach((key) => {
        if (this.form[key] === null) this.form[key] = "";
      });
    } else {
      Object.keys(this.additionalForm).forEach((key) => {
        if (this.additionalForm[key] === null) this.additionalForm[key] = "";
      });
    }
  }

  public async transitionToShipping() {
    this.validateForm();
    if (this.isTransitionAllowed) {
      if (await OrderStore.uploadOrderContactDetails(this.contactParams)) {
        this.$router.push("/shipping");
      }
    }
  }

  async mounted() {
    if (localStorage.getItem("fwi-token")) {
      await CustomerStore.fetchCustomerInfo();
    }
    await CartStore.fetchCart();
    await OrderStore.getOrderDetails();
    this.setCustomerInfoData();
  }

  public setCustomerInfoData() {
    if (this.info) {
      this.shouldAddToMailingList = this.info.is_subscribed
        ? this.info.is_subscribed
        : false;
      this.form.emailInput = this.info.email ? this.info.email : null;
      this.form.firstNameInput = this.info.first_name
        ? this.info.first_name
        : null;
      this.form.lastNameInput = this.info.last_name
        ? this.info.last_name
        : null;
      if (this.info.is_other_delivery_address && CustomerStore.token) {
        this.fillCustomerInfoFields();
        this.isAnotherAddress = true;
        this.additionalForm.additionalAddressInput = this.info.address
          ? this.info.address
          : null;
        this.additionalForm.additionalAptSuiteInput = this.info.apt_suite
          ? this.info.apt_suite
          : null;
        this.additionalForm.additionalPostalCodeInput = this.info.postal_code
          ? this.info.postal_code
          : null;
        this.additionalForm.additionalNumberInput = this.info.phone_number
          ? this.info.phone_number
          : null;
        this.additionalForm.additionalCityInput = this.info.city
          ? this.info.city
          : null;
        this.additionalForm.additionalStateProvidence = this.info
          .state_providence
          ? this.info.state_providence
          : null;
        if (this.info.country) {
          this.additionalForm.selectedAdditionalCountry = this.countryOptions.find(
            (country: any) => country.id == this.info.country
          );
        } else {
          this.additionalForm.additional_country = null;
        }
      } else {
        this.isAnotherAddress = false;
        this.form.addressInput = this.info.address ? this.info.address : null;
        this.form.aptSuiteInput = this.info.apt_suite
          ? this.info.apt_suite
          : null;
        this.form.postalCodeInput = this.info.postal_code
          ? String(this.info.postal_code)
          : null;
        this.form.numberInput = this.info.phone_number
          ? this.info.phone_number
          : null;
        this.form.cityInput = this.info.city ? this.info.city : null;
        this.form.stateProvidence = this.info.state_providence
          ? this.info.state_providence
          : null;
        if (this.info.country) {
          this.form.selectedCountry = this.countryOptions.find(
            (country: any) => country.id == this.info.country
          );
        } else {
          this.form.selectedCountry = null;
        }
      }
    } else if (CustomerStore.token) {
      this.fillCustomerInfoFields();
    }
    if (CustomerStore.token) {
      this.form.emailInput = CustomerStore.customerInfo.email
        ? CustomerStore.customerInfo.email
        : null;
    }
  }
  public fillCustomerInfoFields() {
    if (CustomerStore.customerInfo) {
      this.shouldAddToMailingList = CustomerStore.customerInfo.is_subscribed
        ? CustomerStore.customerInfo.is_subscribed
        : false;
      this.form.emailInput = CustomerStore.customerInfo.email
        ? CustomerStore.customerInfo.email
        : null;
      this.form.firstNameInput = CustomerStore.customerInfo.first_name
        ? CustomerStore.customerInfo.first_name
        : null;
      this.form.lastNameInput = CustomerStore.customerInfo.last_name
        ? CustomerStore.customerInfo.last_name
        : null;
      this.isAnotherAddress = false;
      this.form.addressInput = CustomerStore.customerInfo.address
        ? CustomerStore.customerInfo.address
        : null;
      this.form.aptSuiteInput = CustomerStore.customerInfo.apt_suite
        ? CustomerStore.customerInfo.apt_suite
        : null;
      this.form.postalCodeInput = CustomerStore.customerInfo.postal_code
        ? String(CustomerStore.customerInfo.postal_code)
        : null;
      this.form.numberInput = CustomerStore.customerInfo.phone_number
        ? CustomerStore.customerInfo.phone_number
        : null;
      this.form.cityInput = CustomerStore.customerInfo.city
        ? CustomerStore.customerInfo.city
        : null;
      if (CustomerStore.customerInfo.country) {
        this.form.selectedCountry = this.countryOptions.find(
          (country: any) => country.id == CustomerStore.customerInfo.country
        );
      } else {
        this.form.selectedCountry = null;
      }
    }
  }
}
