import { Module, VuexModule, Mutation, Action, getModule, } from 'vuex-module-decorators';
import {registerCustomer, loginCustomer, getCustomerInfo,
    addLike, getLikedProducts, deactivateAccount, updateInfo, changePassword, resetPassword} from '../api/customer';
import store from "./index";
import { NavigationStore } from "@/store/navigation";
import { OrderStore } from "@/store/order";
import { CartStore } from "@/store/cart";
import axios from '../../config/axios';
import router from '@/router';

@Module({
    name: 'Customer',
    namespaced: true,
    dynamic: true,
    store
})

export default class Customer extends VuexModule {
    customerInfo: any = null;
    token: string | null | undefined = localStorage.getItem('fwi-token') || null;
    likedProducts : any | null = null;

    @Mutation
    setToken(data: string | null){
        if (data) localStorage.setItem('fwi-token', data);
        this.token = data;
    }
    @Mutation
    setCustomerInfo(data: any){
        this.customerInfo = data;
    }
    @Mutation
    setLikedProducts(data: any){
        this.likedProducts = data;
    }
    @Mutation
    setCookie(data: any) {
        var expires = "";
        if (data.days) {
            var date = new Date();
            date.setTime(date.getTime() + (data.days * 24 * 60 * 60 * 1000));
            expires = "; expires=" + date.toUTCString();
        }
        document.cookie = data.name + "=" + (data.value || "")  + expires;
    }
    @Mutation
    eraseCookie(name: string) {
        document.cookie = name +'=; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    }

    @Action
    async registerCustomer(registerData: any){
        const response = await registerCustomer(registerData);
        if(response && response.status === 'ok'){
            return true;
        }
        return false;
    }
    @Action
    async loginCustomer(registerData: any){
        const {email, password, keepMeLoggedIn} = registerData;
        const response = await loginCustomer({email, password});
        if(response && response.status === 200){
            axios.defaults.headers.common["Authorization"] = `Bearer ${response.token}`;
            store.commit('Navigation/toggleLogin',false);
            OrderStore.clearAllInfo();
            CartStore.clearCart();
            store.dispatch('cart/fetchCart');
            store.dispatch('order/getOrderDetails');
            localStorage.removeItem("anonymous_user_id");
            delete axios.defaults.headers.common["AnonymousUserId"];
            this.setToken(response.token);
            if (keepMeLoggedIn) this.setCookie({name: 'fwi-token', value: response.token, days: 365});
            return true;
        }   
        return false;
    }
    @Action
    logout() {
        this.setCustomerInfo(null);
        this.setLikedProducts(null);
        localStorage.removeItem("fwi-token");
        this.eraseCookie("fwi-token");
        this.setToken(null);
        OrderStore.clearAllInfo();
        CartStore.clearCart();
        delete axios.defaults.headers.common["Authorization"];
        delete axios.defaults.headers.common["AnonymousUserId"];
        NavigationStore.toggleLogin(false);
        router.push({name: 'Home'});
    }
    @Action
    async deactivateAccount(){
        const response = await deactivateAccount();
        if(response && response.hasOwnProperty('status') && response.status === 'ok'){
            this.logout();
        }
    }
    @Action
    async fetchCustomerInfo(){
        const response = await getCustomerInfo();
        if(response){
            this.setCustomerInfo(response);
        }
    }
    @Action
    fetchCookie(name: string) {
        const token = localStorage.getItem("fwi-token");
        if (token) {
            // @ts-ignore
            this.setToken(token);
        } else {
            const value = `; ${document.cookie}`;
            const parts = value.split(`; ${name}=`);
            let cookie: string | undefined;
            if (parts.length === 2) {
                // @ts-ignore
                cookie = parts.pop().split(';').shift();
                // @ts-ignore
                this.setToken(cookie);
            }
        }
    }
    @Action
    async updateInformation(params:any){
        const response = await updateInfo(params);
        if(response && response.hasOwnProperty('status') && response.status === 'ok'){
            await this.fetchCustomerInfo();
        }
    }
    @Action
    async changePassword(params:any) {
        const response = await changePassword(params);
        if(response && response.hasOwnProperty('status') && response.status === 'ok'){
            return true;
        }
        return false;
    }
    @Action
    async resetPassword(params:any) {
        const response = await resetPassword(params);
        if(response && response.hasOwnProperty('status') && response.status === 'ok'){
            return true;
        }
        return false;
    }
    @Action
    async addLike(productId:Number){
        await addLike({product_id: productId});
    }
    @Action
    async fetchLikedProducts(){
        const response = await getLikedProducts();
        this.setLikedProducts(response);
    }
}
export const CustomerStore = getModule(Customer);