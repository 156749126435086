
import { Vue, Component } from "vue-property-decorator";
import ProfileInfoIcon from "@/icons/ProfileInfoIcon.vue";
import ProfileLikeIcon from "@/icons/ProfileLikeIcon.vue";
import WalletIcon from "@/icons/WalletIcon.vue";
import ProfilePurchaseIcon from "@/icons/ProfilePurchaseIcon.vue";
import ProfileFollowOrderIcon from "@/icons/ProfileFollowOrderIcon.vue";
import ArrowRightIcon from "@/icons/ArrowRightIcon.vue";
import ProfileInformation from "@/components/profile/ProfileInformation.vue";
import Wallet from "@/components/profile/Wallet.vue";
import Purchases from "@/components/profile/Purchases.vue";
import ProfileLikes from "@/components/profile/ProfileLikes.vue";
import { NavigationStore } from "../store/navigation";
import { CustomerStore } from "../store/customer";
import { WalletStore } from "../store/wallet";
@Component({
  components: {
    ProfileInfoIcon,
    ProfileLikeIcon,
    ProfilePurchaseIcon,
    ProfileFollowOrderIcon,
    ArrowRightIcon,
    ProfileInformation,
    ProfileLikes,
    Wallet,
    Purchases,
    WalletIcon,
  },
})
export default class Profile extends Vue {
  tabIndex: number = 0;
  NavigationStore: any = NavigationStore;
  CustomerStore: any = CustomerStore;
  WalletStore: any = WalletStore;

  public async selectLikesTab() {
    this.tabIndex = 1;
    await CustomerStore.fetchLikedProducts();
  }

  public async selectWalletTab() {
    this.tabIndex = 3;
    await WalletStore.fetchWalletBalance();
  }
}
