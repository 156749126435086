
//inside src/mixins.ts
import { Component, Vue } from "vue-property-decorator";

@Component
export default class Mixin extends Vue {
  filters = {
        paginationPage: 1,
      };
    paginationTotal = 0;
}
