
import { Component, Vue } from "vue-property-decorator";
import { modal } from "@/store/modal";
import { CustomerStore } from "@/store/customer";
@Component({})
export default class AppThankYouModal extends Vue {
  CustomerStore: any = CustomerStore;
  Modal: any = modal;
  oldPassword: string | null = null;
  password: string | null = null;
  passwordRepeat: string | null = null;

  get isInvalidPassword() {
    return this.password &&
      this.passwordRepeat &&
      this.oldPassword &&
      this.password === this.passwordRepeat
      ? false
      : true;
  }
  public clearModal() {
    this.oldPassword = null;
    this.password = null;
    this.passwordRepeat = null;
  }

  public async changePassword() {
    if (
      await CustomerStore.changePassword({
        password: this.oldPassword,
        new_password: this.password,
      })
    ) {
      this.Modal.set({ name: "changePasswordModal", show: false });
      this.clearModal();
    }
  }
}
