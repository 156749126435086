var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"w-full h-full"},[_c('div',{staticClass:"w-full h-full gap-8 mx-auto hidden lg:grid lg:grid-cols-1"},[_c('slider',{attrs:{"animation":"fade","height":"500px","autoplay":false},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}},[_vm._l((_vm.images),function(img,i){return _c('slider-item',{key:i,on:{"click":function($event){return _vm.openImageViewer(_vm.images, i)}}},[_c('div',{staticClass:"w-4/5 h-full cursor-pointer group mx-auto inline-flex items-center justify-center relative",style:({
            backgroundImage: `url(${_vm.host + encodeURI(img).replace(/\(/g, '%28').replace(/\)/g, '%29')})`,
            backgroundSize: 'contain',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundColor: 'transparent',
          })})])}),(_vm.videoImage && _vm.videoImage.url && _vm.videoUrl)?_c('slider-item',{key:_vm.images.length},[_c('div',{staticClass:"w-4/5 h-full cursor-pointer group mx-auto inline-flex items-center justify-center relative",style:({
            backgroundImage: `url(${_vm.host + _vm.videoImage.url})`,
            backgroundSize: 'contain',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundColor: 'transparent',
          })},[_c('div',{staticClass:"absolute h-full w-full bg-transparent-gray flex flex-column items-center justify-center",on:{"click":function($event){$event.stopPropagation();return _vm.openVideoModal.apply(null, arguments)}}},[_c('div',{staticClass:"text-4xl",on:{"click":_vm.openVideoModal}},[_c('i',{staticClass:"fas fa-solid fa-play"})])])])]):_vm._e()],2)],1),_c('div',{staticClass:"w-full h-full mx-auto lg:hidden"},[_c('carousel',{staticClass:"mt-10",attrs:{"per-page":_vm.itemsToShow,"navigationEnabled":false,"mouse-drag":false,"mouseDrag":true}},[_vm._l((_vm.images),function(img,i){return _c('slide',{key:i},[_c('div',{staticClass:"w-full h-full cursor-pointer group mx-auto inline-flex items-center justify-center relative px-5"},[_c('img',{staticClass:"w-full h-auto",attrs:{"src":_vm.host + img,"alt":""}})])])}),(_vm.videoImage && _vm.videoImage.url && _vm.videoUrl)?_c('slide',{key:_vm.images.length},[_c('div',{staticClass:"w-full h-full cursor-pointer group mx-auto inline-flex items-center justify-center relative"},[_c('img',{staticClass:"w-full h-auto",attrs:{"src":_vm.host + _vm.videoImage.url,"alt":""}}),_c('div',{staticClass:"absolute h-full w-full bg-transparent-gray flex flex-column items-center justify-center"},[_c('div',{staticClass:"text-4xl",on:{"click":function($event){$event.stopPropagation();return _vm.openVideoModal.apply(null, arguments)}}},[_c('i',{staticClass:"fas fa-solid fa-play"})])])])]):_vm._e()],2)],1),(_vm.videoId)?[_c('you-tube-modal',{attrs:{"videoId":_vm.videoId}})]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }