import { regions } from './../src/utilities/constants';
import axios from "axios";

let axiosInstance = axios.create({
  baseURL: process.env.VUE_APP_BACKEND_API_URL,
});

const token = localStorage.getItem("fwi-token");
if (token) {
  axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
}

const anonymous_user = localStorage.getItem("anonymous_user_id");
if (anonymous_user) {
  axiosInstance.defaults.headers.common["AnonymousUserId"] = anonymous_user;
}

axiosInstance.defaults.headers.common['lang'] = localStorage.getItem('lang') ? localStorage.getItem('lang') : 'rs';
axiosInstance.defaults.headers.common['region'] = localStorage.getItem('region') ? localStorage.getItem('region') : regions.rs;


export default axiosInstance;
