export const en = {
  "footer": {
    "help": "Help",
    "contact-us": "Contact Us",
    "shipping-info": "Shipping info",
    "replacement-of-goods": "Replacement of goods",
    "gift-card": "Gift card",
    "size-guide": "Size guide",
    "my-account": "My account",
    "login-register": "Login / Register",
    "order-history": "Order history",
    "info": "Info",
    "privacy-policy": "Privacy policy",
    "protection-of-confidential-information": "Protection of confidential information",
    "refund": "Refund",
    "cookie-policy": "Cookie policy",
    "terms-of-use": "Terms of use",
    "follow-us": "Follow us",
    "complaints": "Complaints",

    "about-us": {
      "title":"Company information",
      "about-us": "About us",
      "full-name-of-legal-entity": "Full name of the legal entity",
      "fwi-address": "Savska 15 A/3, 11 000 Belgrade, Serbia",
      "activity-activity-code": "Industry and activity code",
      "wholesale-of-clothing-and-footwear": "4642-Wholesale of clothing and footwear",
      "identification-number": "Registration number",
      "tax-number": "Tax number",
      "web-address": "Web address",
      "support-title" : "Customer support information",
      "contact-phone": "Contact phone",
      "contact-email": "Contact e-mail",
    },
  },

  "cart": {
    "conversion-title" : "Statement of currency conversion",
    "statement-of-conversion": "All payments will be effected in Serbian currency – dinar (RSD). The amount your credit card account will be charged for is obtained through the conversion of the price in Euro into Serbian dinar according to the current exchange rate of the Serbian National Bank. When charging your credit card, the same amount is converted into your local currency according to the exchange rate of credit card associations. As a result of this conversion there is a possibility of a slight difference from the original price stated in our web site.",
    "tax-included-title" : "Tax statement",
    "tax-included": "Tax is included and there are no additional fees",
    "purchase-agreement-1": "I agree with the general",
    "purchase-agreement-2": "terms",
    "purchase-agreement-3": "of purchase",
    "checkout": "Checkout",
    "additional-info": "Additional info",
    "need-more-help": "Need more help",
    "add-to-cart": "Add to cart",
  },

  "globals": {
    "address": "Address",
    "returns": "Returns",
    "contact-us-by-email": "Contact us by email",
    "fwi-email": "fwwtest@fwi.com",
    "register": "Register",
    "amount": "Amount",
    "cookies-policy": "Our site uses cookies. By clicking on I agree or by continuing to use the site, you agree to the use of cookies. If you do not agree, you can change the settings in your browser.",
    "agree": "Agree",
    "cancel": "Cancel",
    "close": "Close",
  },

  "terms-of-use-modal": {
    "general-terms-of-purchase": "General conditions of online shopping",
    "paragraph-1": "By visiting or purchasing on the Internet store www.fwi.rs, you accept the Terms of Purchase listed below. Please read them carefully and get acquainted with the rules we apply. www.fwi.rs reserves the right to make changes and amendments to these Terms of Use without prior notice. ",
    "paragraph-2": "When you visit www.fwi.rs or send us an e-mail, you communicate with us electronically. Our communication with you will be via e-mail or notifications that we will publish on this site.",
    "paragraph-3": "For additional information regarding the order, as well as in case of complaint or cancellation of the order, you can contact the Customer Service by sending an e-mail to support@fwi.rs",
    "paragraph-4": "‟FWI by Ivana Korab“ is committed to protecting the privacy of all customers. ‟FWI by Ivana Korab” collects only basic data on customers / users necessary for business and informing users in accordance with good business practices and in order to provide quality service. All customer / user data is strictly kept and is only available to employees who need this data to do their job. All employees of the Internet store www.fwi.rs are responsible for respecting the principles of privacy protection.",
    "paragraph-5": "The content displayed on this site is the property of ‟FWI by Ivana Korab“ and may not be used without the prior consent of ‟FWI by Ivana Korab”.",
    "paragraph-6": "The online store www.fwi.rs is open from 00-24h, 7 days a week. In case of technical problems, the site may be unavailable for a short period of time, of which you will be notified.",
    "paragraph-7": "If you use www.fwi.rs, you are responsible for the maintenance and confidentiality of your account and passwords that you specified when registering on the site. Minors can use www.fwi.rs under the supervision of a parent or guardian. ‟FWI by Ivana Korab” reserves the right to refuse to provide services, cancel the order or cancel the order if it finds that the Terms of Purchase have been violated.",
    "paragraph-8": "‟FWI by Ivana Korab” reserves the right to change the information displayed on any page of the site at any time, without prior notice. Visitors to www.fwi.rs do not have the right to unilaterally change the Terms of Purchase. ",
    "paragraph-9": "www.fwi.rs is an online store that operates in accordance with the Law on Electronic Commerce and all other applicable laws and regulations in the Republic of Serbia.",
    "paragraph-10": "Failure to follow the rules defined here may result in non-execution of a payment transaction or a failed order.",
    "paragraph-11": "The General Terms and Conditions of Purchase prescribed here are an integral part of the Purchase Agreement to be concluded between the Seller and the Buyer.",

    "ordering": "Ordering",
    "paragraph-12": "To order products from the offer of the online store, you need to follow the following steps:",
    "paragraph-13": "- Familiarization with the General Terms of Purchase, information on the basic data of the Seller, acquaintance with the right to unilateral termination of the Agreement and the text of the form to be filled in upon unilateral termination of the Agreement, acquaintance with all rights and obligations of the Buyer",
    "paragraph-14": "- Registration",
    "paragraph-15": "- Find the product you want to buy",
    "paragraph-16": "- Enter data on the method of delivery, payment and place of delivery",
    "paragraph-17": "- Review, confirm and track purchase orders",

    "register": "Registration",
    "paragraph-18": "Registration is done only once, and each time, enter only your e-mail and password, which the system automatically recognizes. Registration is free and is done by selecting the option 'My Account', on the right below the text 'Are you a new user?' you select the option 'Register by e-mail'. You need to provide your e-mail address, name and surname, residential address to which we would deliver orders, phone number through which we would contact you to confirm the order, as well as the password you would use to access your account after registration. In order to register, you also need to read the privacy policy and privacy statement and check the box for consent to the same. After filling in the data, you will receive an automatic message to the e-mail address with a link to confirm registration. Clicking on the link in the e-mail opens a display on the page where you enter your e-mail address and password and access the account by clicking on the 'Login' button.",
    "paragraph-19": "If you want, you can log out of the site at any time by selecting the 'Log out' option. You can log in to the site again by selecting the 'My Account' option and providing your e-mail address and password.",

    "finding-a-product": "Find the product you want to buy",
    "paragraph-20": "Products are organized by categories. When you find the product you want to buy, click the 'Choose size' button and then the 'Add to cart' button.",

    "enter-dilivery-and-payment-info": "Enter data on the method of delivery, payment and place of delivery",
    "paragraph-21": "When you have finished placing the products in the basket and want to order them, select the “Basket” option where all the items you have selected are displayed. If you have changed your mind about one of the products, you can delete the product from the basket or change the quantity.",
    "paragraph-22": "Clicking on the 'Continue order' button opens options for choosing the method of payment, shipping method and selecting a delivery address, which may be different from the one you specified during registration.",
    "paragraph-23": "The data on the place of delivery are the data where you want us to send the shipment and therefore we ask you to provide all the information as accurately as possible so that the couriers who deliver the shipments can find you as easily as possible. In case of incorrectly entered data, your shipment may be returned and delivery may be slower.",
    "paragraph-24": "Your shipment will be sent by courier service. Make the payment by cash on delivery.",

    "review-confirm-track-order": "Review, confirm and track purchase orders",
    "paragraph-25": "Before you confirm the order, you can take another look at all its details. If you agree with it, select the 'Order now' option to create it. Before confirming the order, you need to read the General Terms and Conditions of Purchase and agree to it. An overview of the purchase order is automatically sent to the registration e-mail with the order confirmation.",
    "paragraph-26": "The seller will inform the buyer within 3 to 5 working days about the availability of the order and the possibility to deliver the goods.",
    "paragraph-27": "The seller will make sure to check the availability of the ordered item as soon as possible. If all the conditions for the purchase are provided, the Seller will send a confirmation of the purchase order to the e-mail address specified by the Buyer during registration, thereby accepting the offer to conclude the Contract. The contract is considered concluded when the Seller informs the Buyer about the status of the order and the time of delivery of the ordered items.",
    "paragraph-28": "If the Seller is not able to provide the ordered item, he will inform the buyer by e-mail that it is no longer available, and that the order has been canceled. In that case, the Agreement will not be concluded.",
    "paragraph-29": "Product prices are clearly displayed on the Seller's website. The seller reserves the right to change prices without prior notice, as well as the right to add or withdraw from the offer of any item at any time.",

    "payment": "Payment",
    "paragraph-30": "All prices on the site are shown with VAT included. www.fwi.rs supports the following payment methods:",
    "paragraph-31": "- cash on delivery - cash on delivery means paying the purchase order to the courier when picking up the shipment. Payment is made exclusively in cash.",
    "paragraph-32": "- payment cards - the customer can make a payment using Visa, Mastercard, Maestro or American Express.cards issued by a domestic bank.",
    "paragraph-33": "All payments will be made in the local currency of the Republic of Serbia - dinars (RSD).",
    "paragraph-34": "The middle exchange rate of the National Bank of Serbia is used for informative presentation of prices in other currencies. The amount for which your payment card will be charged will be expressed in your local currency through conversion into the same at the exchange rate used by card organizations, which we cannot know at the time of the transaction. As a result of this conversion, there is a possibility of a slight difference from the original price listed on our site.",

    "dilivery": "Delivery",
    "paragraph-35": "Shipments are delivered by an independent courier service and are possible anywhere in the world. Delivery costs are charged separately and they are stated when ordering goods. The amount of delivery costs depends on the weight of the shipment, as well as the place where it is sent. You pay the shipping costs together with the products. ",
    "paragraph-36": "The time for which the shipment will be delivered to you is up to 5 working days from the day of the confirmed order. The courier service delivers shipments in the period from 08:00 to 19:00.",
    "paragraph-37": "When delivering the goods to the Buyer, one copy of the retail invoice will be handed over to the Buyer, which at the same time represents the sales contract.",
    "paragraph-38": "The Buyer is responsible for the damage caused by the failure to take over the goods sent to him by the Seller in accordance with the contract.",
    "paragraph-39": "Damage means damage to the goods, as well as the costs incurred by the Seller due to the Buyer's failure to take over the goods, such as: costs of storage, repacking, return of goods and the like.",

    "replacement": "Replacement",
    "paragraph-40": "The buyer can request a replacement within 14 days of receiving the goods. Replacement of goods refers to the replacement of the size or replacement of the model. In case of replacement of the goods, together with the completed Replacement Form, it is delivered to the Seller, and in that case the costs of replacement are borne by the Buyer.",
    "paragraph-41": "The replacement form is available on the Seller's website www.fwi.rs and can be freely downloaded as a permanent record carrier.",
    "paragraph-42": "In case of replacement, the Buyer is obliged to return the goods without damage and traces of use. Otherwise, the Seller is not obliged to make a replacement.",
    "paragraph-43": "The item to be replaced must have the same or higher value than the item to be exchanged, at an additional cost.",
    "paragraph-44": "During periods of seasonal discounts, we only do refunds. You send us the goods together with the completed Statement of Withdrawal from the Agreement.",

    "return-goods-and-termination-contract": "Return of Goods - Termination of the Contract",
    "paragraph-45": "The Buyer may return the goods, ie terminate the Sales Agreement within 14 days from the date of receipt of the goods. In case of termination of the Agreement, the Buyer is obliged to do so on the Form for termination of the Agreement.",
    "paragraph-46": "The Termination Termination Form is available on the Seller's website www.fwi.rs and can be freely downloaded as a permanent record holder.",
    "paragraph-47": "Statement on termination of the Contract Produces legal effect from the day it is sent to the Seller and is considered timely if it is sent within 14 days from the date of receipt of the goods.",
    "paragraph-48": "In case of termination of the Contract, the Buyer is obliged to return the same goods without damage and signs that the goods have been used. Otherwise, the Seller is not obliged to return to the Buyer the amount received from the purchase price. The cost of returning the goods is borne by the buyer.",
    "paragraph-49": "In case of termination of the Agreement by the Buyer within the specified period, the Seller is obliged to return to the Buyer the amount of the purchase price within 14 days of receiving the unilateral statement of the Buyer on termination of the Agreement, provided that the Buyer returned the goods to the Seller without damage and signs that the goods have been used.",
    "paragraph-50": "In case of return of the item and refund of funds paid to one of the payment cards, FWI by Ivana Korab refunds via VISA, EC / MC and Maestro payment methods, which means that the bank will refund the funds to the cardholder's account at our request.",

    "complaint": "Complaint",
    "paragraph-51": "In addition to the complaint for the purpose of exercising the right to compliance, you can also file a complaint due to incorrectly calculated prices and other shortcomings.",
    "paragraph-52": "It is assumed that the goods are in accordance with the Agreement:",
    "paragraph-53": "if it corresponds to the description given by the Seller, and if it has the properties of the goods that the Seller showed to the Buyer as a sample or model",
    "paragraph-54": "if it has the properties necessary for the regular use of goods of the same type",
    "paragraph-55": "if it has the properties required for the special use for which the Buyer procures it",
    "paragraph-56": "if the quality corresponds to what is usual for goods of the same type and that the Buyer can reasonably expect public promises about the special properties of goods given by the Seller, especially if the promise was made through advertisements or on the packaging of goods.",
    "paragraph-57": "Complaints of non-compliance can be filed by the buyer within 2 years from the date of purchase of the product or receipt of goods, with the original invoice or any other proof of purchase of goods.",
    "paragraph-58": "If the non-compliance occurs within 6 months from the date of the transfer of risk to the consumer, it can be assumed that the non-compliance existed at the time of the transfer of risk to the consumer.",
    "paragraph-59": "The complaint will be answered within 8 (eight) days from the day of submitting the complaint.",
    "paragraph-60": "All costs necessary for the goods to comply with the contract, and especially the costs of labor, materials, collection and delivery, we bear as a trader.",
    "paragraph-61": "As a seller, we are obliged to issue a written confirmation to the buyer or confirm the receipt of the complaint electronically, ie state the number under which the complaint is registered in the records of received complaints.",
    "paragraph-62": "You can file a complaint:",
    "paragraph-63": " in writing, via the Complaint Form which can be downloaded as a permanent record available on the website www.fwi.rs, with simultaneous delivery of goods to the Seller which is the subject of sale and with the original copy of the invoice or any other proof of purchase.",
    "paragraph-64": "The deadline for resolving the complaint cannot be longer than 15 days from the day of submitting the complaint.",
    "paragraph-65": "Extension of the deadline for resolving complaints is possible only once.",
    "paragraph-66": "If the delivered goods do not comply with the Contract, the Buyer has the right to choose between the elimination of defects, replacement for the same or another item and refund.",
    "paragraph-67": "Other items to be replaced must be of the same or higher value, at an additional cost.",
    "paragraph-68": "If the elimination of non-conformity is not possible or if it represents a disproportionate burden for the Seller, the Buyer may request a price reduction or declare to terminate the Contract. Depending on the request of the Buyer, the Seller is obliged to return the entire amount of the purchase price or part of the price for which the exchange is accepted by the Seller, to the Buyer within 14 days.",
    "paragraph-69": "The Buyer also has the right to terminate the Contract if the Seller does not eliminate the non-conformity of the goods within the specified period.",
    "paragraph-70": "In any case, the Buyer has no right to terminate the Contract if the non-conformity of the goods is insignificant.",
    "paragraph-71": "The costs of returning the goods in the event of a complaint are borne by the Seller",
    "paragraph-72": "* The color shade of the item may vary depending on the quality of the monitor through which the Buyer selects the item to be purchased, so the Seller is not responsible for the differences between the displayed and actual color *",
    "paragraph-73": "In case of item complaint and refund of funds paid to one of the payment cards, FWI by Ivana Korab refunds via VISA, EC / MC and Maestro payment methods, which means that the bank will refund the card to the cardholder's account at our request.",
  },

  "shipping-info-modal": {
    "paragraph-1": "Shipments are delivered by an independent courier service and are possible anywhere in the world. Delivery costs are charged separately and they are stated when ordering goods. The amount of delivery costs depends on the weight of the shipment, as well as the place where it is sent. You pay the shipping costs together with the products. ",
    "paragraph-2": "The time for which the shipment will be delivered to you is up to 5 working days from the day of the confirmed order. The courier service delivers shipments in the period from 08:00 to 19:00.",
    "paragraph-3": "When delivering the goods to the Buyer, one copy of the retail invoice will be handed over to the Buyer, which at the same time represents the sales contract.",
    "paragraph-4": "The Buyer is responsible for the damage caused by the failure to take over the goods sent to him by the Seller in accordance with the contract.",
    "paragraph-5": "Damage means damage to the goods, as well as the costs incurred by the Seller due to the Buyer's failure to take over the goods, such as: costs of storage, repacking, return of goods and the like.",
  },

  "complaints-modal": {
    "paragraph-1": "In addition to the complaint for the purpose of exercising the right to compliance, you can also file a complaint due to incorrectly calculated prices and other shortcomings.",
    "paragraph-2": "It is assumed that the goods are in accordance with the Agreement:",
    "paragraph-3": "if it corresponds to the description given by the Seller, and if it has the properties of the goods that the Seller showed to the Buyer as a sample or model",
    "paragraph-4": "if it has the properties necessary for the regular use of goods of the same type",
    "paragraph-": "if it has the properties required for the special use for which the Buyer procures it",
    "paragraph-6": "if the quality corresponds to what is usual for goods of the same type and that the Buyer can reasonably expect public promises about the special properties of goods given by the Seller, especially if the promise was made through advertisements or on the packaging of goods.",
    "paragraph-7": "Complaints of non-compliance can be filed by the buyer within 2 years from the date of purchase of the product or receipt of goods, with the original invoice or any other proof of purchase of goods.",
    "paragraph-8": "If the non-compliance occurs within 6 months from the date of the transfer of risk to the consumer, it can be assumed that the non-compliance existed at the time of the transfer of risk to the consumer.",
    "paragraph-9": "The complaint will be answered within 8 (eight) days from the day of submitting the complaint.",
    "paragraph-10": "All costs necessary for the goods to comply with the contract, and especially the costs of labor, materials, collection and delivery, we bear as a trader.",
    "paragraph-11": "As a seller, we are obliged to issue a written confirmation to the buyer or confirm the receipt of the complaint electronically, ie state the number under which the complaint is registered in the records of received complaints.",
    "paragraph-12": "You can file a complaint:",
    "paragraph-13": " in writing, via the Complaint Form which can be downloaded as a permanent record available on the website www.fwi.rs, with simultaneous delivery of goods to the Seller which is the subject of sale and with the original copy of the invoice or any other proof of purchase.",
    "paragraph-14": "The deadline for resolving the complaint cannot be longer than 15 days from the day of submitting the complaint.",
    "paragraph-15": "Extension of the deadline for resolving complaints is possible only once.",
    "paragraph-16": "If the delivered goods do not comply with the Contract, the Buyer has the right to choose between the elimination of defects, replacement for the same or another item and refund.",
    "paragraph-17": "Other items to be replaced must be of the same or higher value, at an additional cost.",
    "paragraph-18": "If the elimination of non-conformity is not possible or if it represents a disproportionate burden for the Seller, the Buyer may request a price reduction or declare to terminate the Contract. Depending on the request of the Buyer, the Seller is obliged to return the entire amount of the purchase price or part of the price for which the exchange is accepted by the Seller, to the Buyer within 14 days.",
    "paragraph-19": "The Buyer also has the right to terminate the Contract if the Seller does not eliminate the non-conformity of the goods within the specified period.",
    "paragraph-20": "In any case, the Buyer has no right to terminate the Contract if the non-conformity of the goods is insignificant.",
    "paragraph-21": "The costs of returning the goods in the event of a complaint are borne by the Seller",
    "paragraph-22": "* The color shade of the item may vary depending on the quality of the monitor through which the Buyer selects the item to be purchased, so the Seller is not responsible for the differences between the displayed and actual color *",
    "paragraph-23": "In case of item complaint and refund of funds paid to one of the payment cards, FWI by Ivana Korab refunds via VISA, EC / MC and Maestro payment methods, which means that the bank will refund the card to the cardholder's account at our request.",
  },
  "goods-replacement-modal": {
    "paragraph-1": "The buyer can request a replacement within 14 days of receiving the goods. Replacement of goods refers to the replacement of the size or replacement of the model. In case of replacement of the goods, together with the completed" ,
    "paragraph-1-link" : "Replacement Form",
    "paragraph-1-1" : ", it is delivered to the Seller, and in that case the costs of replacement are borne by the Buyer.",
    "paragraph-2": "The replacement form is available on the Seller's website www.fwi.rs and can be freely downloaded as a permanent record carrier.",
    "paragraph-3": "In case of replacement, the Buyer is obliged to return the goods without damage and traces of use. Otherwise, the Seller is not obliged to make a replacement.",
    "paragraph-4": "The item to be replaced must have the same or higher value than the item to be exchanged, at an additional cost.",
    "paragraph-5": "During periods of seasonal discounts, we only do refunds. You send us the goods together with the completed",
    "paragraph-5-link": "Statement of Withdrawal from the Agreement",
  },
  "prvacy-policy-modal": {
    "title" : 'Customer data privacy policy',
    "paragraph-1": "On behalf of FWI Ltd we are committed to protecting the privacy of all our customers. We collect only the necessary, basic data on customers / users and data necessary for business and informing users in accordance with good business practices and in order to provide quality service. We give customers a choice including the ability to decide whether or not to delete them from the mailing lists used for marketing campaigns. All user / customer data is strictly kept and is only available to employees who need this data to do their job. All FWI Ltd employees (and business partners) are responsible for adhering to the principles of privacy.",
  },
  "protection-of-confidential-info-modal": {
    "title" : 'Transaction data privacy policy',
    "paragraph-1": "When entering payment card data, confidential information is transmitted via the public network in a protected (encrypted) form using SSL protocols and PKI systems, as currently the most modern cryptographic technology. ",
    "paragraph-2": "The security of data during the purchase is guaranteed by the payment card processor, Banca Intesa ad Belgrade, so the complete billing process is performed on the bank's website. At no time is payment card information available to our system. ",
  },

  "refund-info-modal": {
    "title" : "Refund policy",
    "paragraph-1": "In the case of return of goods and return of funds to the customer who has previously paid for one of the payment cards, in part or in full, regardless of the reason for return, FWI Ltd. is obliged to make a refund exclusively through VISA, EC / MC and Maestro payment methods, which means that the bank will, at the request of the seller, make a refund to the cardholder's account."
  },

  "profile": {
    "info": "Info",
    "likes": "Likes",
    "purchases": "Purchases",
    "wallet": "Wallet",
    "logout": "Sign out",
    "info-tab": {
      'title': 'Profile information',
      'reset-password': 'Reset password',
       "email-reset-info": "Please contact us in order to reset your email",
      'deactivate-account' : 'Deactivate account',
      'confirm-changes' : 'Apply changes'
    },
    "likes-tab":{
      "no-products": "You have no liked products"
    },
    "change-password": {
      "old-password": "Existing password",
      "new-password" : "New password",
      "repeat-password" : "Repeat new password"
    }
  },

  "wallet": {
    "available-funds": "Available funds",
    "pay": "Pay",
  },
  "countries_placeholder": 'Country',
  "country-not-found": 'No results found',
  "countries" : [
    {id:'Albanija', name : "Albania"},
    {id:'Alžir', name : "Algeria"},
    {id:'Američka Samoa', name : "American Samoa"},
    {id:'Andora', name : "Andorra"},
    {id:'Angola', name : "Angola"},
    {id:'Angvila', name : "Anguilla"},
    {id:'Antigva', name : "Antigua"},
    {id:'Argentina', name : "Argentina"},
    {id:'Aruba', name : "Aruba"},
    {id:'Australija', name : "Australia"},
    {id:'Austrija', name : "Austria"},
    {id:'Avganistan', name : "Afghanistan"},
    {id:'Azerbejdžan', name : "Azerbaijan"},
    {id:'Bahami', name : "Bahamas"},
    {id:'Bahrein', name : "Bahrain"},
    {id:'Bangladeš', name : "Bangladesh"},
    {id:'Barbados', name : "Barbados"},
    {id:'Belgija', name : "Belgium"},
    {id:'Belize', name : "Belize"},
    {id:'Belorusija', name : "Belarus"},
    {id:'Benin', name : "Benin"},
    {id:'Bermudska ostrva', name : "Bermuda"},
    {id:'Bocvana', name : "Botswana"},
    {id:'Bolivija', name : "Bolivia"},
    {id:'Boner', name : "Bonaire"},
    {id:'Bosna i Hercegovina', name : "Bosnia and Herzegovina"},
    {id:'Brazil', name : "Brazil"},
    {id:'Brunej', name : "Brunei"},
    {id:'Bugarska', name : "Bulgaria"},
    {id:'Burkina Faso', name : "Burkina Faso"},
    {id:'Burundi', name : "Burundi"},
    {id:'Butan', name : "Bhutan"},
    {id:'Centralnoafrička Republika', name : "Central African Republic"},
    {id:'Crna Gora', name : "Montenegro"},
    {id:'Čad', name : "Chad"},
    {id:'Češka Republika', name : "Czech Republic, The"},
    {id:'Čile', name : "Chile"},
    {id:'Danska', name : "Denmark"},
    {id:'Devičanska ostrva (Američka)', name : "Virgin Islands (US)"},
    {id:'Devičanska ostrva (Britanska)', name : "Virgin Islands (British)"},
    {id:'Dominika', name : "Dominica"},
    {id:'Dominikanska Republika', name : "Dominican Republic"},
    {id:'Džerzi', name : "Jersey"},
    {id:'Džibuti', name : "Djibouti"},
    {id:'Egipat', name : "Egypt"},
    {id:'Ekvador', name : "Ecuador"},
    {id:'El Salvador', name : "El Salvador"},
    {id:'Eritreja', name : "Eritrea"},
    {id:'Estonija', name : "Estonia"},
    {id:'Etiopija', name : "Ethiopia"},
    {id:'Farska ostrva', name : "Faroe Islands"},
    {id:'Fidži', name : "Fiji"},
    {id:'Filipini', name : "The Philippines"},
    {id:'Finska', name : "Finland"},
    {id:'Folklandska ostrva', name : "Falkland Islands"},
    {id:'Francuska', name : "France"},
    {id:'Francuska Gvajana', name : "French Guyana"},
    {id:'Gabon', name : "Gabon"},
    {id:'Gambija', name : "Gambia"},
    {id:'Gana', name : "Ghana"},
    {id:'Gernzi', name : "Guernsey"},
    {id:'Gibraltar', name : "Gibraltar"},
    {id:'Grčka', name : "Greece"},
    {id:'Grenada', name : "Grenada"},
    {id:'Grenland', name : "Greenland"},
    {id:'Gruzija', name : "Georgia"},
    {id:'Guam', name : "Guam"},
    {id:'Gvadalupe', name : "Guadeloupe"},
    {id:'Gvajana (Britanska)', name : "Guyana (British)"},
    {id:'Gvatemala', name : "Guatemala"},
    {id:'Gvineja - Bisao', name : "Guinea-Bissau"},
    {id:'Gvineja - Ekvatorijalna', name : "Guinea-Equatorial"},
    {id:'Gvineja Republika', name : "Guinea Republic"},
    {id:'Haiti', name : "Haiti"},
    {id:'Holandija', name : "Netherlands, The"},
    {id:'Holandski Antili', name : "Netherlands Antilles"},
    {id:'Honduras', name : "Honduras"},
    {id:'Hong Kong', name : "Hong Kong SAR China"},
    {id:'Hrvatska', name : "Croatia"},
    {id:'Indija', name : "India"},
    {id:'Indonezija', name : "Indonesia"},
    {id:'Irak', name : "Iraq"},
    {id:'Iran', name : "Iran, Islamic Rep. of"},
    {id:'Irska', name : "Ireland, Rep. of"},
    {id:'Island', name : "Iceland"},
    {id:'Istočni Timor', name : "Timor-Leste"},
    {id:'Italija', name : "Italy"},
    {id:'Izrael', name : "Israel"},
    {id:'Jamajka', name : "Jamaica"},
    {id:'Japan', name : "Japan"},
    {id:'Jemen', name : "Yemen"},
    {id:'Jermenija', name : "Armenia"},
    {id:'Jordan', name : "Jordan"},
    {id:'Južna Afrika', name : "South Africa"},
    {id:'Južna Koreja', name : "Korea, Rep. of (South)"},
    {id:'Južni Sudan', name : "South Sudan"},
    {id:'Kajmanska ostrva', name : "Cayman Islands"},
    {id:'Kambodža', name : "Cambodia"},
    {id:'Kamerun', name : "Cameroon"},
    {id:'Kanada', name : "Canada"},
    {id:'Kanarska ostrva', name : "Canary Islands, The"},
    {id:'Katar', name : "Qatar"},
    {id:'Kazahstan', name : "Kazakhstan"},
    {id:'Kenija', name : "Kenya"},
    {id:'Kina', name : "China, People’s Republic"},
    {id:'Kipar', name : "Cyprus"},
    {id:'Kirgistan', name : "Kyrgyzstan"},
    {id:'Kiribati', name : "Kiribati"},
    {id:'Kolumbija', name : "Colombia"},
    {id:'Komori', name : "Comoros"},
    {id:'Kongo', name : "Congo"},
    {id:'Kongo, Demokratska Republika', name : "Congo, Democratic Rep. of"},
    {id:'Kostarika', name : "Costa Rica"},
    {id:'Kuba', name : "Cuba"},
    {id:'Kukova ostrva', name : "Cook Islands"},
    {id:'Kurasao', name : "Curacao"},
    {id:'Kuvajt', name : "Kuwait"},
    {id:'Laos', name : "Lao P.D.R."},
    {id:'Letonija', name : "Latvia"},
    {id:'Lesoto', name : "Lesotho"},
    {id:'Liberija', name : "Liberia"},
    {id:'Liban', name : "Lebanon"},
    {id:'Libija', name : "Libya"},
    {id:'Lihtenštajn', name : "Liechtenstein"},
    {id:'Litvanija', name : "Lithuania"},
    {id:'Luksemburg', name : "Luxembourg"},
    {id:'Madagaskar', name : "Madagascar"},
    {id:'Mađarska', name : "Hungary"},
    {id:'Majote', name : "Mayotte"},
    {id:'Makao', name : "Macau SAR China"},
    {id:'Malavi', name : "Malawi"},
    {id:'Maldivi', name : "Maldives"},
    {id:'Malezija', name : "Malaysia"},
    {id:'Mali', name : "Mali"},
    {id:'Malta', name : "Malta"},
    {id:'Maroko', name : "Morocco"},
    {id:'Maršalska ostrva', name : "Marshall Islands"},
    {id:'Martinik', name : "Martinique"},
    {id:'Mauricijus', name : "Mauritius"},
    {id:'Mauritanija', name : "Mauritania"},
    {id:'Meksiko', name : "Mexico"},
    {id:'Mijanmar', name : "Myanmar"},
    {id:'Mikronezija', name : "Micronesia, Fed. States of"},
    {id:'Moldavija', name : "Moldova, Rep. of"},
    {id:'Monako', name : "Monaco"},
    {id:'Mongolija', name : "Mongolia"},
    {id:'Monserat', name : "Montserrat"},
    {id:'Mozambik', name : "Mozambique"},
    {id:'Namibija', name : "Namibia"},
    {id:'Nauru', name : "Nauru, Rep. of"},
    {id:'Nemačka', name : "Germany"},
    {id:'Nepal', name : "Nepal"},
    {id:'Nevis', name : "Nevis"},
    {id:'Niger', name : "Niger"},
    {id:'Nigerija', name : "Nigeria"},
    {id:'Nikaragva', name : "Nicaragua"},
    {id:'Niue', name : "Niue"},
    {id:'Norveška', name : "Norway"},
    {id:'Nova Kaledonija', name : "New Caledonia"},
    {id:'Novi Zeland', name : "New Zealand"},
    {id:'Obala Slonovače', name : "Cote d’Ivoire"},
    {id:'Oman', name : "Oman"},
    {id:'Pakistan', name : "Pakistan"},
    {id:'Palau', name : "Palau"},
    {id:'Panama', name : "Panama"},
    {id:'Papua Nova Gvineja', name : "Papua New Guinea"},
    {id:'Paragvaj', name : "Paraguay"},
    {id:'Peru', name : "Peru"},
    {id:'Poljska', name : "Poland"},
    {id:'Portugalija', name : "Portugal"},
    {id:'Portoriko', name : "Puerto Rico"},
    {id:'Reunion ostrva', name : "Reunion, Island of"},
    {id:'Ruanda', name : "Rwanda"},
    {id:'Rumunija', name : "Romania"},
    {id:'Ruska Federacija', name : "Russian Federation, The"},
    {id:'Samoa', name : "Samoa"},
    {id:'San Marino', name : "San Marino"},
    {id:'Saudijska Arabija', name : "Saudi Arabia"},
    {id:'Sejšeli', name : "Seychelles"},
    {id:'Senegal', name : "Senegal"},
    {id:'Severna Koreja', name : "Korea, D.P.R. of (North)"},
    {id:'Severna Makedonija', name : "North Macedonia"},
    {id:'Severna Marijanska ostrva (Saipan)', name : "Northern Mariana Islands"},
    {id:'Sijera Leone', name : "Sierra Leone"},
    {id:'Singapur', name : "Singapore"},
    {id:'Sirija', name : "Syria"},
    {id:'Sjedinjene Američke Države', name : "United States of America"},
    {id:'Slovačka', name : "Slovakia"},
    {id:'Slovenija', name : "Slovenia"},
    {id:'Solomonska ostrva', name : "Solomon Islands"},
    {id:'Somalija', name : "Somalia"},
    {id:'Somalilend (Severna Somalija)', name : "Somaliland, Rep. (N. Somalia)"},
    {id:'Srbija', name : "Serbia"},
    {id:'Svazilend', name : "Eswatini"},
    {id:'Sveti Bartolomej', name : "St. Barthelemy"},
    {id:'Sveti Eustahije', name : "St. Eustatius"},
    {id:'Sveti Kits', name : "St. Kitts"},
    {id:'Sveta Lucija', name : "St. Lucia"},
    {id:'Sveti Martin', name : "St. Maarten"},
    {id:'Sveti Toma i Princip', name : "Sao Tome & Principe"},
    {id:'Sveta Jelena', name : "St. Helena"},
    {id:'Sveti Vinsent', name : "St. Vincent"},
    {id:'Sudan', name : "Sudan"},
    {id:'Surinam', name : "Suriname"},
    {id:'Španija', name : "Spain"},
    {id:'Šri Lanka', name : "Sri Lanka"},
    {id:'Švedska', name : "Sweden"},
    {id:'Švajcarska', name : "Switzerland"},
    {id:'Tahiti', name : "Tahiti"},
    {id:'Tajvan', name : "Taiwan"},
    {id:'Tadžikistan', name : "Tajikistan"},
    {id:'Tanzanija', name : "Tanzania"},
    {id:'Tajland', name : "Thailand"},
    {id:'Togo', name : "Togo"},
    {id:'Tonga', name : "Tonga"},
    {id:'Trinidad i Tobago', name : "Trinidad and Tobago"},
    {id:'Tunis', name : "Tunisia"},
    {id:'Turkmenistan', name : "Turkmenistan"},
    {id:'Turska', name : "Turkey"},
    {id:'Turks i Kaikos ostrva', name : "Turks and Caicos Islands"},
    {id:'Tuvalu', name : "Tuvalu"},
    {id:'Uganda', name : "Uganda"},
    {id:'Ukrajina', name : "Ukraine"},
    {id:'Ujedinjeni Arapski Emirati', name : "United Arab Emirates"},
    {id:'Urugvaj', name : "Uruguay"},
    {id:'Uzbekistan', name : "Uzbekistan"},
    {id:'Vanuatu', name : "Vanuatu"},
    {id:'Vatikan', name : "Vatican City"},
    {id:'Velika Britanija', name : "United Kingdom"},
    {id:'Venecuela', name : "Venezuela"},
    {id:'Vijetnam', name : "Vietnam"},
    {id:'Zambija', name : "Zambia"},
    {id:'Zelenortska ostrva', name : "Cape Verde"},
    {id:'Zimbabve', name : "Zimbabwe"},
  ],
  'warehouse-error-modal':{
    'title' : 'Unfortunately not all of the items in your cart are available'
  },
  'review-and-buy':{
    'title' : "Review your info",
    'unexpected-error' : 'Unfortunately an unexpected error has occurred',
    'payment-method' : 'Payment method',
    'wallet-payment' : 'Wallet',
    'card-payment' : 'Card',
    'cash-payment' : 'Cash',
    'buy': 'Finalize order',
    'total-cart' : 'Subtotal',
    'delivery-price' : 'Shipping price',
    'discount-coupon' : 'Discount code',
    'apply-discount' : 'Apply',
    'discount' : 'Discount',
    'total' : 'Total',
    'back': 'Back to shipping'
  },

  "shop": {
    "sort-by": "Sort by",
    "popularity": "Popularity",
    "price-1": "Price",
    "price-2": "Price",
    "filters": "Filteri",
    "choose-filter": "Izaberi filter",
    "clear-all": "Clear all",
    "products": "Products",
    "size": "Size",
    "color": "Color",
    "of": "Of",
    "items": "Items",
  },

  "contact-information": {
    "contact-information": "Contact information",
    "already-have-an-account": "Already have an account?",
    "log-in": "Log in",
    "email": "Email",
    "keep-me-up-to-date": "Keep me up to date on news and exclusive offers",
    "first-name": "First name",
    "last-name": "Last name",
    "address": "Address",
    "apt-suite": "Apt. Suite",
    "postal-code": "Postal code",
    "city": "City",
    "country": "Country",
    "state-providence": "State providence",
    "phone-number": "Phone number",
    "shipping-address-is-the-same-as-invoice-address": "Shipping address is the same as invoice address",
    "i-want-shippment-to-another-address": "I want shippment to another address",
    "return-to-shopping-bag": "Return to shopping bag",
    "please-fill-in-all-the-fields": "Please fill in all the fields",
    "proceed-to-shipping": "Proceed to shipping",
    "password": "Password",
    "repeat-password": "Repeat password",
  },

  "shipping": {
    "shipping-company": "Shipping company",
    "shipping-type": "Shipping type",
    "shipping-amount": "Shipping price",
    "payment-type": "Payment method",
    "shipping-address" : "Shipping address",
    "shipping-info" : "The shipping company will contact you about the date and time of delivery. Delivery usually takes from 2 to 5 days.",
    "back" : "Return to information",
    "continue" : "Review & Finish"
  },

  "purchases": {
    "purchase-code": "Order confirmation code",
    "status": "Status",
    "no-purchases": "There are no purchases at the moment",
    "discount": "Discount"
  },

  "product": {
    "name": "Name",
    "color": "Color",
    "size" : 'Size',
    "quantity": "Quantity",
  },

  "errors": {
    "passwords-must-match": "Passwords must match",
    "required-field": "Required field",
    "you-must-fill-required-fields": "You must fill required fields",
  },
  "session-expired-message": "Your session has expired. Please login again.",
  'deactivate-account-modal': {
    'heading' : 'We are sad to see you go!',
    'text' : 'Are you sure you would like to deactivate your profile?' ,
    'yes' : "Deactivate account",
    'no' : 'Cancel'
  },
  'reset-password-modal':{
    'email': 'Email',
    'invalid': 'Please input your email',
    'confirm' : 'Confirm'
  },
  'search-modal':{
    'placeholder': 'Try searching by product or category name',
    'categories' : 'Categories',
    'products' : 'Products',
    'no-results' : 'No results found. Try searching for something else.'
  },
  'thank-you-modal':{
    'heading-1': 'Thank you!',
    'heading-2' : 'You have successfully made a purchase',
    'confirmation-code' : 'Confirmation code',
    'email-sent-to': 'Invoice has been sent to: ',
    'check-order': 'You can check the status of your order on your profile page',
    'continue-shopping' : 'Continue shopping'
  },
  'product-details' : {
    'description' : 'Details',
    'materials' : 'Materials',
    'out-of-stock' : 'Out of stock',
    'select-a-color': 'Please select a color',
  },
  'similar-products' : {
    'title' : 'Similar products'
  },
  'popular-products' : {
    'title' : 'Popular products'
  },
  'shop-navigation' : {
    'to-shop' : 'Back to shop',
    'previous' : 'Previous item',
    'next' : 'Next item'
  },
  'home': {
    'welcome' : 'Welcome',
    'new-arrivals' : 'New arrivals',
    'browse-all-products' : 'See all products',
    'favourites' : 'FWI favourites',
    'shop-category' : 'Shop by category',
    'new-product' : 'New product',
    'fashion-health' : 'Fashion & health'
  },
  'side-cart': {
    'summary' : 'Order summary',
    'subtotal' : 'Subtotal',
    'discount' : 'Discount',
    'shipping' : 'Shipping',
    'total' : 'Total'
  },
  'about':{
    'title' : 'About us',
    'paragraph-1': "Do you have a sporty spirit and you love fashion and follow fashion trends? Whether your style is already recognizable or you are happy to adapt it to the upcoming trends from the world of fashion, the women's clothing line of the new brand FWI will completely knock you off your feet.",
    'paragraph-2': "While performing urgent tasks in the city, mingle inside the mall or spend the evening hours enjoying going out, pieces from the FWI collection are there to keep up with you.",
    'paragraph-3':"Inspired by the fashion and lifestyle typical of Los Angeles, the line of FWI sports equipment of our successful model Ivana Korab will make your every active day be accompanied by, above all, comfort, but at the same time elegance and conciseness.",
    'paragraph-4':"The main feature of each item of clothing from the FWI collection is the emphasis on the personal touch of each lady individually in a modern way. Multi-purpose pieces made of quality and carefully selected materials will fit into the combinations you wear from morning to night.",
    'paragraph-5':"The exclusive interior of the store itself, as well as the always smiling faces of our girls, create an atmosphere that will permanently attract you. Whether you want to please yourself or someone you love, don't miss a visit to our store."
  },
  'register-banner': {
    'heading' : 'Become a part of FWI tribe',
    'button' : 'Register',
  },
  'blog':{
    'no-posts': 'Currently there are no blog posts. Come back later',
    'back-to': 'Back to blog',
    'similar-posts': 'Similar posts',
  },
  'continue-shopping':{
    'paragraph-1': 'Want more?',
    'button': 'Continue shopping',
  }
};