
import { Vue, Component } from "vue-property-decorator";
import AppVideoBanner from "@/components/AppVideoBanner.vue";
import AppRecommendedProductsSlider from "@/components/AppRecommendedProductsSlider.vue";
import AppRegisterBanner from "@/components/AppRegisterBanner.vue";
import { ProductStore } from "../store/product";
@Component({
  components: {
    AppVideoBanner,
    AppRecommendedProductsSlider,
    AppRegisterBanner,
  },
})
export default class About extends Vue {
  get popularProducts() {
    return ProductStore.hotStuff;
  }

  mounted() {
    ProductStore.fetchHotStuffProducts();
  }
}
