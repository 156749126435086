import { Locales } from "./types/i18n";
import axios from "../config/axios";
import { Regions } from "@/types/region.types";


import {en} from "./locales/locale-en";
import {sr} from "./locales/locale-sr";

export const messages = {
  [Locales.EN]: en,
  [Locales.SR]: sr
};

export const defaultLocale = axios.defaults.headers.common["region"] === Regions.RS ? Locales.SR : Locales.EN;