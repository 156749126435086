
import { Component, Vue } from "vue-property-decorator";
import { NavigationStore } from "@/store/navigation";
import { modal } from "@/store/modal";
@Component({})
export default class AppSearchModal extends Vue {
  NavigationStore: any = NavigationStore;
  searchString: string | null = "";
  Modal: any = modal;
  get isSearchEmpty() {
    return (
      NavigationStore.searchResults &&
      this.categories.length == 0 &&
      this.products.length == 0
    );
  }
  get categories() {
    return NavigationStore.searchResults &&
      NavigationStore.searchResults.categories
      ? NavigationStore.searchResults.categories
      : [];
  }
  get products() {
    return NavigationStore.searchResults &&
      NavigationStore.searchResults.products
      ? NavigationStore.searchResults.products
      : [];
  }

  get searchWidth() {
    if (window.innerWidth < 600) return "97%";
    if (window.innerWidth < 900) return "80%";
    if (window.innerWidth < 1025) return "60%";
    if (window.innerWidth >= 1025) return "55%";
  }

  public setCategory(category: any) {
    if (this.$route.path == "/shop") {
      const queryParams = this.$route.query ? this.$route.query : {};
      let category_query_param =
        this.$route.query && this.$route.query.category
          ? this.$route.query.category
          : "";
      const add_category =
        category_query_param != "" ? "," + category.id : category.id;
      if (
        !category_query_param.includes(category.id) &&
        !category_query_param.includes("," + category.id)
      ) {
        this.$router
          .push({
            path: "/shop",
            query: {
              ...queryParams,
              category: category_query_param + add_category,
            },
          })
          .catch(() => {});
      }
    } else {
      this.$router
        .push({
          path: "/shop",
          query: { category: category.id },
        })
        .catch(() => {});
    }

    this.searchString = null;
    NavigationStore.setSearchResults(null);
    this.Modal.set({ name: "searchModal", show: false });
  }
  public selectProduct(product: any) {
    this.$router.push(`/product-details/${product.id}`).catch(() => {});
    this.searchString = null;
    NavigationStore.setSearchResults(null);
    this.Modal.set({ name: "searchModal", show: false });
  }
  public async search() {
    await NavigationStore.searchSite(
      this.searchString ? this.searchString.trim() : null
    );
  }
}
