
import { Component, Vue } from "vue-property-decorator";
import FacebookIconWhite from "../../icons/FacebookIconWhite.vue";
import TwitterIconWhite from "../../icons/TwitterIconWhite.vue";
import YoutubeIconWhite from "../../icons/YoutubeIconWhite.vue";
import InstagramIconWhite from "../../icons/InstagramIconWhite.vue";
import LogoFooter from "../../icons/LogoFooter.vue";
import LogoFooterFull from "../../icons/LogoFooterFull.vue";
import { NavigationStore } from "@/store/navigation";
import { CustomerStore } from "@/store/customer";
import { modal } from "@/store/modal";
import { instagramAddress, facebookAddress } from "../../utilities/constants";
//@ts-ignore
import MasterCard from "../../assets/backgrounds/payment_cards/master-card.png";
//@ts-ignore
import MaestroCard from "../../assets/backgrounds/payment_cards/maestro-card.png";
//@ts-ignore
import Visa from "../../assets/backgrounds/payment_cards/visa.png";
//@ts-ignore
import SecureCode from "../../assets/backgrounds/payment_cards/mc_idcheck.svg";
//@ts-ignore
import DinaCard from "../../assets/backgrounds/payment_cards/DinaCard.jpg";
//@ts-ignore
import VerByVisa from "../../assets/backgrounds/payment_cards/ver-by-visa.jpg";
//@ts-ignore
import Intesa from "../../assets/backgrounds/payment_cards/logo-intesa.png";
//@ts-ignore
import AmericanExpress from "@/assets/backgrounds/payment_cards/AMERICAN EXPRESS.png";
@Component({
  components: {
    FacebookIconWhite,
    TwitterIconWhite,
    YoutubeIconWhite,
    InstagramIconWhite,
    LogoFooter,
    LogoFooterFull,
  },
})
export default class AppFooter extends Vue {
  NavigationStore: any = NavigationStore;
  CustomerStore: any = CustomerStore;
  modal: any = modal;
  paymentCards: any = [
    {
      src: MasterCard,
      alt: "MasterCard",
    },
    { src: MaestroCard, alt: "MaestroCard" },
    {
      src: Visa,
      alt: "Visa",
    },
    {
      src: AmericanExpress,
      alt: "AmericanExpress",
    },
    { src: DinaCard, alt: "DinaCard" },
  ];
  paymentPrograms: any = [
    {
      src: SecureCode,
      alt: "SecureCode",
      link: "http://www.mastercard.com/rs/consumer/credit-cards.html",
      target: "_blank",
    },
    {
      src: VerByVisa,
      alt: "VerifiedByVisa",
      link:
        "https://rs.visa.com/pay-with-visa/security-and-assistance/protected-everywhere.html",
      target: "_blank",
    },
  ];
  bancLogo: any = {
    src: Intesa,
    alt: "BancaIntesa",
    link: "https://www.bancaintesa.rs",
    target: "_blank",
  };
  get isCustomerLoggedIn() {
    return CustomerStore.token;
  }

  public redirectToInstagram() {
    window.open(instagramAddress);
  }
  public showLogin() {
    window.scrollTo(0, 0);
    NavigationStore.toggleLogin();
    NavigationStore.toggleDesktopNavigation(false);
    NavigationStore.toggleMobileNavigation(false);
    if (this.isCustomerLoggedIn) this.$router.push("/profile").catch(() => {});
  }
}
