
import { Component, Vue } from "vue-property-decorator";
import { Locales } from "@/types/i18n";
import { modal } from "@/store/modal";
@Component({})
export default class GoodsReplacementModal extends Vue {
  modal: any = modal;

  downloadFile(path: string) {
    window.open(path, "_blank");
  }
  downloadReplacementForm() {
    const formPathRs = "./Obrazac_za_zamenu_robe.docx";
    const formPathEn = "./Replacement_form.docx";
    let path = formPathRs;
    if (this.$i18n.locale == Locales.EN) {
      path = formPathEn;
    }
    this.downloadFile(path);
  }
  downloadWithdrawalForm() {
    const formPathRs = "./Izjava_o_odustajanju_od_ugovora.docx";
    const formPathEn = "./Statement_of_Withdrawal.docx";
    let path = formPathRs;
    if (this.$i18n.locale == Locales.EN) {
      path = formPathEn;
    }
    this.downloadFile(path);
  }
}
