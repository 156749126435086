
import { Vue, Component, Watch } from "vue-property-decorator";
import AppRecommendedProductsSlider from "@/components/AppRecommendedProductsSlider.vue";
import AppRegisterBanner from "@/components/AppRegisterBanner.vue";
import { BlogStore } from "../store/blog";
import { ProductStore } from "../store/product";
import { NavigationStore } from "../store/navigation";
@Component({ components: { AppRecommendedProductsSlider, AppRegisterBanner } })
export default class Blog extends Vue {
  BlogStore: any = BlogStore;
  ProductStore: any = ProductStore;
  NavigationStore: any = NavigationStore;
  private imageURLprefix: string | undefined =
    process.env.VUE_APP_BACKEND_IMAGE_PREFIX;

  get popularProducts() {
    return ProductStore.hotStuff;
  }
  get blogPost() {
    return BlogStore.selectedBlogPost;
  }
  get similarPosts() {
    return BlogStore.similarBlogPosts;
  }
  get routeObject() {
    if (this.$route && this.$route.params && this.$route.params.id)
      return this.$route.params.id;
    return null;
  }
  @Watch("routeObject", {
    deep: true,
    immediate: true,
  })
  async routeIdHandler(newVal: any) {
    if (newVal) {
      await BlogStore.fetchBlogById(Number(newVal));
      BlogStore.fetchSimilarBlogPosts({ blog_id: this.blogPost.id });
      // BlogStore.setBlogPostById(Number(newVal));
    }
  }
  mounted() {
    ProductStore.fetchHotStuffProducts();
  }
  deleted() {
    BlogStore.setSimilarBlogPosts([]);
  }
}
