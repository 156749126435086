import { Module, VuexModule, Mutation, getModule } from 'vuex-module-decorators'
import store from "./index";

@Module({ dynamic: true, store, name: 'overlayLoader', namespaced: true })
export default class overlayLoader extends VuexModule {
    isLoading: boolean = false;

    @Mutation
    setLoader(show:boolean):void {
        this.isLoading = show;
    }


}
export const OverlayStore = getModule(overlayLoader);