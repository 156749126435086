
import { Vue, Component } from "vue-property-decorator";
import { CartStore } from "@/store/cart";
import { OrderStore } from "@/store/order";
import DeleteIcon from "@/icons/DeleteIcon.vue";
import { modal } from "@/store/modal";
@Component({ components: { DeleteIcon } })
export default class AppHeaderCart extends Vue {
  CartStore: any = CartStore;
  OrderStore: any = OrderStore;
  quantity: number = 1;

  modal: any = modal;
  get cart() {
    return CartStore.cart;
  }
  get totalPrice() {
    return CartStore.totalAmount;
  }

  public removeCartItem(item: any) {
    CartStore.removeCartProduct({
      productId: item.id,
      size: item.size,
      colorId: item.color.id,
    });
  }
  public async finalizeShopping() {
    const result = await OrderStore.initOrder();
    if (result && result.status) {
      this.$router.push("/contact-information");
    } else {
      if (result.warehouse_error) {
        OrderStore.setWarehouseErrorProducts(result.products);
        this.modal.set({ name: "warehouseError", show: true });
      }
    }
  }
  public editQuantity(item: any, operator: string): void {
    if (operator === "+") {
      CartStore.editCart({
        productId: item.id,
        size: item.size,
        colorId: item.color.id,
        newQuantity: item.quantity + 1,
      });
      return;
    }
    if (operator === "-") {
      CartStore.editCart({
        productId: item.id,
        size: item.size,
        colorId: item.color.id,
        newQuantity: item.quantity - 1 > 0 ? item.quantity - 1 : 1,
      });
      return;
    }
  }
}
