
import { Vue, Component, Prop } from "vue-property-decorator";

@Component({})
export default class RecomendedProductsSingleProduct extends Vue {
  imageURLprefix: string | undefined = process.env.VUE_APP_BACKEND_IMAGE_PREFIX;

  selectedColor: any = null;

  @Prop({ required: true })
  product!: any;
  @Prop({ required: false })
  index!: number;

  public setColor(val: any): void {
    this.selectedColor = val;
  }
  get ImagesUrlsPerColor() {
    if (this.productColors && this.selectedColor && this.selectedColor.id) {
      return this.product.images
        .filter((image: any) => image.color.id == this.selectedColor.id)
        .map((image: any) => image.url);
    }
    return [];
  }

  get productColors() {
    if (this.product) {
      if (
        this.product.images &&
        Array.isArray(this.product.images) &&
        this.product.images.length > 0
      ) {
        let colorArray: any[] = [];
        this.product.images.forEach((image: any) => {
          if (image.color) {
            if (!colorArray.find((color) => color.id == image.color.id)) {
              colorArray.push(image.color);
            }
          }
        });
        if (this.selectedColor === null) {
          this.selectedColor = colorArray[0];
        }
        return colorArray;
      }
    }
    return [];
  }
}
