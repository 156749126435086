
import { Vue, Component } from "vue-property-decorator";
import { imageViewer } from "@/store/imageViewer";
import { modal } from "@/store/modal";

@Component({ components: {} })
export default class AppImageViewer extends Vue {
  imageViewer: any = imageViewer;
  modal: any = modal;
  imageURLprefix: string | undefined = process.env.VUE_APP_BACKEND_IMAGE_PREFIX;

  slide(operator: string) {
    const imagesLenght: number = this.imageViewer.images.length;
    if (operator === "+") {
      if (this.imageViewer.imgIndex + 1 == imagesLenght) {
        this.imageViewer.setImgIndex(0);
        this.imageViewer.setSelectedImage(
          imageViewer.images[imageViewer.imgIndex]
        );
        return;
      }
      if (this.imageViewer.imgIndex + 1 < imagesLenght) {
        this.imageViewer.setImgIndex(imageViewer.imgIndex + 1);
        this.imageViewer.setSelectedImage(
          imageViewer.images[imageViewer.imgIndex]
        );
      }
    }
    if (operator === "-") {
      if (this.imageViewer.imgIndex == 0) {
        this.imageViewer.setImgIndex(imagesLenght - 1);
        this.imageViewer.setSelectedImage(
          imageViewer.images[imageViewer.imgIndex]
        );
        return;
      }
      if (this.imageViewer.imgIndex > 0) {
        this.imageViewer.setImgIndex(imageViewer.imgIndex - 1);
        this.imageViewer.setSelectedImage(
          imageViewer.images[imageViewer.imgIndex]
        );
        return;
      }
    }
  }
}
