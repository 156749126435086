import { render, staticRenderFns } from "./PrivacyPolicyModal.vue?vue&type=template&id=acf21e0a&scoped=true&"
import script from "./PrivacyPolicyModal.vue?vue&type=script&lang=ts&"
export * from "./PrivacyPolicyModal.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "acf21e0a",
  null
  
)

export default component.exports