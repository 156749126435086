import axios from "../../config/axios";

export const getWalletBalance = async() => {
    const response = await axios.get('/wallet_balance');
    return response && response.data ? response.data : null ;
}
export const addFunds = async(params:any) => {
    const response = await axios.post('/wallet/add_funds', params);
    return response && response.data ? response.data : null ;
}
export const finalizeWalletPayment = async() => {
    const response = await axios.post('/wallet_payment');
    return response && response.data ? response.data : null ;
}


