import { Module, VuexModule, Mutation, Action, getModule } from 'vuex-module-decorators';
import store from "./index";
import {getHomepageBlogPosts, getAllBlogPosts, 
    getBlogHashtags, getFilteredBlogPosts, getSimilarBlogPosts, getBlogById} from "../api/blog";


@Module({ dynamic: true, store, name: 'blog', namespaced: true })
export default class blog extends VuexModule {
    homepageBlogs: object[] | null = [];
    allBlogPosts: object[] = [];
    allHashtags: []| null = null;
    selectedBlogPost : object| any = null;
    filteredPosts: object[]  = [];
    filterHashtags: string[] = [];
    similarBlogPosts: object[]  = [];
   
    @Mutation
    clearFiltering(){
        this.filterHashtags = [];
    }
    @Mutation
    setHomepageBlogs(data: object[] | null):void {
        this.homepageBlogs = data;
    }
    @Mutation
    setAllBlogPosts(data: object[]):void {
        this.allBlogPosts = data;
    }
    @Mutation
    setSelectedBlogPost(data: object | any):void {
        this.selectedBlogPost = data;
    }
    @Mutation
    setAllHashtags(data: object | any):void {
        this.allHashtags = data;
    }
    @Mutation
    setBlogPostById(blogPostId: number):void {
        this.selectedBlogPost = this.allBlogPosts.find((blogPost:any)=> blogPost.id === blogPostId);
    }
    @Mutation
    setFilteredBlogPosts(data: object[]):void {
        this.filteredPosts = data;
    }
    @Mutation
    setSimilarBlogPosts(data: object[]):void {
        this.similarBlogPosts = data;
    }
    @Mutation
    addFilterHashtags(hashtag: string):void {
        if(!this.filterHashtags.includes(hashtag)){
            this.filterHashtags.push(hashtag);
        }else
             this.filterHashtags =  this.filterHashtags.filter(existing => existing !== hashtag)
    }

    @Mutation
    clearFilterHashtags():void{
        this.filterHashtags = [];
    }

    @Action
    async fetchBlogPosts(){
        const response = await getHomepageBlogPosts();
        this.setHomepageBlogs(response);
    }
    @Action
    async fetchBlogById(id:number){
        const response = await getBlogById({id:id});
        if(response !== null){
            this.setSelectedBlogPost(response);
        }
    }
    @Action
    async fetchAllBlogPosts(){
        const response = await getAllBlogPosts();
        if(response !== null){
            this.setAllBlogPosts(response);
        }
    }
    @Action
    async fetchFilteredBlogPosts(){
        const response = await getFilteredBlogPosts({hashtags: this.filterHashtags});
        if(response !== null){
            this.setFilteredBlogPosts(response);
        }
    }
    @Action
    async fetchBloghashtags(){
        const response = await getBlogHashtags();
        if(response !== null){
            this.setAllHashtags(response);
        }
    }
    @Action
    async fetchSimilarBlogPosts(params:any){
        const response = await getSimilarBlogPosts(params);
        if(response !== null){
            this.setSimilarBlogPosts(response);
        }
    }
}
export const BlogStore = getModule(blog);