import { Module, VuexModule, Mutation, Action, getModule } from 'vuex-module-decorators';
import store from "./index";
import { LoaderStore } from "@/store/loader";
import {
    getProducts,
    getNewProduct,
    getNewArrivals,
    getAllColors,
    getHotStuffProducts,
    getFwiFavorites,
    getSimilarProducts,
    getProductById,
    getNextSliderProduct,
    getPurchases,
    getPurchase,
    getInstagramProducts,
} from "../api/products";
import Logo from "@/icons/Logo.vue";

@Module({ dynamic: true, store, name: 'product', namespaced: true })
export default class product extends VuexModule {
    allProducts: any[] = [];
    allColors: any[]=[];
    newArrivals:any[] = [];
    hotStuff: any[] = [];
    instagramProducts: any[] = [];
    selectedProduct: any = null;
    selectedProductSize: any = null;
    selectedColor: any = null;
    homepageNewProduct: any = null;
    fwiFavoriteProducts: any[] = [];
    similarProducts: any [] = [];
    purchases: any = null;
    purchase: any = null;
    productColors: any[]= [];

    @Mutation
    setProducts(data: object[]):void {
        this.allProducts = data;
    }
    @Mutation
    setHotStuffProducts(data: any[]):void {
        this.hotStuff = data;
    }

    @Mutation
    setNewArrivals(data: object[]):void {
        this.newArrivals = data;
    }
    @Mutation
    setHomepageNewProduct(data: any):void {
        this.homepageNewProduct = data;
    }
    @Mutation
    setProductSize(data: any):void {
        this.selectedProductSize = data;
    }

    @Mutation
    setSelectedProduct(data: any):void {
        this.selectedProduct = data;

        if (this.selectedProduct &&
          this.selectedProduct.images &&
          Array.isArray(this.selectedProduct.images) &&
          this.selectedProduct.images.length > 0
        ) {
            let colorArray: any[] = [];
            console.log(this.selectedProduct.images, 'this.selectedProduct.images')
            this.selectedProduct.images.forEach((image: any) => {
                if (image.color) {
                    if (!colorArray.find((color) => color.id == image.color.id)) {
                        colorArray.push(image.color);
                    }
                }
            });
            this.productColors = colorArray;
        }
        this.selectedColor = this.productColors[0];
    }
    @Mutation
    setSelectedColor(data: object | null):void {
        this.selectedColor = data;
    }
    @Mutation
    setAllColors(data: []):void {
        this.allColors = data;
    }
    @Mutation
    setfwiFavoriteProducts(data: []):void {
        this.fwiFavoriteProducts = data;
    }
    @Mutation
    setSimilarProducts(data: []):void {
        this.similarProducts = data;
    }
    // @Mutation
    // setProductColors(data: any[]):void {
    //     this.productColors = data;
    // }
    @Mutation
    resetProduct():void {
        this.selectedProduct = null;
        this.selectedProductSize = null;
        this.selectedColor = null;
        this.productColors = [];
    }
    @Mutation
    setPurchases(data: any):void {
        this.purchases = data;
    }
    @Mutation
    setPurchase(data: any):void {
        this.purchase = data;
    }
    @Mutation
    setInstagramProducts(data: any):void {
        this.instagramProducts = data;
    }

    @Action
    async fetchProductById(id:number|null){
        const response = await getProductById({id:id});
        if(response){
            this.setSelectedProduct(response);
        }
    }

    @Action
    async fetchColors(){
        const response = await getAllColors();
        if(response !== null){
            this.setAllColors(response);
        }
    }
    @Action
    async fetchProducts(){
        const response = await getProducts();
        if(response !== null){
            this.setProducts(response);
        }
    }

    @Action
    async fetchNewArrivals(){
        const response = await getNewArrivals();
        if(response !== null){
            this.setNewArrivals(response);
        }
    }
    @Action
    async fetchNewProduct(){
        const response = await getNewProduct();
        if(response !== null){
            this.setHomepageNewProduct(response);
        }

    }
    @Action
    async fetchHotStuffProducts(){
        const response = await getHotStuffProducts();
        if(response !== null){
            this.setHotStuffProducts(response);
        }

    }
    @Action
    async fetchFwiFavorites(){
        const response = await getFwiFavorites();
        if(response !== null){
            this.setfwiFavoriteProducts(response);
        }

    }
    @Action
    async fetchSimilarProducts(params: any){
        const response = await getSimilarProducts(params);
        if(response !== null){
            this.setSimilarProducts(response);
        }
    }
    @Action
    async slideProduct(data:{operator: string}){
        const response = await getNextSliderProduct({
            id:this.selectedProduct && this.selectedProduct.id ? this.selectedProduct.id : null,
            operator: data.operator
        });
        if(response){
            this.setSelectedProduct(response);
        }
    }
    @Action
    async fetchPurchases() {
        LoaderStore.set(true);
        const response = await getPurchases();

        if (response) {
            this.setPurchases(response);
            LoaderStore.set(false);
        };
    }

    @Action
    async fetchPurchase(purchaseId: number) {
        LoaderStore.set(true);
        const response = await getPurchase({order_id: purchaseId});
        if (response) {
            this.setPurchase(response);
            LoaderStore.set(false);
        };
    }

    @Action
    async fetchInstagramProducts() {
        const { data } = await getInstagramProducts();
        if (data) {
            this.setInstagramProducts(data);
        };
    }
}
export const ProductStore = getModule(product);