import { Module, VuexModule, Mutation, Action, getModule } from 'vuex-module-decorators'
import store from "./index";

@Module({
    name: 'ImageViewer',
    namespaced: true,
    dynamic: true,
    store
})
export default class ImageViewer extends VuexModule {
    imgIndex: number = 0;
    images: string[] = [];
    selectedImage: string = '';

    @Mutation
    setImgIndex(data: number):void {
        this.imgIndex = data;
    }

    @Mutation
    setImages(data: string[]):void {
        this.images = data;
    }

    @Mutation
    setSelectedImage(data: string):void {
        this.selectedImage = data;
    }

    // // action 'incr' commits mutation 'increment' when done with return value as payload
    // @Action({ commit: 'increment' })
    // incr() {
    //   return 5
    // }
    // // action 'decr' commits mutation 'decrement' when done with return value as payload
    // @Action({ commit: 'decrement' })
    // decr() {
    //   return 5
    // }
}
export const imageViewer = getModule(ImageViewer);