var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"w-full mt-12 relative bg-white lg:mb-300 z-10"},[(_vm.foundProduct)?[_c('app-shop-navigation',{on:{"slide":function($event){return _vm.slideNextProduct($event)}}}),_c('div',{staticClass:"w-11/12 2xl:container mx-auto h-full mt-10 lg:flex"},[_c('div',{staticClass:"w-full lg:w-2/3"},[_c('app-product-slider',{attrs:{"videoImage":_vm.foundProduct.video_image,"videoUrl":_vm.foundProduct.video_link,"images":_vm.sliderImages}})],1),_c('div',{staticClass:"w-3/3 lg:w-1/3 mt-14 lg:mt-0"},[_c('div',{staticClass:"w-11/12 md:w-10/12 mx-auto border-b pb-5"},[_c('h2',{staticClass:"lg:text-left uppercase tracking-wide truncate text-2xl"},[(_vm.foundProduct.name)?[_vm._v(" "+_vm._s(_vm.foundProduct.name)+" ")]:_vm._e()],2),_c('p',{staticClass:"mt-8 text-center lg:text-left text-1xl text-gray-650"},[(_vm.foundProduct.sale_price && _vm.foundProduct.price)?_c('span',{staticClass:"text-red-550 line-through mr-3"},[(_vm.foundProduct.sale_price && _vm.foundProduct.price)?[_vm._v(" "+_vm._s(_vm._f("monetary")(_vm.foundProduct.price))+" ")]:_vm._e()],2):_vm._e(),(_vm.foundProduct.sale_price && _vm.foundProduct.price)?[_vm._v(" "+_vm._s(_vm._f("monetary")(_vm.foundProduct.sale_price))+" ")]:(_vm.foundProduct.price)?[_vm._v(" "+_vm._s(_vm._f("monetary")(_vm.foundProduct.price))+" ")]:_vm._e()],2)]),(_vm.isInStock)?_c('div',[(_vm.productColors.length > 0 && _vm.availableColorIds)?[_c('div',{staticClass:"w-11/12 md:w-10/12 mx-auto py-6 border-b flex justify-center lg:justify-start space-x-4"},[_vm._l((_vm.productColors),function(color,index){return _c('div',{key:index},[_c('button',{staticClass:"ring ring-offset-2 rounded-full h-7 w-7 hover: cursor-pointer",class:{
                    'ring-black':
                      _vm.selectedColor && _vm.selectedColor.id === color.id,
                    'ring-gray-200':
                      !_vm.selectedColor ||
                      (_vm.selectedColor && _vm.selectedColor.id !== color.id),
                    'cursor-not-allowed opacity-40':
                      !_vm.availableColorIds.includes(color.id),
                  },on:{"click":function($event){return _vm.setColor(color)}}},[(
                      color.color_image_file && color.color_image_file.url
                    )?_c('img',{staticClass:"colorImage",attrs:{"src":_vm.host + color.color_image_file.url}}):_c('div')])])}),_c('div',[_c('button',{staticClass:"ring ring-gray-200 ring-offset-2 rounded-full h-7 w-7 hover: cursor-pointer",on:{"click":function($event){return _vm.setColor(null)}}},[_c('i',{staticClass:"fas fa-times"})])])],2)]:_vm._e(),(
              _vm.foundProduct.sizes &&
              _vm.selectedColor &&
              _vm.availableSizesByColor &&
              _vm.availableSizesByColor[_vm.selectedColor.id]
            )?[_c('div',{staticClass:"w-11/12 md:w-10/12 mx-auto py-6 flex"},[_c('ul',{staticClass:"flex mx-auto lg:mx-0 uppercase space-x-8"},_vm._l((_vm.foundProduct.sizes),function(size,i){return _c('li',{key:i,staticClass:"border hover: cursor-pointer hover:bg-gray-700 hover:text-white"},[_c('button',{staticClass:"p-3",class:{
                      'focus: bg-black focus: text-white':
                        _vm.selectedProductSize &&
                        _vm.selectedProductSize.id &&
                        _vm.selectedProductSize.id == size.id,
                      'cursor-not-allowed opacity-40': !_vm.availableSizesByColor[
                        _vm.selectedColor.id
                      ].includes(size.size),
                    },attrs:{"disabled":!_vm.availableSizesByColor[_vm.selectedColor.id].includes(
                        size.size
                      )},on:{"click":function($event){return _vm.setSelectedSize(size)}}},[_vm._v(" "+_vm._s(size.size)+" ")])])}),0)])]:_vm._e(),(_vm.selectedProductSize)?[_c('div',{staticClass:"w-10/12 mx-auto text-left"},[(_vm.NavigationStore.language === 'rs')?[_c('div',[_vm._v("Dužina : "+_vm._s(_vm.selectedProductSize.length)+" cm")]),_c('div',[_vm._v("Širina : "+_vm._s(_vm.selectedProductSize.width)+" cm")]),_c('div',[_vm._v("Gramaža : "+_vm._s(_vm.selectedProductSize.weight)+" g")])]:_vm._e(),(_vm.NavigationStore.language === 'en')?[_c('div',[_vm._v("Length : "+_vm._s(_vm.selectedProductSize.length)+" cm")]),_c('div',[_vm._v("Width : "+_vm._s(_vm.selectedProductSize.length)+" cm")]),_c('div',[_vm._v("Weight : "+_vm._s(_vm.selectedProductSize.weight)+" g")])]:_vm._e()],2)]:_vm._e(),(_vm.NavigationStore.language === 'rs')?[(_vm.isSizeInvalid)?_c('span',{staticClass:"text-red-400 text-sm"},[_vm._v("Molim Vas odaberite veličinu")]):_vm._e()]:_vm._e(),(_vm.NavigationStore.language === 'en')?[(_vm.isSizeInvalid)?_c('span',{staticClass:"text-red-400 text-sm"},[_vm._v("Please select a size")]):_vm._e()]:_vm._e()],2):_vm._e(),_c('div',{staticClass:"w-11/12 md:w-10/12 grid grid-cols-12 mx-auto py-6"},[(_vm.isInStock && _vm.isColorInStock)?[_c('app-add-to-cart-button',{class:{
                'col-span-10': _vm.CustomerStore.token,
                'col-span-12': !_vm.CustomerStore.token,
              },attrs:{"callbackFunction":_vm.addProductToCart}}),(_vm.CustomerStore.token)?_c('button',{staticClass:"col-span-2 py-3 border border-black flex justify-center focus:outline-none",attrs:{"disabled":_vm.isLikedBtnLoading},on:{"click":function($event){$event.preventDefault();return _vm.addLike.apply(null, arguments)}}},[_c('hearth',{staticClass:"w-5 h-5",style:({ fill: _vm.foundProduct.is_liked ? 'red' : 'black' })})],1):_vm._e()]:_c('div',{staticClass:"col-span-12 border border-black py-3 opacity-40"},[(_vm.isInStock && !_vm.selectedColor)?[_vm._v(" "+_vm._s(_vm.$t("product-details.select-a-color"))+" ")]:[_vm._v(" "+_vm._s(_vm.$t("product-details.out-of-stock"))+" ")]],2)],2)])]),_c('div',{staticClass:"w-11/12 2xl:container mx-auto grid grid-cols-1 gap-y-8 md:grid-cols-2 mt-16 md:mt-20 pb-14"},[_c('div',{staticClass:"text-left px-8"},[_c('h3',{staticClass:"uppercase font-bold"},[_vm._v(" "+_vm._s(_vm.$t("product-details.description"))+" ")]),(_vm.foundProduct.description)?[_c('p',{staticClass:"mt-3 text-sm text-justify"},[_vm._v(" "+_vm._s(_vm.foundProduct.description)+" ")])]:_vm._e()],2),_c('div',{staticClass:"text-left px-8"},[_c('h3',{staticClass:"uppercase font-bold"},[_vm._v(" "+_vm._s(_vm.$t("product-details.materials"))+" ")]),(_vm.foundProduct.description)?[_c('p',{staticClass:"mt-3 text-sm text-justify"},[_vm._v(" "+_vm._s(_vm.foundProduct.material)+" ")])]:_vm._e()],2)]),_c('app-shop-navigation',{staticClass:"border-b border-t py-8",on:{"slide":function($event){return _vm.slideNextProduct($event)}}}),_c('app-recommended-products-slider',{attrs:{"element-id":'similar_products',"title":_vm.$t('similar-products.title'),"products":_vm.similarProducts}}),_c('app-register-banner')]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }