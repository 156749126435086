
import { Component, Vue } from "vue-property-decorator";
import ArrowPhotoOverlayIcon from "@/icons/ArrowPhotoOverlayIcon.vue";
import ArrowRightIcon from "@/icons/ArrowRightIcon.vue";
import InstagramIconOverlay from "@/icons/InstagramIconOverlay.vue";
import AppVideoBanner from "@/components/AppVideoBanner.vue";
import AppRecommendedProductsSlider from "@/components/AppRecommendedProductsSlider.vue";
import { BlogStore } from "../store/blog";
import { ProductStore } from "../store/product";
import { CategoriesStore } from "../store/categories";
import { CartStore } from "@/store/cart";
import { NavigationStore } from "@/store/navigation";
import axios from "../../config/axios";
import { Regions } from "../types/region.types";
import { Locales } from "../types/i18n";
import { regions } from "../utilities/constants";
@Component({
  components: {
    ArrowPhotoOverlayIcon,
    ArrowRightIcon,
    InstagramIconOverlay,
    AppVideoBanner,
    AppRecommendedProductsSlider,
  },
})
export default class Home extends Vue {
  liked: boolean = false;
  BlogStore: any = BlogStore;
  CategoriesStore: any = CategoriesStore;
  private imageURLprefix: string | undefined =
    process.env.VUE_APP_BACKEND_IMAGE_PREFIX;

  get fwiFavoriteProducts() {
    return ProductStore.fwiFavoriteProducts;
  }

  get hotStuffProducts() {
    return ProductStore.hotStuff;
  }
  get instagramProducts() {
    return ProductStore.instagramProducts.slice(0, 10);
  }
  get NewProduct() {
    return ProductStore.homepageNewProduct;
  }
  get BlogPosts() {
    return BlogStore.homepageBlogs;
  }
  get NewArrivals() {
    return ProductStore.newArrivals;
  }
  get HomepageSubcategories() {
    return CategoriesStore.homepageSubcategories;
  }
  public openBlogPost(blogPost: any) {
    BlogStore.setSelectedBlogPost(blogPost);
    this.$router.push(`blog/${blogPost.id}`);
  }
  slide(side: string, elementId: any, itemId: any): void {
    let content = document.getElementById(elementId) as HTMLElement;
    let itemWidth = document.getElementById(itemId) as HTMLElement;
    let containerWidth: number = itemWidth.offsetWidth;
    if (side === "left") {
      content.scrollLeft -= containerWidth + 25;
    } else {
      content.scrollLeft += containerWidth + 25;
    }
  }

  async getLocation() {
    try {
      await axios
        .get(
          "https://api.freegeoip.app/json/?apikey=bbd4d4a0-72cd-11ec-9011-03a26fa3e291"
        )
        .then((res: any) => {
          const region = localStorage.getItem("region");
          if (!region) this.parseRegion(res.data);
        });
    } finally {
      console.log("GeoIP ERROR");
    }
  }

  parseRegion(data: any) {
    let region: string = Regions.RS;
    if (data.country_name === Regions.RS) {
      region = data.country_name;
      this.$i18n.locale = Locales.SR;
      NavigationStore.setLanguage("rs");
      NavigationStore.setRegion(regions.rs);
    } else {
      region = data.time_zone.split("/")[0];
      if (region == regions.eu) {
        NavigationStore.setRegion(regions.eu);
      } else {
        NavigationStore.setRegion(regions.us);
      }
      this.$i18n.locale = Locales.EN;
      NavigationStore.setLanguage("en");
    }
  }

  async mounted() {
    try {
      await this.getLocation();
    } finally {
      ProductStore.fetchProducts();
      ProductStore.fetchNewArrivals();
      ProductStore.fetchNewProduct();
      ProductStore.fetchHotStuffProducts();
      ProductStore.fetchFwiFavorites();
      ProductStore.fetchInstagramProducts();
      CategoriesStore.fetchCategoriesSubcategories();
      CategoriesStore.fetchHomepageSubcategories();
      CartStore.fetchCart();
      BlogStore.fetchAllBlogPosts();
      BlogStore.fetchBlogPosts();
    }
  }
}
