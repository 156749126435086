
import axiosInstance from "../../config/axios";

export const registerCustomer = async(params:any) => {
    const response = await axiosInstance.post('/register_customer', params);
    return response && response.data ? response.data : null ;
}
export const loginCustomer = async(params:any) => {
    const response = await axiosInstance.post('/login', params);
    return response && response.data ? response.data : null ;
}
export const getCustomerInfo = async() => {
    const response = await axiosInstance.get('/customer_info');
    return response && response.data ? response.data : null ;
}
export const addLike = async(params:any) => {
    const response = await axiosInstance.post('/likes', params);
    return response && response.data ? response.data : null ;
}
export const getLikedProducts = async() => {
    const response = await axiosInstance.get('/profile/liked');
    return response && response.data ? response.data : null ;
}
export const deactivateAccount = async() => {
    const response = await axiosInstance.post('/profile/deactivate');
    return response && response.data ? response.data : null ;
}
export const updateInfo = async(params:any) => {
    const response = await axiosInstance.post('/customer_info', params);
    return response && response.data ? response.data : null ;
}
export const changePassword = async(params:any) => {
    const response = await axiosInstance.post('/profile/change_password', params);
    return response && response.data ? response.data : null ;
}
export const resetPassword = async(params:any) => {
    const response = await axiosInstance.post('/reset_password', params);
    return response && response.data ? response.data : null ;
}



