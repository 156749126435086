import { render, staticRenderFns } from "./ProfileFollowOrderIcon.vue?vue&type=template&id=18f595a8&scoped=true&"
import script from "./ProfileFollowOrderIcon.vue?vue&type=script&lang=ts&"
export * from "./ProfileFollowOrderIcon.vue?vue&type=script&lang=ts&"
import style0 from "./ProfileFollowOrderIcon.vue?vue&type=style&index=0&id=18f595a8&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "18f595a8",
  null
  
)

export default component.exports