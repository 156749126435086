import axiosInstance from "../../config/axios";

export const initOrder = async() => {
    const response = await axiosInstance.post('/order_init',{});
    return response && response.data ? response.data : null ;
}
export const setOrderContactDetails = async(params:any) => {
    const response = await axiosInstance.post('/order_contact_details', params);
    return response && response.data ? response.data : null ;
}
export const updateOrderShippingDetails = async() => {
    const response = await axiosInstance.post('/calculate_shipping');
    return response && response.data ? response.data : null ;
}
export const fetchOrder = async() => {
    const response = await axiosInstance.get('/order');
    return response && response.data ? response.data : null ;
}
export const createDiscountWithCode = async(params:any) => {
    const response = await axiosInstance.post('/discount_code', params);
    return response && response.data ? response.data : null ;
}
export const finalizeCardOrder = async() => {
    const response = await axiosInstance.post('/finalize_order');
    return response && response.data ? response.data : null ;
}
export const finalizeCashOrder = async() => {
    const response = await axiosInstance.post('/finalize_cash_order');
    return response && response.data ? response.data : null ;
}



