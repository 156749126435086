
import { mixins } from "vue-class-component";
import { Vue, Component, Watch } from "vue-property-decorator";
import AppRecommendedProductsSlider from "@/components/AppRecommendedProductsSlider.vue";
import AppRegisterBanner from "@/components/AppRegisterBanner.vue";
import ArrowPhotoOverlayIcon from "@/icons/ArrowPhotoOverlayIcon.vue";
import { BlogStore } from "../store/blog";
import { ProductStore } from "../store/product";
import pagination from "../utilities/pagination";

@Component({
  components: {
    AppRecommendedProductsSlider,
    AppRegisterBanner,
    ArrowPhotoOverlayIcon,
  },
})
export default class Blog extends mixins(pagination) {
  BlogStore: any = BlogStore;
  ProductStore: any = ProductStore;
  private imageURLprefix: string | undefined =
    process.env.VUE_APP_BACKEND_IMAGE_PREFIX;

  get popularProducts() {
    return ProductStore.hotStuff;
  }
  get allBlogs() {
    return BlogStore.allBlogPosts;
  }
  get allHashtags() {
    return BlogStore.allHashtags;
  }
  get filterHashtags() {
    return BlogStore.filterHashtags;
  }
  get filteredPosts() {
    return BlogStore.filteredPosts;
  }
  get BlogPosts() {
    if (this.filterHashtags && this.filterHashtags.length > 0) {
      return this.filteredPosts;
    } else {
      return this.allBlogs;
    }
  }
  get BlogPostPaginatedList() {
    const retVal = this.BlogPosts.filter(
      (item, index) =>
        index < this.filters.paginationPage * 7 &&
        index >= (this.filters.paginationPage - 1) * 7
    );
    return retVal;
  }
  get routePageNum() {
    if (this.$route && this.$route.params && this.$route.params.pageNum)
      return Number(this.$route.params.pageNum);
    return 1;
  }
  @Watch("routePageNum", { deep: true, immediate: true })
  onPageNumChange(newVal: any) {
    if (newVal) {
      this.$set(this.filters, "paginationPage", newVal);
    }
  }
  @Watch("BlogPosts", { immediate: true, deep: true })
  onPropertyChanged(value: string) {
    if (value && value.length && value.length > 0) {
      this.paginationTotal = value.length;
    }
  }
  @Watch("filterHashtags", { immediate: true, deep: true })
  onHashtagArrayChanged(value: string) {
    if (value && value.length && value.length > 0) {
      if (this.$route.path !== "/blogs/1") this.$router.push("/blogs/1");
      BlogStore.fetchFilteredBlogPosts();
    }
  }
  public slide(side: string, elementId: any, itemId: any): void {
    let content = document.getElementById(elementId) as HTMLElement;
    let itemWidth = document.getElementById(itemId) as HTMLElement;
    let contentWidth: number = content.offsetWidth;
    let containerWidth: number = itemWidth.offsetWidth;

    if (side === "left") {
      content.scrollLeft -= containerWidth + 50;
    } else {
      content.scrollLeft += containerWidth + 50;
    }
  }
  async beforeCreate() {
    await BlogStore.fetchAllBlogPosts();
  }
  async mounted() {
    await BlogStore.fetchBloghashtags();
    ProductStore.fetchHotStuffProducts();
  }
  destroyed() {
    BlogStore.clearFiltering();
  }
}
