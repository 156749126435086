
import { Vue, Component } from "vue-property-decorator";
//@ts-ignore
import MasterCard from "../../assets/backgrounds/payment_cards/master-card.png";
//@ts-ignore
import MaestroCard from "../../assets/backgrounds/payment_cards/maestro-card.png";
//@ts-ignore
import Visa from "../../assets/backgrounds/payment_cards/visa.png";
//@ts-ignore
import SecureCode from "../../assets/backgrounds/payment_cards/mc_idcheck.svg";
//@ts-ignore
import DinaCard from "../../assets/backgrounds/payment_cards/DinaCard.jpg";
//@ts-ignore
import VerByVisa from "../../assets/backgrounds/payment_cards/ver-by-visa.jpg";
//@ts-ignore
import AmericanExpress from "@/assets/backgrounds/payment_cards/AMERICAN EXPRESS.png";
//@ts-ignore
import Intesa from "../../assets/backgrounds/payment_cards/logo-intesa.png";
import { WalletStore } from "../../store/wallet";
import BankPaymentForm from "../BankPaymentForm.vue";
import { modal } from "@/store/modal";
import { OverlayStore } from "../../store/overlayLoader";

@Component({
  components: {
    BankPaymentForm,
  },
})
export default class Wallet extends Vue {
  WalletStore: any = WalletStore;
  modal: any = modal;
  amount: number | null = null;
  isPaymentDisabled: boolean = false;
  termsOfPurchase: boolean = false;
  paymentCards: any = [
    {
      src: MasterCard,
      alt: "MasterCard",
    },
    { src: MaestroCard, alt: "MaestroCard" },
    {
      src: Visa,
      alt: "Visa",
    },
    {
      src: AmericanExpress,
      alt: "AmericanExpress",
    },
    { src: DinaCard, alt: "DinaCard" },
  ];
  paymentPrograms: any = [
    {
      src: SecureCode,
      alt: "SecureCode",
      link: "http://www.mastercard.com/rs/consumer/credit-cards.html",
      target: "_blank",
    },
    {
      src: VerByVisa,
      alt: "VerifiedByVisa",
      link:
        "https://rs.visa.com/pay-with-visa/security-and-assistance/protected-everywhere.html",
      target: "_blank",
    },
  ];
  bankLogo: any = {
    src: Intesa,
    alt: "BancaIntesa",
    link: "https://www.bancaintesa.rs",
    target: "_blank",
  };
  get appendedCurrency() {
    return Vue.filter("monetary")(0)
      ? Vue.filter("monetary")(0).slice(-3)
      : null;
  }

  get balance() {
    return WalletStore.walletBalance ? WalletStore.walletBalance : 0;
  }
  get isSubmitDisabled() {
    return (this.amount && this.amount > 0) || this.termsOfPurchase
      ? false
      : true;
  }
  public async submitWalletPayment() {
    this.isPaymentDisabled = true;
    OverlayStore.setLoader(true);
    const response = await WalletStore.addWalletFunds(this.amount);
    if (
      response &&
      response.hasOwnProperty("form_details") &&
      response.hasOwnProperty("status") &&
      response.status === "ok"
    ) {
      await this.sendPaymentForm(response["form_details"]);
    }
  }
  public async sendPaymentForm(paymentParams: any) {
    //@ts-ignore
    this.$refs["walletPaymentForm"]["$refs"]["okUrl"].value =
      process.env.VUE_APP_WALLET_PAYMENT_REDIRECT;
    //@ts-ignore
    this.$refs["walletPaymentForm"]["$refs"]["failUrl"].value =
      process.env.VUE_APP_WALLET_PAYMENT_REDIRECT;
    //@ts-ignore
    this.$refs["walletPaymentForm"]["$refs"]["shopurl"].value =
      process.env.VUE_APP_BANK_URL;
    //@ts-ignore
    this.$refs["walletPaymentForm"]["$refs"]["BFlang"].value = null;
    //@ts-ignore
    this.$refs["walletPaymentForm"]["$refs"]["BFcurrency"].value = null;
    //@ts-ignore
    this.$refs["walletPaymentForm"]["$refs"]["BFamount"].value = null;
    //@ts-ignore
    this.$refs["walletPaymentForm"]["$refs"]["BFoid"].value = null;
    //@ts-ignore
    this.$refs["walletPaymentForm"]["$refs"]["BFrnd"].value = null;
    //@ts-ignore
    this.$refs["walletPaymentForm"]["$refs"]["BFhash"].value = null;

    //@ts-ignore
    this.$refs["walletPaymentForm"]["$refs"]["BFlang"].value =
      paymentParams["lang"];
    //@ts-ignore
    this.$refs["walletPaymentForm"]["$refs"]["BFcurrency"].value =
      paymentParams["currency_code"];
    //@ts-ignore
    this.$refs["walletPaymentForm"]["$refs"]["BFamount"].value =
      paymentParams["amount"];
    //@ts-ignore
    this.$refs["walletPaymentForm"]["$refs"]["BFoid"].value =
      paymentParams["oid"];
    //@ts-ignore
    this.$refs["walletPaymentForm"]["$refs"]["BFrnd"].value =
      paymentParams["rnd"];
    //@ts-ignore
    this.$refs["walletPaymentForm"]["$refs"]["BFhash"].value =
      paymentParams["hash"];
    //@ts-ignore
    this.$refs["walletPaymentForm"]["$refs"]["BFform"].submit();
  }
}
