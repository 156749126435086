import { render, staticRenderFns } from "./AboutUsModal.vue?vue&type=template&id=e0902860&scoped=true&"
import script from "./AboutUsModal.vue?vue&type=script&lang=ts&"
export * from "./AboutUsModal.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e0902860",
  null
  
)

export default component.exports