import { CustomerStore } from './../store/customer';
import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Home from '../views/Home.vue';
Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
    {
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: "/shop",
        name: "Shop",
        component: require("../views/Shop").default,
    },
    {
        path: "/product-details/:id",
        name: "ProductDetails",
        component: require("../views/ProductDetails").default,
    },
    {
        path: "/cart",
        name: "Cart",
        component: require("../views/Cart").default,
    },
    {
        path: "/contact-information",
        name: "ContactInformation",
        component: require("../views/ContactInformation").default,
        // beforeEnter: (to, from, next) => {
        //
        // }
    },
    {
        path: "/shipping",
        name: "Shipping",
        component: require("../views/Shipping").default,
    },
    {
        path: "/review-and-finish",
        name: "ReviewAndBuy",
        component: require("../views/ReviewAndBuy").default,
    },
    {
        path: "/register",
        name: "Register",
        component: require("../views/Register").default,
    },
    {
        path: "/about",
        name: "About",
        component: require("../views/About").default,
    },
    {
        path: "/blogs/:pageNum",
        name: "Blogs",
        component: require("../views/Blogs").default,
    },
    {
        path: "/blog/:id",
        name: "Blog",
        component: require("../views/Blog").default,
    },
    {
        path: "/profile",
        name: "Profile",
        component: require("../views/Profile").default,
    },
    {
    path: '*',
    name:'404', 
    component: Home,
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach(async (to, from, next) => {
    if(to.name === '404'){
        router.push('/')
    }
    if(to.name === 'Profile' && CustomerStore.token === null){
        router.push('/')
    }
    next();
})

export default router
