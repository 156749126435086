
import { Vue, Component } from "vue-property-decorator";
// @ts-ignore
import { gmapApi } from 'vue2-google-maps';

@Component({})
export default class GoogleMap extends Vue {
  // DATA - START
  google: any = gmapApi;
  locationPin: any = { lat: 44.8158837, lng: 20.434235 };
  // DATA - END


  // HOOKS - START
  public mounted() {
    // @ts-ignore
    this.$refs.mapRef.$mapPromise.then((map: any) => {
      map.panTo({lat: 44.8158837, lng: 20.434235})
    })
  }
  // HOOKS - END
}
