
import { Vue, Prop, Component } from "vue-property-decorator";
import { ShopStore } from "../store/shop";
import { modal } from "@/store/modal";

@Component
export default class AppShopFilters extends Vue {
  imageURLprefix: string | undefined = process.env.VUE_APP_BACKEND_IMAGE_PREFIX;
  openProducts: boolean = false;
  openSize: boolean = false;
  openColors: boolean = false;
  openPrice: boolean = false;

  ShopStore: any = ShopStore;
  modal: any = modal;

  get subcategoryFiltering() {
    return ShopStore.filterCategories;
  }

  get sizeFilters() {
    return ShopStore.sizeFilters;
  }
  get priceFilters() {
    return ShopStore.priceRange;
  }

  get colorFiltering() {
    return ShopStore.filterColors;
  }

  public updateRoute() {
    this.$router
      .push({
        path: "/shop",
        query: ShopStore.queryParams,
      })
      .catch(() => {});
  }

  public setSizeValue(val: any, key: any) {
    if (val && val.target && val.target.checked !== null) {
      ShopStore.setSizeFilters({ key: key, value: val.target.checked });
      this.updateRoute();
    }
  }

  public setPriceRange(val: any, key: any) {
    if (val && val.target && val.target.checked !== null) {
      ShopStore.setPriceRangeFilter({ key: key, value: val.target.checked });
      this.$router
        .push({
          path: "/shop",
          query: ShopStore.queryParams,
        })
        .catch(() => {});
    }
  }
}
