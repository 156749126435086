
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import ArrowPhotoOverlayIcon from "@/icons/ArrowPhotoOverlayIcon.vue";
import { imageViewer } from "@/store/imageViewer";
import { modal } from "@/store/modal";
import { ProductStore } from "../store/product";
import YouTubeModal from "./modals/YouTubeModal.vue";
@Component({ components: { ArrowPhotoOverlayIcon, YouTubeModal } })
export default class AppProductViewer extends Vue {
  ProductStore: any = ProductStore;
  imgId: number = 0;
  parentId: number = 0;
  host: string | undefined = process.env.VUE_APP_BACKEND_IMAGE_PREFIX;
  imageViewer: any = imageViewer;
  modal: any = modal;
  currentPage: number = 0;
  imgs: string[] = [];

  @Prop({ required: true })
  images!: string[];

  @Prop({ required: false })
  videoUrl!: string | null;

  @Prop({ required: false })
  videoImage!: any | null;

  get videoId() {
    if (this.videoUrl) {
      //@ts-ignore
      return this.$youtube.getIdFromUrl(this.videoUrl);
    }
    return null;
  }

  @Watch("images")
  onImagesChanged(val: string[]): void {
    //@ts-ignore
    let imgCarousel = this.$refs["imgCarousel"];
    if (imgCarousel) {
      //@ts-ignore
      imgCarousel.goToPage(0);
    }
    this.currentPage = 0;
  }

  get itemsToShow() {
    if (window.innerWidth < 600) return 1;
    if (window.innerWidth < 900) return 2;
    if (window.innerWidth < 1025) return 3;
    if (window.innerWidth >= 1025) return 4;
  }

  zoomIn(event: any): void {
    if (window.innerWidth > 1023) {
      var imgId = "img" + this.imgId;
      var parentId = "parent" + this.parentId;
      var img = document.getElementById(imgId) as HTMLElement;
      var parent = document.getElementById(parentId) as HTMLElement;
      img.style.backgroundRepeat = "no-repeat";
      img.style.backgroundSize = "150% 150%";
      var x = event.pageX - img.offsetLeft;
      var y = event.pageY - img.offsetTop;
      var xperc = (x / parent.offsetWidth) * 100;
      var yperc = (y / parent.offsetHeight) * 100;
      img.style.backgroundPositionX = xperc + "%";
      img.style.backgroundPositionY = yperc - 30 + "%";
      img.style.transition = "0.1s linear all";
    }
  }

  zoomOut(): void {
    if (window.innerWidth > 1023) {
      var imgId = "img" + this.imgId;
      var img = document.getElementById(imgId) as HTMLElement;
      img.style.backgroundSize = "100% 100%";
    }
  }

  slide(side: string, elementId: any, itemId: any): void {
    let content = document.getElementById(elementId) as HTMLElement;
    let itemWidth = document.getElementById(itemId) as HTMLElement;
    let contentWidth: number = content.offsetWidth;
    let containerWidth: number = itemWidth.offsetWidth;
    if (side === "left") {
      content.scrollLeft -= containerWidth + 20;
    } else {
      content.scrollLeft += containerWidth + 20;
    }
  }
  openImageViewer(images: string[], index: number) {
    this.imageViewer.setImgIndex(index);
    this.imageViewer.setImages(images);
    this.imageViewer.setSelectedImage(images[index]);
    this.modal.set({ name: "imageViewerModal", show: true });
  }
  sliderEnd(e: any) {
    console.log("slide", e);
  }
  beforeNext() {
    let newIndex = this.currentPage + 1;
    if (newIndex == this.images.length) {
      let currentColor = ProductStore.selectedColor;
      let currentIndex = ProductStore.productColors.findIndex((color: any) => {
        return color.id == currentColor.id;
      });
      if (currentIndex != -1) {
        var newColorIndex = currentIndex + 1;
        if (currentIndex + 1 >= ProductStore.productColors.length) {
          newColorIndex = 0;
        }

        ProductStore.setSelectedColor(
          ProductStore.productColors[newColorIndex]
        );
        this.currentPage = 0;
      }
    }
    return true;
  }
  beforePrev(e: any) {
    let newIndex = this.currentPage - 1;
    if (newIndex == -1) {
      let currentColor = ProductStore.selectedColor;
      let currentIndex = ProductStore.productColors.findIndex((color: any) => {
        return color.id == currentColor.id;
      });
      if (currentIndex != -1) {
        let newColorIndex = currentIndex - 1;
        if (currentIndex - 1 == -1) {
          newColorIndex = ProductStore.productColors.length - 1;
        }
        ProductStore.setSelectedColor(
          ProductStore.productColors[newColorIndex]
        );
        this.currentPage = 0;
      }
    }
  }
  navigationClicked(direction: string) {
    if (direction == "forward") {
    }
    if (direction == "backward") {
    }
  }
  openVideoModal() {
    this.modal.set({ name: "youTubeModal", show: true });
  }
}
