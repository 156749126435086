
import { Component, Vue } from "vue-property-decorator";
import { modal } from "@/store/modal";
import { CustomerStore } from "@/store/customer";
@Component({})
export default class AppThankYouModal extends Vue {
  CustomerStore: any = CustomerStore;
  Modal: any = modal;
  email: string | null = null;

  get isInvalidEmail() {
    return !this.email;
  }
  public clearModal() {
    this.email = null;
  }

  public async changePassword() {
    if (this.email) {
      if (
        await CustomerStore.resetPassword({
          email: this.email.trim(),
        })
      ) {
        this.Modal.set({ name: "resetPasswordModal", show: false });
        this.clearModal();
      }
    }
  }
}
