import axiosInstance from "../../config/axios";

export const getProducts = async() => {
    const {data} = await axiosInstance.get('/products');
    return data;
}
export const getProductsBy = async(params:any|null) => {
    const {data} = await axiosInstance.post('/products',{...params});
    return data;
}
export const getAllColors = async() => {
    const {data} = await axiosInstance.get('/product/colors');
    return data;
}
export const getNewProduct = async() => {
    const {data} = await axiosInstance.get('/product/new');
    return data;
}
export const getNewArrivals = async() => {
    const {data} = await axiosInstance.get('/product/new_arrivals');
    return data;
}
export const getHotStuffProducts = async() => {
    const {data} = await axiosInstance.get('/product/hot_stuff');
    return data;
}
export const getFwiFavorites = async() => {
    const response = await axiosInstance.get('/product/fwi_favorites');
    return response && response.data ? response.data : null ;
}
export const getSimilarProducts = async(params: any) => {
    const response = await axiosInstance.post('/product/similar_product', {...params});
    return response && response.data ? response.data : null ;
}
export const getProductById = async(params: any) => {
    const response = await axiosInstance.post('/product', {...params});
    return response && response.data ? response.data : null ;
}
export const getNextSliderProduct = async(params: any) => {
    const response = await axiosInstance.post('/product_slide', {...params});
    return response && response.data ? response.data : null ;
}

export const getPurchases = async() => {
    const response = await axiosInstance.get('/profile/orders');
    return response && response.data ? response.data : null;
}

export const getPurchase = async(params: any) => {
    const response = await axiosInstance.post('/profile/order_details', {...params});
    return response && response.data ? response.data : null;
}
export const websiteSearch = async(params: any) => {
    const response = await axiosInstance.post('/product/search', {...params});
    return response && response.data ? response.data : null;
}

export const getInstagramProducts = async() => {
    const response = await axiosInstance.get('/instagram');
    return response && response.data ? response.data : null;
}