
import { Component, Vue } from "vue-property-decorator";
import FacebookIconWhite from "@/icons/FacebookIconWhite.vue";
import InstagramIconWhite from "@/icons/InstagramIconWhite.vue";
import TwitterIconWhite from "@/icons/TwitterIconWhite.vue";
import YoutubeIconWhite from "@/icons/YoutubeIconWhite.vue";
import { CategoriesStore } from "@/store/categories";
import { NavigationStore } from "@/store/navigation";
import { regions } from "../../utilities/constants";
import { Locales } from "../../types/i18n";
import axios from "../../../config/axios";

@Component({
  components: {
    YoutubeIconWhite,
    TwitterIconWhite,
    InstagramIconWhite,
    FacebookIconWhite,
  },
})
export default class AppMobileNavigation extends Vue {
  CategoriesStore: any = CategoriesStore;
  NavigationStore: any = NavigationStore;
  lang: string = "ENGLISH";
  langs: string[] = ["ENGLISH", "SERBIAN"];
  activeLink: any = "1";
  Locales: any = Locales;
  selectedMenuItem: any = this.mainMenuItems["1"].subcategories;
  regions: any = regions;

  get mainMenuItems(): any {
    return CategoriesStore.navigationMenu;
  }

  get region() {
    return NavigationStore.region;
  }

  mounted() {
    this.$emit("fetchLanguage");
    this.$emit("fetchRegion");
  }

  public goToSubcategoryDetails(subcategory: any): void {
    this.$router
      .push({
        path: "/shop",
        query: {
          sort_by: "popularity",
          page: "1",
          category: subcategory.id,
        },
      })
      .catch(() => {});
    NavigationStore.setMobileNav(false);
    //Todo set product
  }

  fetchLanguage() {
    if (localStorage.getItem("lang") && localStorage.getItem("lang") !== "rs")
      this.$i18n.locale = "en";
    else this.$i18n.locale = "sr";
  }

  setRegion(event: any) {
    this.$emit("setRegion", event);
  }
}
