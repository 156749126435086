
import axiosInstance from "../../config/axios";

export const addProduct = async(params : any) => {
    const response = await axiosInstance.post('/add_product', params);
    return response && response.data ? response.data : null ;
}
export const editProductQuantity = async(params : any) => {
    const response = await axiosInstance.post('/edit_product_qty', params);
    return response && response.data ? response.data : null ;
}
export const removeProduct = async(params : any) => {
    const response = await axiosInstance.post('/remove_product', params);
    return response && response.data ? response.data : null ;
}
export const fetchCart = async() => {
    const response = await axiosInstance.post('/cart');
    return response && response.data ? response.data : null ;
}





