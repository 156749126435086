import { Module, VuexModule, Mutation, Action, getModule } from 'vuex-module-decorators'
import store from "./index";
import {addProduct, fetchCart,editProductQuantity,removeProduct} from '../api/cart';
import axios from '../../config/axios';
@Module({ dynamic: true, store, name: 'cart', namespaced: true })
export default class cart extends VuexModule {
    cart: any[] = [];
    totalAmount = 0;
    selectedProduct: object = {};
    cartLength: number = 0;

    @Mutation
    addToCart(data:any):void {
        if(!localStorage.getItem('cart')) localStorage.setItem('cart', JSON.stringify([]));
        let currentItems = localStorage.getItem('cart');
        if(currentItems)
        currentItems = JSON.parse(currentItems);
        if(currentItems && Array.isArray(currentItems) && !currentItems.find(item => item.product_id == data.productId)){
            currentItems.push({product_id:data.productId, quantity: 1, size: data.size, color_id: data.colorId});
            localStorage.setItem('cart', JSON.stringify(currentItems));
        }
    }

    @Mutation
    removeFromCart(data: any):void {
        if(!localStorage.getItem('cart')) localStorage.setItem('cart', JSON.stringify([]));
        let currentItems = localStorage.getItem('cart');
        if(currentItems)
        currentItems = JSON.parse(currentItems);
        if(currentItems && Array.isArray(currentItems)){
            currentItems.forEach((item: any, i: number) => {
                if (item.product_id === data.productId) {
                    this.cart.splice(i, 1);
                }
            })
        }
        localStorage.setItem('cart', JSON.stringify(currentItems));
    }

    @Mutation
    setCart(data: any[]):void {
        this.cart = data;
    }
    @Mutation
    clearCart():void {
        this.cart = [];
    }
    @Mutation
    setTotal(total:number): void {
        this.totalAmount = total;
    }
    
    @Action
    public async addProductToCart (data: any){
        const response = await addProduct({product_id: data.productId, size: data.size, color_id: data.colorId}, );
        if(response){
            if(response['anonymous_user_id']){
                axios.defaults.headers.common["AnonymousUserId"] = response['anonymous_user_id'];
                localStorage.setItem('anonymous_user_id',response['anonymous_user_id']);
            }
        }
        await this.fetchCart();
    }
    @Action
    public async editCart (data: any){
        await editProductQuantity({
            product_id: data.productId,
            size: data.size,
            color_id: data.colorId,
            new_quantity: data.newQuantity, 
            is_adding: data.is_adding} );
        await this.fetchCart();
    }
    @Action
    public async removeCartProduct (data: any){
        await removeProduct({
            product_id: data.productId, 
            size: data.size,
            color_id: data.colorId, }, );
        await this.fetchCart();
    }
    @Action
    public async fetchCart(){
        const response = await fetchCart();
        if(response){
            this.setCart(response.items);
            this.setTotal(response.total);
        }

    }
}
export const CartStore = getModule(cart);