var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"w-full relative z-10 bg-white"},[_c('div',{staticClass:"w-11/12 xl:w-10/12 2xl:container mx-auto grid md-grid-cols-1 lg:grid-cols-3 gap-x-5 lg:gap-x-8 xl:gap-x-10"},[_c('div',{staticClass:"lg:col-span-2 mt-10"},[_c('app-cart-steps',{attrs:{"step":3}}),_c('div',{staticClass:"mt-8"},[_c('h2',{staticClass:"uppercase text-left font-medium"},[_vm._v(" "+_vm._s(_vm.$t("shipping.shipping-company"))+" ")])]),_c('div',{staticClass:"my-5 pt-2 pb-2"},[_c('div',{staticClass:"grid grid-cols-1 gap-10 my-5"},[(_vm.shippingCompany == _vm.shippingCompanies.DHL)?_c('a',{staticClass:"flex items-center"},[_vm._m(0)]):_vm._e(),(_vm.shippingCompany == _vm.shippingCompanies.PostExpress)?_c('a',{staticClass:"flex items-center"},[_vm._m(1)]):_vm._e()])]),_c('div',{staticClass:"text-gray-650 text-left text-sm"},[_c('div',{staticClass:"mt-4"},[_c('p',[_vm._v(" "+_vm._s(_vm.$t("shipping.shipping-company"))+" "),_c('span',{staticClass:"text-black font-medium"},[_vm._v(_vm._s(_vm.shippingCompany))])]),_c('p',{staticClass:"flex mt-2"},[_vm._v(" "+_vm._s(_vm.$t("shipping.shipping-address"))+" "),_c('span',{staticClass:"text-black font-medium ml-2"},[_vm._v(_vm._s(_vm.completeShippingAddress))])]),_c('p',{staticClass:"border-t border-b mt-5 py-3"},[_vm._v(" "+_vm._s(_vm.$t("shipping.shipping-info"))+" ")]),(
              _vm.OrderStore.order &&
                _vm.OrderStore.order.totals &&
                _vm.OrderStore.order.totals.shipping_amount
            )?_c('p',{staticClass:"mt-4"},[_vm._v(" "+_vm._s(_vm.$t("shipping.shipping-amount"))+" "),_c('span',{staticClass:"text-black font-medium"},[_vm._v(_vm._s(_vm._f("monetary")(_vm.OrderStore.order.totals.shipping_amount)))])]):_vm._e(),_c('div',{staticClass:"grid grid-cols-1 md:grid-cols-2 mt-5 mb-12 lg:mb-20"},[_c('div',{staticClass:"mt-5 flex items-center"},[_c('a',{staticClass:"flex items-center",attrs:{"href":"/contact-information"}},[_c('i',{staticClass:"fas fa-chevron-left font-light"}),_c('p',{staticClass:"ml-3"},[_vm._v(" "+_vm._s(_vm.$t("shipping.back"))+" ")])])]),_c('div',{staticClass:"mt-5"},[_c('button',{staticClass:"w-full bg-black text-white uppercase font-bold py-3 text-sm hover:text-gray-300 focus:outline-none disabled:opacity-20",attrs:{"disabled":!_vm.isShippingDataUpdated},on:{"click":function($event){return _vm.transitionToReview()}}},[_vm._v(" "+_vm._s(_vm.$t("shipping.continue"))+" ")])])])])])],1),_c('app-aside-cart')],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('span',{staticClass:"flex items-center"},[_c('img',{staticClass:"w-full mx-auto",attrs:{"src":require("../assets/backgrounds/shipping_companies/shipping-DHL.png"),"alt":"DHL"}})])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('span',{staticClass:"flex items-center"},[_c('img',{staticClass:"w-11/12 lg:w-10/12 mx-auto",attrs:{"src":require("../assets/backgrounds/shipping_companies/shipping-PostExpress.png"),"alt":"PostExpress"}})])
}]

export { render, staticRenderFns }