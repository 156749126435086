
import { Vue, Component } from "vue-property-decorator";
import { CustomerStore } from "../../store/customer";
import ShopSingleProduct from "@/components/ShopSingleProduct.vue";
@Component({
  components: { ShopSingleProduct },
})
export default class ProfileLikes extends Vue {
  CustomerStore: any = CustomerStore;
  get likedProducts() {
    return CustomerStore.likedProducts;
  }
}
