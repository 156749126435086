
import { Component, Vue } from "vue-property-decorator";
import ShippingIcon from "@/icons/ShippingIcon.vue";
import AppContinueShoppingBanner from "@/components/AppContinueShoppingBanner.vue";
import DeleteIcon from "@/icons/DeleteIcon.vue";
import NeedMoreHelpIcon from "@/icons/NeedMoreHelpIcon.vue";
import ReturnsIcon from "@/icons/ReturnsIcon.vue";
import WalletIcon from "@/icons/WalletIcon.vue";
import { CartStore } from "@/store/cart";
import { NavigationStore } from "@/store/navigation";
import Order, { OrderStore } from "../store/order";
import { modal } from "@/store/modal";
@Component({
  components: {
    ShippingIcon,
    AppContinueShoppingBanner,
    DeleteIcon,
    NeedMoreHelpIcon,
    ReturnsIcon,
    WalletIcon,
  },
})
export default class Cart extends Vue {
  quantity: number = 1;
  returnsInfo: boolean = false;
  replacementInfo: boolean = false;
  shippingInfo: boolean = false;
  moreHelpInfo: boolean = false;
  CartStore: any = CartStore;
  OrderStore: any = OrderStore;
  NavigationStore: any = NavigationStore;
  imageURLprefix: string | undefined = process.env.VUE_APP_BACKEND_IMAGE_PREFIX;
  modal: any = modal;
  get cart() {
    return CartStore.cart;
  }

  get totalPrice() {
    return CartStore.totalAmount;
  }
  get isCartNotEmpty() {
    return this.cart && this.cart.length && this.cart.length > 0 ? true : false;
  }
  public getItemColorImage(item: any, colorId: number) {
    const images = item.images.filter(
      (img: any) => img.image_color_id === colorId
    );
    return images[0] ? images[0] : null;
  }
  public async finalizeShopping() {
    const result = await OrderStore.initOrder();
    if (result && result.status) {
      this.$router.push("/contact-information");
    } else {
      if (result.warehouse_error) {
        OrderStore.setWarehouseErrorProducts(result.products);
        this.modal.set({ name: "warehouseError", show: true });
      }
    }
  }

  public editQuantity(item: any, operator: string): void {
    if (operator === "+") {
      CartStore.editCart({
        productId: item.id,
        size: item.size,
        colorId: item.color.id,
        newQuantity: item.quantity + 1,
        is_adding: true,
      });
      return;
    }
    if (operator === "-") {
      CartStore.editCart({
        productId: item.id,
        size: item.size,
        colorId: item.color.id,
        newQuantity: item.quantity - 1 > 0 ? item.quantity - 1 : 1,
        is_adding: false,
      });
      return;
    }
  }
  public removeCartItem(item: any) {
    CartStore.removeCartProduct({
      productId: item.id,
      size: item.size,
      colorId: item.color.id,
    });
  }

  mounted() {
    CartStore.fetchCart();
  }

  downloadFile(path: string): void {
    window.open(path, "_blank");
  }
}
